import { close_icon_modal } from "assets/images";
import {
  annotation_dots,
  clear,
  file_download,
  filter,
  notification_text,
  refreshIcon,
  search,
  star_yellow
} from "assets/media";
import {
  Button,
  GridDateCellRenderer,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ProgressBar,
  StarRating
} from "common/components";
import AvatarCellRenderer from "common/components/custom/AvatarCellRenderer/AvatarCellRenderer";
import GPOAgGrid from "common/components/custom/GPOAgGrid/GPOAgGrid";
import { ApiStatus } from "common/enums";
import { useDebounce, useInterval } from "common/hooks";
import { DateRange, SelectOptions } from "common/models";
import { GridSortModel } from "common/models/grid-sort-model";
import { useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { DateUtil, FileUtil, ObjectUtil } from "common/utils";
import {
  CommentFilterOption,
  ReviewFilterTypes
} from "modules/gpo-agent/enums/user-review-filter.enum";
import {
  ExportReviewRequest,
  UserReviewFilterBody,
  UserReviewFilters
} from "modules/gpo-agent/models";
import { SortOrder } from "modules/gpo-agent/models/filter-component/filter-values.model";
import { dashboardActions } from "modules/gpo-agent/redux/dashboard";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { UserReviewService } from "modules/gpo-agent/redux/user-review/user-review.service";
import { UserReviewUtil } from "modules/gpo-agent/utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import UserReviewFilter from "../UserReviewFilter/UserReviewFilter";
import "./UserReview.scss";
import { AUTO_REFRESH_INTERVAL_MS } from "common/config/app.config";

const UserReview: React.FC = () => {
  const dispatch = useAppDispatch();
  const DEBOUNCE_TIME_OUT = 1000;
  const [showFilter, setShowFilter] = useState(false);
  const [gridSortParams, setGridSortParams] = useState<GridSortModel>(
    UserReviewUtil.InitialSortModel
  );
  const { data: userRatingsData } = useAppSelector(
    (state) => state.dashboard.userRatingsData
  );

  const [appliedFilter, setAppliedFilter] = useState<
    UserReviewFilters | undefined
  >(UserReviewUtil.InitialFilter);
  const [searchText, setSearchText] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [autoRefreshCount, setAutoRefreshCount] = useState(0);
  const [showExportConfirmation, setShowExportConfirmation] = useState(false);
  const [exportStatus, setExportStatus] = useState(ApiStatus.NONE);
  const [lastUpdatedDatetime, setLastUpdatedDatetime] = useState(DateUtil.getTimeInDDMMMYYYY());
  const [filterRequest, setFilterRequest] = useState<UserReviewFilterBody | {}>(
    {}
  );
  const [selectedRowDetails, setSelectedRowDetails] = useState<{
    comment: string;
    userName: string;
  }>();
  const debouncedSearchText: string = useDebounce(
    searchText,
    DEBOUNCE_TIME_OUT
  );

  useEffect(() => {
    dispatch(appHeaderActions.getAllCountries());
  }, []);

  useEffect(() => {
    setSearchFilter(debouncedSearchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    const requestBody = UserReviewUtil.getFilterRequestBody(appliedFilter);
    requestBody.searchText = searchFilter;
    setFilterRequest(requestBody);
    callUserFeedbackService(requestBody);
  }, [appliedFilter, searchFilter]);

  const handleAutoRefresh = () => {
    setAutoRefreshCount((prev) => prev + 1);
    callUserFeedbackService(filterRequest);
  };
    // auto-refresh the data
    const { startInterval } = useInterval(
      handleAutoRefresh,
      AUTO_REFRESH_INTERVAL_MS,
      false
    );

  const columnDef = [
    {
      field: "userName",
      headerName: "USER",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <AvatarCellRenderer
            profileIdField={params?.data?.userGuid}
            displayName={params?.value}
            emailID={params?.data?.userEmail}
          />
        );
      }
    },
    {
      field: "countryName",
      headerName: "COUNTRY",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => params.value ?? "-"
    },
    {
      field: "rating",
      headerName: "RATING",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) =>
        params?.value ? <StarRating rating={params.value} /> : "Not provided"
    },
    {
      field: "createdDate",
      headerName: "DATE",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => (
        <GridDateCellRenderer
          dateToConvert={params?.value}
          format={"DD MMM YYYY h:mm a"}
        />
      )
    },
    {
      field: "ratingComment",
      headerName: "REVIEW COMMENTS",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => params.value ?? "-"
    }
  ];

  const callUserFeedbackService = (filter: UserReviewFilterBody | {}) => {
    if (ObjectUtil.isObjectEmpty(filter)) {
      dispatch(
        dashboardActions.getUserRatingsData(
          UserReviewUtil.UserFeedbackInitialRequest
        )
      );
    } else {
      dispatch(
        dashboardActions.getUserRatingsData(filter as UserReviewFilterBody)
      );
    }
  };

  const handleApplyFilter = (filter: UserReviewFilters) => {
    setAppliedFilter(structuredClone(filter));
    setShowFilter(false);
  };

  const formatDateRange = (date: DateRange) => {
    return (
      moment(date[0]).format("D/M/YYYY") +
      " - " +
      moment(date[1]).format("D/M/YYYY")
    );
  };

  const handleResetFilter = () => {
    setAppliedFilter(UserReviewUtil.EmptyFilter);
    setSearchFilter("");
  };

  const handleClearSelectedFilter = (
    key: ReviewFilterTypes,
    value: boolean | string | number | undefined
  ) => {
    switch (key) {
      case ReviewFilterTypes.Country:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [ReviewFilterTypes.Country]: prev[
                  ReviewFilterTypes.Country
                ].filter((item) => item.id !== value)
              }
            : prev
        );
        break;

      case ReviewFilterTypes.Duration:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [ReviewFilterTypes.Duration]: [null, null]
              }
            : prev
        );
        break;
      case ReviewFilterTypes.Rating:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [ReviewFilterTypes.Rating]: {
                  ...prev.rating,
                  [value as number]: false
                }
              }
            : prev
        );
        break;
      case ReviewFilterTypes.Comment:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [ReviewFilterTypes.Comment]: null
              }
            : prev
        );
        break;
    }
  };

  const getFilterChipElements = (key: string) => {
    switch (key) {
      case ReviewFilterTypes.Country:
        if (appliedFilter && appliedFilter[key].length > 0) {
          return appliedFilter[key].map((item) => (
            <div className="user-review__chip">
              <p>{item.name}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(ReviewFilterTypes.Country, item.id)
                }
              />
            </div>
          ));
        }
        break;
      case ReviewFilterTypes.Duration:
        if (appliedFilter && appliedFilter[key].every((item) => item != null)) {
          return (
            <div className="user-review__chip">
              <p>{formatDateRange(appliedFilter[key])}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    ReviewFilterTypes.Duration,
                    undefined
                  )
                }
              />
            </div>
          );
        }
        break;
      case ReviewFilterTypes.Rating:
        if (appliedFilter && Object.keys(appliedFilter[key]).length > 0) {
          return Object.keys(appliedFilter[key]).map((rating) => {
            if (appliedFilter[key][rating])
              return (
                <div className="user-review__chip">
                  <p>{`${rating} stars`}</p>
                  <img
                    src={clear}
                    alt="clear"
                    onClick={() =>
                      handleClearSelectedFilter(
                        ReviewFilterTypes.Rating,
                        rating
                      )
                    }
                  />
                </div>
              );
          });
        }
        break;
      case ReviewFilterTypes.Comment:
        if (appliedFilter && appliedFilter[key]) {
          return (
            <div className="user-review__chip">
              <p>{getCommentFilterLabel(appliedFilter[key])}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    ReviewFilterTypes.Comment,
                    undefined
                  )
                }
              />
            </div>
          );
        }
        break;
    }

    return <></>;
  };

  const getCommentFilterLabel = (option: SelectOptions | null) => {
    if (option) {
      switch (option.id) {
        case CommentFilterOption.All:
          return "All comments";
        case CommentFilterOption.NotProvided:
          return "Not provided comments";
        case CommentFilterOption.Provided:
          return "Provided comments";
      }
    } else return "";
  };

  const handleRowClicked = (data: any) => {
    setSelectedRowDetails({
      comment: data.ratingComment,
      userName: data.userName
    });
  };

  const roundNumber = (num) => Math.round(num * 100) / 100;

  const handleExport = async () => {
    setShowExportConfirmation(false);
    setExportStatus(ApiStatus.LOADING);
    const filterRequest = UserReviewUtil.getFilterRequestBody(appliedFilter);
    const exportRequest: ExportReviewRequest = {
      ...filterRequest,
      searchText: searchFilter,
      sortColumn: gridSortParams.colId,
      sortOrder: SortOrder[gridSortParams.sort],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    const { hasErrors, value } = await UserReviewService.export(exportRequest);
    const fileName = `User Review_${moment().format("YYYYMMDDHHmm")}.csv`;

    if (!hasErrors) {
      FileUtil.stringToFile(value, fileName, "text/csv");
      setExportStatus(ApiStatus.SUCCESS);
    } else setExportStatus(ApiStatus.ERROR);
  };

  const handleExportIconClick = () => {
    if (
      (appliedFilter && UserReviewUtil.showSelectedFilter(appliedFilter)) ||
      searchFilter.length > 0
    ) {
      setShowExportConfirmation(true);
    } else {
      handleExport();
    }
  };

  const handleGridApiSuccess = () =>{
    setLastUpdatedDatetime(DateUtil.getTimeInDDMMMYYYY());
    startInterval();
  }

  return (
    <div className="user-review">
      <div className="user-review__container">
        <div className="user-review__header">
          <div className="user-review__header__left">
            <h2>User Reviews</h2>
            <p>{`Last updated on ${lastUpdatedDatetime}`}</p>
          </div>
        </div>

        <div className="user-review__body">
          <UserReviewFilter
            show={showFilter}
            autoRefreshCount={autoRefreshCount}
            appliedFilters={appliedFilter}
            searchFilter={searchFilter}
            onCloseModal={() => setShowFilter(false)}
            onApply={handleApplyFilter}
            onReset={handleResetFilter}
          />
          {appliedFilter &&
            UserReviewUtil.showSelectedFilter(appliedFilter) && (
              <div className="user-review__selected-filter">
                <div className="user-review__selected-filter__wrapper">
                  <p className="user-review__selected-filter__label">
                    Selected filters
                  </p>
                  {Object.keys(appliedFilter).map((key) =>
                    getFilterChipElements(key)
                  )}
                  <p
                    className="user-review__selected-filter__clear"
                    onClick={handleResetFilter}
                  >
                    Clear all
                  </p>
                </div>
                <button
                  className="user-review__selected-filter__refresh-btn"
                  onClick={handleAutoRefresh}
                >
                  <img src={refreshIcon} />
                  <p>Refresh</p>
                </button>
              </div>
            )}

          <div className="user-review__action-panel">
            <div className="user-review__action-panel__search">
              <img src={search} />
              <input
                type="text"
                placeholder="Search"
                onChange={(evt) => setSearchText(evt.target.value)}
              />
            </div>
            <div className="user-review__action-panel__right">
              <div
                className="user-review__action-panel__filter-btn"
                onClick={() => setShowFilter(true)}
              >
                <img src={filter} />
                <span>Filter</span>
              </div>
              <button
                className="user-review__action-panel__export-btn"
                onClick={handleExportIconClick}
              >
                <img src={file_download} />
                <p>Export</p>
              </button>
            </div>
          </div>
          <div className="user-review__total-count">
            <div>
              <img src={annotation_dots} />
              <p className="user-review__total-count__label">
                Total Conversations
              </p>
              <p className="user-review__total-count__value">
                {userRatingsData.conversationCount}
              </p>
            </div>
            <div>
              <img src={star_yellow} />
              <p className="user-review__total-count__label">
                Average User Rating
              </p>
              <p className="user-review__total-count__value">
                {roundNumber(userRatingsData.average)}
              </p>
            </div>
            <div>
              <img src={notification_text} />
              <p className="user-review__total-count__label">
                Number of Ratings
              </p>
              <p className="user-review__total-count__value">
                {userRatingsData.count}
              </p>
            </div>
          </div>

          <div className="user-review__table">
            <GPOAgGrid
              method="POST"
              url={"Ratings/filter"}
              paginationSize={10}
              columnDefinitions={columnDef}
              requestBody={filterRequest}
              responsePropertyKey={"ratings"}
              searchFilter={searchFilter}
              payloadType="body"
              initialSortModel={UserReviewUtil.InitialSortModel}
              refreshGrid={autoRefreshCount}
              onRowClicked={handleRowClicked}
              onSort={setGridSortParams}
              onGridApiSuccess={handleGridApiSuccess}
            />

            {selectedRowDetails && (
              <div className="user-review__comment-panel">
                <div className="user-review__comment-panel__header">
                  <span>{`Review Comments (${selectedRowDetails.userName})`}</span>
                  <img
                    src={close_icon_modal}
                    alt="close"
                    onClick={() => setSelectedRowDetails(undefined)}
                  />
                </div>
                <p className="user-review__comment-panel__body">
                  {selectedRowDetails.comment ?? "-"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {showExportConfirmation && (
        <Modal>
          <ModalHeader onClose={() => setShowExportConfirmation(false)}>
            Export Confirmation
          </ModalHeader>
          <ModalBody>
            The export file is based on the filters applied. Do you want to
            continue ?
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={handleExport}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowExportConfirmation(false)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {exportStatus === ApiStatus.LOADING && (
        <Modal>
          <ModalBody className="user-review__export-progress">
            <ProgressBar label="Export is being generated. Please wait before taking any other action..." />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
export default UserReview;
