export const TOP_5_SOURCE_DOCS_COLORS = {
  "Rank-1": "#351C21",
  "Rank-2": "#42152D",
  "Rank-3": "#5A0A42",
  "Rank-4": "#750E5D",
  "Rank-5": "#922B73",
};

export const RESOLVED_RATE_COLORS = {
  resolved: "#57E188",
  unresolved: "#FF736A",
};

export const SENTIMENT_DISTRIBUTION_COLORS = {
  like: "#188CE5",
  unlike: "#87D3F2",
  noAction: "#FAFAFC"
};

export const AVG_TIME_COLORS = {
  avgTime: "#9C82D4",
};

export const HAND_OFF_RATE_COLORS = {
  sentToAgent: "#60E6E1",
  botResolvedRate: "#004F4F",
};

export const COUNTRY_COLORS_BY_USER_COUNT = [
  { min: 0, max: 0, color: "#C4C4CD" },
  { min: 1, max: 1666, color: "#C981B2" },
  { min: 1667, max: 3332, color: "#B14891" },
  { min: 3333, max: 4998, color: "#922B73" },
  { min: 4999, max: 6664, color: "#5A0A42" },
  { min: 6665, max: 8330, color: "#42152D" },
  { min: 8331, max: Number.POSITIVE_INFINITY, color: "#351C21" },
];
