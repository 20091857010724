import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabType } from "common/enums/tab-types.enum";
import { initialAppHeaderState } from "./header.state";
import { ICountryResponse } from "modules/gpo-agent/models/country-component/country-response.model";
import { ApiStatus } from "common/enums";
import { DocumentStatusesResponse } from "modules/gpo-agent/models/manage-document-filter/document-status.model";
import { DocumentAdminResponse } from "modules/gpo-agent/models/manage-document-filter/document-admins.model";
import { ArchiveDocumentRequest, ArchiveDocumentResponse } from "modules/gpo-agent/models/manage-document-filter/archive-document.model";
import { DocumentDownloadRequest } from "modules/gpo-agent/models/document/download-documentRequest.model";
import { ExportManagedDocumentDetails } from "modules/gpo-agent/models/manage-document-filter/export-managed-document.model";

const appHeaderSlice = createSlice({
  name: "appHeader",
  initialState: initialAppHeaderState,
  reducers: {
    updateTabType: (state, { payload }: PayloadAction<TabType>) => {
      state.tabType = payload;
    },
    updateNavBarTabChangeState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isNavBarTabChange = payload;
    },
    getAllCountries: (state) => {
      state.countryResponse.status = ApiStatus.LOADING;
    },
    getAllCountriesSuccess: (
      state,
      { payload }: PayloadAction<ICountryResponse>
    ) => {
      state.countryResponse.data = payload;
      state.countryResponse.status = ApiStatus.SUCCESS;
    },
    getAllCountriesFailed: (state, { payload }: PayloadAction<any>) => {
      state.countryResponse.data = {
        countries: [],
        status: "",
        code: -1,
        message: "",
      };
      state.countryResponse.status = ApiStatus.ERROR;
    },
    getAllDocumentStatuses: (state) => {
      state.documentStatusesResponse.status = ApiStatus.LOADING;
    },
    getAllDocumentStatusesSuccess: (
      state,
      { payload }: PayloadAction<DocumentStatusesResponse>
    ) => {
      state.documentStatusesResponse.data = payload;
      state.documentStatusesResponse.status = ApiStatus.SUCCESS;
    },
    getAllDocumentStatusesFailed: (state, { payload }: PayloadAction<any>) => {
      state.documentStatusesResponse.data = {
        documentStatuses: [],
        status: "",
        code: -1,
        message: "",
      };
      state.documentStatusesResponse.status = ApiStatus.ERROR;
    },
    getAllDocumentAdmins: (state) => {
      state.documentAdminsResponse.status = ApiStatus.LOADING;
    },
    getAllDocumentAdminsSuccess: (
      state,
      { payload }: PayloadAction<DocumentAdminResponse>
    ) => {
      state.documentAdminsResponse.data = payload;
      state.documentAdminsResponse.status = ApiStatus.SUCCESS;
    },
    getAllDocumentAdminsFailed: (state, { payload }: PayloadAction<any>) => {
      state.documentAdminsResponse.data = {
        admins: [],
        status: "",
        code: -1,
        message: "",
      };
      state.documentAdminsResponse.status = ApiStatus.ERROR;
    },
    archiveDocument :(state,{payload}:PayloadAction<ArchiveDocumentRequest>) =>{
      
    },
    archiveDocumentSuccess: (
      state,
      { payload }: PayloadAction<ArchiveDocumentResponse>
    ) => {
      state.archiveDocumentState = payload;
    },
    archiveDocumentError: (state) => {
      state.archiveDocumentState = initialAppHeaderState?.archiveDocumentState;
    },
    resetTotalArchived: (state) => {
      state.archiveDocumentState = initialAppHeaderState?.archiveDocumentState;
    },
    downloadDocument :(state,{payload}:PayloadAction<DocumentDownloadRequest>) =>{
      
    },
    downloadDocumentSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.downloadedFile = payload;
    },
    downloadDocumentError: (state) => {
      state.downloadedFile = initialAppHeaderState?.downloadedFile;
    },
    resetDownloadDocument: (state) => {
      state.downloadedFile = initialAppHeaderState?.downloadedFile;
    },
    exportDocument :(state,{payload}:PayloadAction<ExportManagedDocumentDetails>) =>{
      
    },
    exportDocumentSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.exportedManagedDocumentDetails = payload;
    },
    exportDocumentError: (state) => {
      state.exportedManagedDocumentDetails = initialAppHeaderState?.exportedManagedDocumentDetails;
    },
    resetExportDocument: (state) => {
      state.exportedManagedDocumentDetails = initialAppHeaderState?.exportedManagedDocumentDetails;
    },
  },
});

export const appHeaderActions = appHeaderSlice.actions;

export default appHeaderSlice.reducer;
