import { ApiStatus } from "common/enums";
import { IAsyncData } from "common/models/store/store.model";
import { IACSContext } from "modules/gpo-agent/models/agents/acs-context.model";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { ISession } from "modules/gpo-agent/models/agents/session.model";
import { WebPubSubDetails } from "modules/gpo-agent/models/agents/web-pub-sub-details.model";
import { IDocumentDetails, IDocumentPDFDetails } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import { UserEngagementData } from "modules/gpo-agent/models/dashboard";

interface IAgentsState {
    session: IAsyncData<ISession>;
    chatRequest: IAsyncData<IChatRequest[]>;
    acceptConversation: IAsyncData<boolean>;
    selectedConversation?: IChatRequest;
    aCSContext: IAsyncData<IACSContext>;
    leaveConversation: IAsyncData<boolean>;
    chatRequestSilent: IAsyncData<IChatRequest[]>;
    rejectConversation: IAsyncData<boolean>;
    webPubSubNegotiateDetails: IAsyncData<WebPubSubDetails>;
    pdfDocumentDetails:IDocumentPDFDetails  |undefined;
    userEngagementData:UserEngagementData;
    exportedQuestionDetailsData:any;
    totalArchivedCount:any;

}

const initalAgentsState: IAgentsState = {
    session: {
        status: ApiStatus.LOADING
    },
    chatRequest: {},
    acceptConversation: {},
    aCSContext: {},
    leaveConversation: {},
    chatRequestSilent:{},
    rejectConversation: {},
    webPubSubNegotiateDetails:{},
    pdfDocumentDetails:undefined,
    userEngagementData: {
        totalTraffic: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        totalLikes: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        totalDislikes: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        totalNoActions: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        totalConversations: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        totalQuestions: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        engagedUsers: {
          data: {
            total: 0,
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
        top5SourceDocs: {
          data: {
            top5SourceDocuments: [],
            status: "",
            code: -1,
            message: "",
          },
          status: ApiStatus.NONE,
        },
      },
      exportedQuestionDetailsData:undefined,
      totalArchivedCount:undefined
};

export { initalAgentsState };
