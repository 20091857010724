import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotateMessageRequest } from 'modules/gpo-agent/models/chat-history/chat-history-annotate-message.model';
import { IChatHistory, IChatHistoryList } from 'modules/gpo-agent/models/chat-history/chat-history-list.model';
import { IChatHistoryMessage, IChatHistoryMessages } from 'modules/gpo-agent/models/chat-history/chat-history-messages.model';
import { IReferenceDocumentsResponse } from 'modules/gpo-agent/models/chat-history/reference-documents-response.model';
import { initalAgentsChatHistoryState } from './agent-chat-history.state';
import { ApiStatus } from 'common/enums';
import { IErrorMessage } from 'common/models';


const agentChatHistorySlice = createSlice({
    name: 'agent-chat-history',
    initialState: initalAgentsChatHistoryState,
    reducers: {
        getChatHistoryList: (state,{ payload }: PayloadAction<string>) => {
            state.searchText = payload;
        },
        getChatHistoryListSuccess: (state, { payload }: PayloadAction<IChatHistoryList>) => {
             state.chatHistoryList = payload;
        },
        getChatHistoryListFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatHistoryList = undefined;
            console.log("Errors",payload);
        },
        getChatHistoryMessages: (state,{ payload }: PayloadAction<string>) => {
            state.chatHistoryMessageHandoffGuid=payload;
        },
        getChatHistoryMessagesSuccess: (state, { payload }: PayloadAction<IChatHistoryMessages>) => {
            state.chatHistoryMessages = payload;
        },
        getChatHistoryMessagesFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatHistoryMessages = undefined;
            console.log("Errors",payload);
        },
        updateSelectedChatHandOffGuid: (state, { payload }: PayloadAction<string>) =>{
            state.currentSelectedChatGuid = payload;
        },
        updateSelectedChatHistory: (state, { payload }: PayloadAction<IChatHistory>) =>{
            state.selectedChatHistory = payload;
        },
        updateChatAnnotateView: (state, { payload }: PayloadAction<boolean>) =>{
            state.isAnnotateView = payload;
        },
        clearChatHistoryMessages :(state) => {
            state.chatHistoryMessages = initalAgentsChatHistoryState.chatHistoryMessages;
        },
        clearChatHistoryList: (state) => {
            state.chatHistoryList = initalAgentsChatHistoryState.chatHistoryList;
        },
        clearSelectedChatHistory: (state) => {
            state.selectedChatHistory = initalAgentsChatHistoryState.selectedChatHistory;
        },
        clearSelectedChatGuid: (state) => {
            state.currentSelectedChatGuid = initalAgentsChatHistoryState.currentSelectedChatGuid;
        },
        getReferenceDocument: (state,{ payload }: PayloadAction<string>) => {
            state.documentType=payload;
        },
        getReferenceDocumentSuccess: (state, { payload }: PayloadAction<IReferenceDocumentsResponse>) => {
            state.referenceDocuments = payload;
        },
        getReferenceDocumentFailed: (state, { payload }: PayloadAction<any>) => {
            state.referenceDocuments = undefined;
            console.log("Errors",payload);
        },
        getAllDocuments: (state) => {
            
        },
        getAllDocumentsSuccess: (state, { payload }: PayloadAction<any>) => {
            state.allDocuments = payload;
        },
        getAllDocumentsFailed: (state, { payload }: PayloadAction<any>) => {
            state.allDocuments = undefined;
            console.log("Errors",payload);
        },
        completeAnnotation: (state,{ payload }: PayloadAction<string>) => {
            state.chatHistoryMessageHandoffGuid=payload;
        },
        completeAnnotationSuccess: (state, { payload }: PayloadAction<any>) => {
            state.completeAnnotationResponse = payload;
        },
        completeAnnotationFailed: (state, { payload }: PayloadAction<any>) => {
            state.completeAnnotationResponse = undefined;
            console.log("Errors",payload);
        },
        updateSearchMessagesText: (state) =>{
            state.searchMessagesText = state.searchText;
        },
        annotateMessage: (state,{ payload }: PayloadAction<AnnotateMessageRequest>) => {
        },
        annotateMessageSuccess: (state, { payload }: PayloadAction<any>) => {
             state.annotateDetailsResponse = payload;
        },
        annotateMessageFailed: (state, { payload }: PayloadAction<any>) => {
            state.annotateDetailsResponse = undefined;
            console.log("Errors",payload);
        },
        updateSearchTextChanged: (state, { payload }: PayloadAction<boolean>) => {
            state.searchTextChanged = payload;
        },
        uploadDocumentBegin: (state, { payload }: PayloadAction<FormData>) => {
            state.uploadDocument.status = ApiStatus.LOADING;
        },
        uploadDocumentSuccess: (state ) => {
            state.uploadDocument.status = ApiStatus.SUCCESS;            
        },
        uploadDocumentFailed: (state, { payload }: PayloadAction<IErrorMessage>) => {
            state.uploadDocument.status = ApiStatus.ERROR;
            state.uploadDocument.error = payload;
        },
        resetUploadDocument: (state) => {
            state.uploadDocument.status = ApiStatus.NONE;
            state.uploadDocument.error = undefined;
        },
    }
});

export const agentChatHistoryActions = agentChatHistorySlice.actions;

export default agentChatHistorySlice.reducer;
