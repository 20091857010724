import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResult } from "common/models";
import { IACSContext } from "modules/gpo-agent/models/agents/acs-context.model";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { ISession } from "modules/gpo-agent/models/agents/session.model";
import { WebPubSubDetails } from "modules/gpo-agent/models/agents/web-pub-sub-details.model";
import { put, takeLatest } from "redux-saga/effects";
import { AgentsService } from "./agents.service";
import { agentsActions } from "./agents.slice";
import { OpinionDataRequest, UserEngFieldResponse } from "modules/gpo-agent/models/dashboard";
import { AgentsChatHistoryService } from "../agent-chat-history/agent-chat-history.service";
import { ExportTableQuestionDetails } from "modules/gpo-agent/models/export-table-question-details/export-table-question-details.model";
import { ManagedDocumentsRequest } from "modules/gpo-agent/models/filter-component/filter-values.model";

const getSession = function* () {
    const { hasErrors, errors, value }: ApiResult<ISession> =
        yield AgentsService.getSession();

    if (hasErrors) {
        yield put({
            type: agentsActions.getSessionFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getSessionSuccess.type,
            payload: value
        });
    }
};

const getSessionWatch = function* () {
    yield takeLatest(agentsActions.getSession.type, getSession);
};

const getChatRequests = function* () {
    const { hasErrors, errors, value }: ApiResult<IChatRequest[]> =
        yield AgentsService.getChatRequests();

    if (hasErrors) {
        yield put({
            type: agentsActions.getChatRequestsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getChatRequestsSuccess.type,
            payload: value
        });
    }
};

const getChatRequestsWatch = function* () {
    yield takeLatest(agentsActions.getChatRequests.type, getChatRequests);
};

const acceptConversation = function* ({ payload }: PayloadAction<string>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.acceptConversation(payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.acceptConversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.acceptConversationSuccess.type,
            payload: true
        });
    }
};

const acceptConversationWatch = function* () {
    yield takeLatest(agentsActions.acceptConversation.type, acceptConversation);
}

const getACSContext = function* () {
    const { hasErrors, errors, value }: ApiResult<IACSContext> =
        yield AgentsService.getACSContext();
    if (hasErrors) {
        yield put({
            type: agentsActions.getACSContextFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getACSContextSuccess.type,
            payload: value
        });
    }
}

const getACSContextWatch = function* () {
    yield takeLatest(agentsActions.getACSContext.type, getACSContext);
}

const leaveConversation = function* ({ payload }: PayloadAction<string>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.leaveConversation(payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.leaveConversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.leaveConversationSuccess.type,
            payload: value
        });
    }
}

const leaveConversationWatch = function* () {
    yield takeLatest(agentsActions.leaveConversation.type, leaveConversation);
}

const getSilentChatRequest = function* () {
    const { hasErrors, errors, value }: ApiResult<IChatRequest[]> =
        yield AgentsService.getChatRequests();

    if (hasErrors) {
        yield put({
            type: agentsActions.getSilentChatRequestFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.getSilentChatRequestSuccess.type,
            payload: value
        });
    }
};

const getSilentChatRequestWatch = function* () {
    yield takeLatest(agentsActions.getSilentChatRequest.type, getSilentChatRequest);
};

const rejectConversation = function* ({ payload }: PayloadAction<string>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.leaveConversation(payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.rejectConversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.rejectConversationSuccess.type,
            payload: value
        });
    }
}

const rejectConversationWatch = function* () {
    yield takeLatest(agentsActions.rejectConversation.type, rejectConversation);
}

const webPubSubNegotiate = function* () {
    const { hasErrors, errors, value }: ApiResult<WebPubSubDetails> =
        yield AgentsService.webPubSubNegotiate();
    if (hasErrors) {
        yield put({
            type: agentsActions.webPubSubNegotiateFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: agentsActions.webPubSubNegotiateSuccess.type,
            payload: value
        });
    }
}

const webPubSubNegotiateWatch = function* () {
    yield takeLatest(agentsActions.webPubSubNegotiate.type, webPubSubNegotiate);
}

const getPDFDocument = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AgentsService.getPDFDocument(action?.payload);
    if (hasErrors) {
        yield put({
            type: agentsActions.getPDFDetailsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type:  agentsActions.getPDFDetailsSuccess.type,
            payload: value
        });
    }
};


const getPDFDocumentWatch = function* () {
    yield takeLatest(agentsActions.getPDFDetails.type, getPDFDocument);
};

const getTotalLikes = function* ({
    payload,
  }: PayloadAction<OpinionDataRequest>) {
    const { hasErrors, errors, value }: ApiResult<UserEngFieldResponse> =
      yield AgentsService.getTotalLikes(payload);
  
    if (hasErrors) {
      yield put(agentsActions.getTotalLikesError());
    } else {
      yield put(agentsActions.getTotalLikesSuccess(value));
    }
  };

  const getTotalLikesWatch = function* () {
    yield takeLatest(agentsActions.getTotalLikes.type, getTotalLikes);
 };
  
  const getTotalDislikes = function* ({
    payload,
  }: PayloadAction<OpinionDataRequest>) {
    const { hasErrors, errors, value }: ApiResult<UserEngFieldResponse> =
      yield AgentsService.getTotalDislikes(payload);
  
    if (hasErrors) {
      yield put(agentsActions.getTotalDislikesError());
    } else {
      yield put(agentsActions.getTotalDislikesSuccess(value));
    }
  };

  const getTotalDislikesWatch = function* () {
    yield takeLatest(agentsActions.getTotalDislikes.type, getTotalDislikes);
 };

 const getTotalNoActions = function* ({
    payload,
  }: PayloadAction<OpinionDataRequest>) {
    const { hasErrors, errors, value }: ApiResult<UserEngFieldResponse> =
      yield AgentsService.getTotalNoActions(payload);
  
    if (hasErrors) {
      yield put(agentsActions.getTotalNoActionsError());
    } else {
      yield put(agentsActions.getTotalNoActionsSuccess(value));
    }
  };

  const getTotalNoActionsWatch = function* () {
    yield takeLatest(agentsActions.getTotalNoActions.type, getTotalNoActions);
 };

 const getTotalArchivedCount = function* ({
    payload,
  }: PayloadAction<ManagedDocumentsRequest>) {
    const { hasErrors, errors, value }: ApiResult<any> =
      yield AgentsService.getTotalArchivedCount(payload);
  
    if (hasErrors) {
      yield put(agentsActions.getTotalArchivedError());
    } else {
      yield put(agentsActions.getTotalArchivedSuccess(value));
    }
  };

  const getTotalArchivedCountWatch = function* () {
    yield takeLatest(agentsActions.getTotalArchived.type, getTotalArchivedCount);
 };
 

 const exportedQuestionDetails = function* ({
    payload,
  }: PayloadAction<ExportTableQuestionDetails>) {
    const { hasErrors, errors, value }: ApiResult<any> =
      yield AgentsService.exportQuestionDetailsTable(payload);
  
    if (hasErrors) {
      yield put(agentsActions.exportQuestionDetailsDataFailed());
    } else {
      yield put(agentsActions.exportQuestionDetailsDataSuccess(value));
    }
  };

  const exportedQuestionDetailsWatch = function* () {
    yield takeLatest(agentsActions.exportQuestionDetailsData, exportedQuestionDetails);
 };
  

export {
    getSessionWatch,
    getChatRequestsWatch,
    acceptConversationWatch,
    getACSContextWatch,
    leaveConversationWatch,
    getSilentChatRequestWatch,
    rejectConversationWatch,
    webPubSubNegotiateWatch,
    getPDFDocumentWatch,
    getTotalLikesWatch,
    getTotalDislikesWatch,
    getTotalNoActionsWatch,
    exportedQuestionDetailsWatch,
    getTotalArchivedCountWatch
};
