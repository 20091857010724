import React, { FC, ReactNode } from "react";
import {
  BarChart as Chart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";

type BarChartData = {
  name: string;
  [key: string]: number | string;
};

type BarsConfig = {
  name?: string;
  dataKey: string;
  fill: string;
  barSize?: number;
  radius?: number;
  tooltipLabel?: string;
};

type BarChartProps = {
  data: BarChartData[];
  xKey: string;
  barsConfig: BarsConfig[];
  width?: number;
  height?: number;
  labelFontSize?: number;
};

const BarChart: FC<BarChartProps> = ({
  data,
  xKey,
  barsConfig,
  width,
  height,
  labelFontSize = 8,
}: BarChartProps) => {
  return (
    <Chart width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#747480" />
      <XAxis dataKey={xKey} fontSize={labelFontSize} stroke="#747480" />
      <YAxis
        tickFormatter={(value) => `${value}%`}
        fontSize={labelFontSize}
        stroke="#747480"
      />
      <Tooltip
        content={({ label, payload }) => {
          return (
            <div className="custom-tooltip">
              <div className="tooltip-arrow"></div>
              <div className="tooltip-content">
                {barsConfig.map((bar, index) => {
                  return bar.tooltipLabel ? (
                    <p>{`${bar.tooltipLabel}: ${Number(
                      payload?.[index]?.value
                    )?.toFixed(2)}%`}</p>
                  ) : (
                    ""
                  );
                })}
              </div>
            </div>
          );
        }}
      />
      {barsConfig.map((bar, index) => (
        <Bar
          key={index}
          dataKey={bar.dataKey}
          fill={bar.fill}
          barSize={bar.barSize || 6}
          radius={bar.radius || 5}
        />
      ))}
    </Chart>
  );
};

export default BarChart;
