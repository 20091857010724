export const CONTROLLER = {
  AGENTS: "Agents",
  WEB_PUB_SUB: "WebPubSub",
  USER: "User",
  ADMIN: "Admin",
  COUNTRY: "country",
  RESOLVED_RATE: "resolvedrate",
  HAND_OFF_RATE: "handoffrate",
  AVG_TIME_RES: "averagetimeresponse",
  FIRST_RESOLUTION_RATE: "firstResolutionRate",
  SENTIMENT_DIST_RATE: 'SentimentDistributionRate',
  USER_FEEDBACKS: "userfeedbacks",
  TOTAL_TRAFFIC: "totaltraffic",
  DOCUMENTS: "documents",
  OPINION: "opinion",
  TOTAL_CONVERSATION: "totalconversation",
  TOTAL_QUESTIONS: "totalquestions",
  TOTAL_ENGAGED_USERS: "totalengagedusers",
  RATINGS: "Ratings",
  TOP_SOURCE_DOCS: "topsourcedocuments",
  QUESTION_DETAILS: "QuestionDetails",
  TRAFFIC_FOR_MAP: "trafficdistributionmaps",
  EXPORT_METRICS: "exportmetrics",
};

export const METHOD = {
  SESSION: "session",
  CHAT_HISTORY: "chat-history",
  CHAT: "chat",
  MESSAGES: "messages",
  CHAT_REQUESTS: "chat-requests",
  ACCEPT: "accept",
  CONTEXT: "context",
  DOCUMENTS: "documents",
  COMPLETE_ANNOTATION: "complete-annotation",
  LEAVE: "leave",
  NEGOTIATE: "negotiate",
  ANNOTATE: "annotate",
  PROCESS_REQUEST: "process-request",
  REQUEST_ACCESS: "request-access",
  EXPORT: "export",
  FILTER: "filter",
  MANAGED_STATUS:"managed/statuses",
  ADMIN:"admin",
  MANAGED_DOCUMENTS:"documents/managed",
  ARCHIVE:"archive",
  LOGOUT: "logout",
  MANAGED: "managed",
  UPLOAD: "upload",
  DOWNLOAD:"download"
};

export const QUERY_PARAMS = {};

export const WEB_PUB_SUB_CONFIG = {
  HUB_NAME: "notify",
};
