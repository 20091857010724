import { solid_star_yellow, star_border } from "assets/media";
import './StarRating.scss';

type StarRatingProps = {
    rating: number;
}
const StarRating = ({rating}:StarRatingProps) =>{
const MAX = 5;

return(
    <div className='star-rating'>
        {[...Array(rating)].map((i) => <img key={i} src={solid_star_yellow}/>)}
        {[...Array(MAX - rating)].map((i) => <img key={i} src={star_border}/>)}
    </div>
)
}

export default StarRating;