import { FC, useEffect, useState, memo, useRef, useReducer} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { arrow_back_yellow, close_btn, maximize, page_icon, pdf_download, pdf_info_icon, pdf_zoom_in, pdf_zoom_out, reset, right_icon } from "assets/images";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDownloadLink } from '@react-pdf/renderer'
import "./PDFViewer.scss";
import React from "react";
import Loader from "common/components/base/Loader/Loader";
import ReactDOM from "react-dom";
import { Viewer,Worker } from "@react-pdf-viewer/core";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { IDocumentDetails, IDocumentPDFDetails, IDocumentPageDetails } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { SilentRequest } from "@azure/msal-browser";
import { msalInstance, protectedResources } from "common/config/auth.config";
import { HTTP_HEADER_KEY } from "common/enums";
import { ENV_CONFIG } from "common/config/env.config";
import { METHOD } from "common/config/endpoint.config";

type PDFViewerProps = {
    documentDetails: IDocumentDetails,
    onPDFClose : (documentDetails:any) => void
};
const PDFViewer: FC<PDFViewerProps> = (
     {
        documentDetails,
        onPDFClose
     }: PDFViewerProps) => 
{
    const [fileStatus, setFileStatus] = useState(false);
    const [fileLoading, setFileLoading] = useState(true);
    const [shown, setShown] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<any>("");
    const [pageNum, setPageNum] = useState<Array<IDocumentPageDetails>>([]);

    const dispatch = useAppDispatch();
   const docState = useAppSelector((state: RootState) => state.agents?.pdfDocumentDetails);
    const fileState = useAppSelector((state: RootState) => state.header?.downloadedFile);
    const [activePage, setActivePage] = useState<number>(0);
    const pageRefs:any = useRef({});

    const zoomReducer = (zoomState, action) => {
        switch (action.type) {
          case 'ZOOMIN': return { scale: zoomState.scale + 0.2 }
          case 'ZOOMOUT': return { scale: zoomState.scale - 0.2 }
          case 'RESET': return { scale: 1 }
          default: return zoomState
        }
    }

    const [zoomState, setScale] = useReducer(zoomReducer, { scale: 1 })  

    useEffect(()=>{
       //dispatch(agentsActions.getPDFDetails(documentDetails?.documentGuid)); 
      dispatch(appHeaderActions.downloadDocument({guidCode:documentDetails?.documentGuid})); 
    },[])
    
    // useEffect(()=>{
    //   if(docState){
    //     const pdfDetails : IDocumentPDFDetails = docState; 
    //     setPdfUrl(pdfDetails?.sasUrl);
    //     setPageNum(documentDetails?.pages?.sort((a, b) => a?.pageNumber- b?.pageNumber));
    //   } 
    // },[docState])
    
    useEffect(()=>{
      if(fileState && fileState instanceof Blob){
        const urlToPDF = URL.createObjectURL(fileState);
        console.log("bloburl",urlToPDF);
        setPdfUrl(urlToPDF);
        setPageNum(documentDetails?.pages?.sort((a, b) => a?.pageNumber- b?.pageNumber));
        dispatch(appHeaderActions?.resetDownloadDocument());
      }
    },[fileState])

    const blobToBase64 = (blob:Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileState);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    const onDocumentLoadSuccess = (event: any) => {
        console.log("load event",event);
        setFileLoading(false);
        setFileStatus(true);
      };
    
      const handleCloseDoc = () => {
        setFileStatus(false);
        setPdfUrl("");
        setPageNum([]);
        onPDFClose(undefined);
      };


  const modalBody = () => (
    <div className="fullscreen-modal">
      <header>
        <span>{documentDetails?.documentName}</span>
        <img src={close_btn} onClick={() => setShown(false)} />
      </header>
      <div className="fullscreen-modal__body">
       {/* <Worker workerUrl={"/pdf.worker_3_5_127.js"}>
        <Viewer  fileUrl={pdfUrl}
          initialPage={pageNum-1} />
      </Worker> */}
      </div>
    </div>
  );

  const downloadFile = (url:string) => {
    var a = document.createElement('a');
    a.href = url;
    a.download = documentDetails?.documentName;
    a.click();
  }

  const handlePageSourceClick = (index:number,pageNumber:number) =>{
    setActivePage(index);
    navigateToPage(pageNumber);
  }

  const navigateToPage = (pageNumber) => {
    pageRefs.current[pageNumber].scrollIntoView({ behavior: "smooth" });
  }

  return (
    <React.Fragment>
        <div className="gpo-pdf-viewer-wrapper">
          <div className="gpo-pdf-details">
            <div className ="gpo-pdf-details__name">
               <img src={arrow_back_yellow} onClick={handleCloseDoc}/>
               <span>{documentDetails?.documentName}</span>
            </div>
          </div>
          <div className="gpo-pdf-navigation">
            <div className="gpo-pdf-navigation__sources">
                <div className="sources-header">
                   <img src={page_icon}/>
                   <span>Pages Sourced</span>
                </div>
                <div className="sources-body">
                {pageNum?.map((pageDetail:IDocumentPageDetails,index)=>(
                   <p className={activePage==index?"sources-body__page active":"sources-body__page"} onClick={()=>{handlePageSourceClick(index,pageDetail?.pageNumber)}}>
                     <span>Page {pageDetail?.pageNumber}</span>
                     {activePage==index && (
                       <img src={right_icon}/>
                     )}     
                   </p>
                ))}
                </div>
            </div>
           <div className="gpo-pdf-viewer">
          <div className="doc">
            <div className="gpo-pdf-header">
              <span>{documentDetails?.documentName}</span>
              <div className="gpo-pdf-header__actions">
                {!fileLoading && (
                <>
                  {/* <img src={pdf_info_icon} title={"information"}/>                */}
                  <img src={pdf_zoom_out} title={"zoom-out"} onClick={() => setScale({type: 'ZOOMOUT'})}/>
                  <img src={pdf_zoom_in} title={"zoom-in"} onClick={() => setScale({type: 'ZOOMIN'})}/>
                  <img src={pdf_download} title={"download"} onClick={()=>{downloadFile(pdfUrl)}}/> 
                </>            
                )}   
                <img src={close_btn} title={"close"} onClick={handleCloseDoc}/>
              </div>
            </div>
           {!fileStatus && (
             <p className="loading">Loading...</p>
           )} 

           <Document
            file={pdfUrl}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            >
            {pageNum?.map((pageDetail:IDocumentPageDetails,index)=>(
              <div
              key={index}
              ref={(el) => {
                pageRefs.current[pageDetail?.pageNumber] = el;
              }}
             >
              <Page 
              pageNumber={pageDetail?.pageNumber} 
              renderAnnotationLayer={false} 
              renderTextLayer={false}
              scale={zoomState?.scale}
              /> 
              </div>     
            ))}
            
          </Document>
          
          </div>
           </div>
         </div>
        </div>
      {shown && ReactDOM.createPortal(modalBody(), document.body)}
    </React.Fragment>
  );
};

export default memo(PDFViewer);
