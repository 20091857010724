import "./ChartsSkeletonLoader.scss";

const ChartsSkeletonLoader = () => {
  return (
    <div className="chart-skelton-loader">
      <div className="chart-skelton-loader__container">
        <h1 className="chart-skelton-loader__container--header">
          <span></span>
        </h1>
        <div className="chart-skelton-loader__container--body">
          <div className="chart-circle"></div>
          <div className="chart-square"></div>
        </div>
      </div>
    </div>
  );
};

export default ChartsSkeletonLoader;
