import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import { Cell, ResponsiveContainer } from "recharts";
import UserEngagement from "../UserEngagement/UserEngagement";
import {
  chevron_right,
  info,
  sort_down,
  sort_up,
  file_download,
  refreshIcon,
} from "assets/media";
import {
  ChartsSkeletonLoader,
  DateRangePicker,
  MultiSelect,
  PieChart,
  Rating,
  SpeedoMeter,
  WorldMap,
  LineChart,
  BarChart,
  UserFeedBackSkeletonLoader,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ProgressBar,
} from "common/components";
import { DateRange } from "common/models";
import { ApiStatus, CALENDAR_BTNS } from "common/enums";
import { ArrayUtil, FileUtil, calendarBtnsUtil } from "common/utils";
import { useInterval, useOnClickOutside } from "common/hooks";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { dashboardActions } from "modules/gpo-agent/redux/dashboard";
import { useDashboard } from "./hooks/useDashboard";
import {
  AVG_TIME_COLORS,
  SENTIMENT_DISTRIBUTION_COLORS,
  HAND_OFF_RATE_COLORS,
  RESOLVED_RATE_COLORS,
} from "common/config/metric-dashboard.config";
import {
  AvgTimeLineData,
  BarData,
  DashboardDataRequest,
  UnresolvedRateLineData,
  MeterData,
  PieData,
  ResolvedRateLineData,
  UserRatings,
} from "modules/gpo-agent/models/dashboard";
import moment from "moment";
import { TabType } from "common/enums/tab-types.enum";
import { DashboardService } from "modules/gpo-agent/redux/dashboard/dashboard.service";
import { AUTO_REFRESH_INTERVAL_MS } from "common/config/app.config";
import "./Dashboard.scss";

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const {
    data: { countries },
    status: countriesStatus,
  } = useSelector((state: RootState) => state.header.countryResponse);

  const {} = useSelector(
    (state: RootState) => state.dashboard.userEngagementData
  );

  const { data: resolvedRateData, status: resolvedRateDataStatus } =
    useSelector((state: RootState) => state.dashboard.resolvedData);

  const { data: sentimentDistData, status: sentimentDistDataStatus } =
    useSelector((state: RootState) => state.dashboard.sentimentDistData);

  const { data: unresolvedRateData, status: unresolvedRateDataStatus } =
    useSelector((state: RootState) => state.dashboard.unresolvedRateData);

  const { data: avgTimeData, status: avgTimeDataStatus } = useSelector(
    (state: RootState) => state.dashboard.avgTimeToResolveData
  );

  const { data: userRatingsData, status: userRatingsDataStatus } = useSelector(
    (state: RootState) => state.dashboard.userRatingsData
  );

  const lastUpdatedTime = useSelector(
    (state: RootState) => state.dashboard.lastUpdatedTime
  );

  const datePickerRef = useRef(null);
  const [showWorldMap, setShowWorldMap] = useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateRange>(
    calendarBtnsUtil.getLast30DaysRange() as DateRange
  );
  const [showExportConfirmation, setShowExportConfirmation] = useState(false);
  const [exportStatus, setExportStatus] = useState(ApiStatus.NONE);
  const {
    transformResolvedRatePieData,
    transformResolvedRateLineData,
    transformUnresolvedRatePieData,
    transformUnresolvedRateLineData,
    transformAvgTimeMeterData,
    transformAvgTimeLineData,
    transformSentimentDistPieData,
    transformSentimentDistBarData,
    transformUserRatingsData,
  } = useDashboard();

  const [resolvedRatePieData, setResolvedRatePieData] = useState<PieData[]>([]);
  const [resolvedRateLineChartData, setResolvedRateLineChartData] = useState<
    ResolvedRateLineData[]
  >([]);

  const [sentimentDistPieData, setSentimentDistPieData] = useState<PieData[]>(
    []
  );
  const [sentimentDistBarChartData, setSentimentDistBarChartData] = useState<
    BarData[]
  >([]);

  const [unresolvedRatePieData, setUnresolvedRatePieData] = useState<PieData[]>(
    []
  );
  const [unresolvedRateLineChartData, setUnresolvedRateLineChartData] =
    useState<UnresolvedRateLineData[]>([]);

  const [avgTimeMeterData, setAvgTimeMeterData] = useState<MeterData>({
    name: "",
    value: 0,
  });
  const [avgTimeLineData, setAvgTimeLineData] = useState<AvgTimeLineData[]>([]);
  const [userRatings, setUserRatings] = useState<UserRatings>({
    averageRating: 0,
    totalRatings: 0,
    ratingsPerStar: [],
  });

  const calendarOptions = Object.values(CALENDAR_BTNS);

  useOnClickOutside(datePickerRef, () => {
    if (isCalendarOpen) {
      setIsCalendarOpen(false);
    }
  });

  useEffect(() => {
    dispatch(appHeaderActions.getAllCountries());
  }, []);

  useEffect(() => {
    clearComponentStates();
    callChartApis();
  }, [selectedCountries, dateFilter]);

  // transform data for resolved rate charts
  useEffect(() => {
    if (resolvedRateDataStatus === ApiStatus.SUCCESS) {
      const pieChartData = transformResolvedRatePieData(resolvedRateData);
      const lineChartData = transformResolvedRateLineData(resolvedRateData);
      setResolvedRatePieData(pieChartData);
      setResolvedRateLineChartData(lineChartData);
    }
  }, [resolvedRateData, resolvedRateDataStatus]);

  // transform data for first resolution rate charts
  useEffect(() => {
    if (sentimentDistDataStatus === ApiStatus.SUCCESS) {
      const pieChartData = transformSentimentDistPieData(sentimentDistData);
      const barChartData = transformSentimentDistBarData(sentimentDistData);
      setSentimentDistPieData(pieChartData);
      setSentimentDistBarChartData(barChartData);
    }
  }, [sentimentDistData, sentimentDistDataStatus]);

  // transform data for unresolved rate charts
  useEffect(() => {
    if (unresolvedRateDataStatus === ApiStatus.SUCCESS) {
      const pieChartData = transformUnresolvedRatePieData(unresolvedRateData);
      const lineChartData = transformUnresolvedRateLineData(unresolvedRateData);
      setUnresolvedRatePieData(pieChartData);
      setUnresolvedRateLineChartData(lineChartData);
    }
  }, [unresolvedRateData, unresolvedRateDataStatus]);

  // transform data for avg time charts
  useEffect(() => {
    if (avgTimeDataStatus === ApiStatus.SUCCESS) {
      const speedometerData = transformAvgTimeMeterData(avgTimeData);
      const lineChartData = transformAvgTimeLineData(avgTimeData);
      setAvgTimeMeterData(speedometerData);
      setAvgTimeLineData(lineChartData);
    }
  }, [avgTimeData, avgTimeDataStatus]);

  //transform data for user feedback/ratings
  useEffect(() => {
    if (userRatingsDataStatus === ApiStatus.SUCCESS) {
      const userRatings = transformUserRatingsData(userRatingsData);
      setUserRatings(userRatings);
    }
  }, [userRatingsData, userRatingsDataStatus]);

  const callChartApis = () => {
    resetInterval();

    const request: DashboardDataRequest = {
      filter: {
        // startDate: calendarBtnsUtil.formatToYYYYMMDD(dateFilter[0]),
        // endDate: calendarBtnsUtil.formatToYYYYMMDD(dateFilter[1]),        
        startDate: dateFilter[0] ? moment(dateFilter[0])?.startOf('day')?.toISOString():null,
        endDate: dateFilter[1] ?moment(dateFilter[1])?.endOf('day')?.toISOString():null,
        countryGuids:
          selectedCountries?.length === countries.length
            ? []
            : selectedCountries,
      },
      ratingFilters: null,
    };

    dispatch(dashboardActions.getTotalTraffic(request));
    dispatch(dashboardActions.getEngagedUsers(request));
    dispatch(dashboardActions.getTotalLikes({ ...request, thumbValue: true }));
    dispatch(
      dashboardActions.getTotalDislikes({ ...request, thumbValue: false })
    );
    dispatch(dashboardActions.getTotalQuestions(request));
    dispatch(dashboardActions.getTotalConversations(request));
    dispatch(dashboardActions.getTop5SourceDocsBegin(request));
    dispatch(dashboardActions.getResolvedRateData(request));
    dispatch(dashboardActions.getUnresolvedRateData(request));
    dispatch(dashboardActions.getAvgTimeToResolveData(request));
    dispatch(dashboardActions.getSentimentDistData(request));
    dispatch(dashboardActions.getUserRatingsData(request));
    dispatch(dashboardActions.getTrafficDataForMapsBegin(request));
  };

  // auto-refresh the data
  const { resetInterval } = useInterval(
    callChartApis,
    AUTO_REFRESH_INTERVAL_MS
  );

  const clearComponentStates = () => {
    setResolvedRatePieData([]);
    setResolvedRateLineChartData([]);
    setUnresolvedRatePieData([]);
    setUnresolvedRateLineChartData([]);
    setAvgTimeMeterData({ name: "", value: 0 });
    setAvgTimeLineData([]);
  };

  const handleCountryChange = (countries) => {
    const countryIds = countries.map((country) => country.id);

    //check if user selection is updated
    if (!ArrayUtil.areArrayValuesEqual(countryIds, selectedCountries)) {
      setSelectedCountries(countryIds);
    }
  };

  const handleMapBack = () => {
    setShowWorldMap(false);
  };

  const handleChevronIconClick = (component: TabType) => {
    switch (component) {
      case TabType.METRIC_DASHBOARD:
        setShowWorldMap(true);
        break;
      case TabType.QUESTION_DETAILS:
        dispatch(appHeaderActions?.updateTabType(TabType.QUESTION_DETAILS));
        break;
      case TabType.USER_REVIEW:
        dispatch(appHeaderActions?.updateTabType(TabType.USER_REVIEW));
        break;
    }
  };

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleCalendarBtns = (selectedBtn: string) => {
    toggleCalendar();
    switch (selectedBtn) {
      case CALENDAR_BTNS.THIS_WEEK: {
        const thisWeekRange = calendarBtnsUtil.getThisWeekRange();
        handleDateRangeChange(thisWeekRange);
        break;
      }

      case CALENDAR_BTNS.LAST_WEEK: {
        const lastWeekRange = calendarBtnsUtil.getLastWeekRange();
        handleDateRangeChange(lastWeekRange);
        break;
      }

      case CALENDAR_BTNS.LAST_15_DAYS: {
        const lastFifteenDays = calendarBtnsUtil.getLast15DaysRange();
        handleDateRangeChange(lastFifteenDays);
        break;
      }

      case CALENDAR_BTNS.CURRENT_MONTH: {
        const currentMonthRange = calendarBtnsUtil.getThisMonthRange();
        handleDateRangeChange(currentMonthRange);
        break;
      }

      case CALENDAR_BTNS.LAST_MONTH: {
        const lastMonthRange = calendarBtnsUtil.getLastMonthRange();
        handleDateRangeChange(lastMonthRange);
        break;
      }

      case CALENDAR_BTNS.RESET: {
        const lastThirtyDays = calendarBtnsUtil.getLast30DaysRange();
        handleDateRangeChange(lastThirtyDays);
        break;
      }
    }
  };

  const handleDateRangeChange = (date) => {
    toggleCalendar();
    if (date && date[0] && date[1]) {
      setDateFilter(date);
    } else {
      setDateFilter([null, null]);
    }
  };

  const handleRefresh = () => {
    callChartApis();
  };

  const handleExportIconClick = () => {
    setShowExportConfirmation(true);
  };

  const handleExport = async () => {
    setShowExportConfirmation(false);
    setExportStatus(ApiStatus.LOADING);
    const exportRequest: DashboardDataRequest = {
      filter: {
        startDate: calendarBtnsUtil.formatToYYYYMMDD(dateFilter[0]),
        endDate: calendarBtnsUtil.formatToYYYYMMDD(dateFilter[1]),
        countryGuids: selectedCountries,
      },
    };

    const { hasErrors, value } = await DashboardService.export(exportRequest);
    const fileName = `Metric dashboard_${moment().format(
      "YYYYMMDDHHmmss"
    )}.csv`;

    if (!hasErrors) {
      FileUtil.stringToFile(value, fileName, "text/csv");
      setExportStatus(ApiStatus.SUCCESS);
    } else setExportStatus(ApiStatus.ERROR);
  };

  const countryOptions = useMemo(
    () =>
      countries.map((country) => {
        return {
          id: country.guidCode,
          name: country.name,
        };
      }),
    [countries]
  );

  return (
    <div className="dashboard-container">
      <div className="dashboard-container__header">
        <div className="dashboard-container__header--left">
          <div className="dashboard-container__header--title">
            <h2>Metric Dashboard</h2>
            <p className="last-update">{`Last updated on ${lastUpdatedTime}`}</p>
          </div>

          <div className="dashboard-container__header--actions">
            <div className="select-container">
              <p className="country-label">Country: </p>
              <MultiSelect
                className="country-select"
                multi={true}
                label={"Select"}
                placeholder="Search..."
                defaultOption={countryOptions}
                searchable
                showSelectAll
                checkBoxName={"country"}
                onChange={(options) => console.log("")}
                onDropdownClose={(options) => handleCountryChange(options)}
                data={countryOptions}
              />
            </div>

            <div className="date-picker">
              <p className="date-picker__label">Duration: </p>
              <div className="date-picker__wrapper" ref={datePickerRef}>
                {isCalendarOpen && (
                  <div className="action-btns">
                    {calendarOptions.map((option, index) => (
                      <button
                        key={index}
                        className={"btn"}
                        onClick={() => handleCalendarBtns(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}

                <DateRangePicker
                  className="custom-date-picker"
                  label=""
                  value={dateFilter}
                  format="dd/MM/y"
                  maxDate={new Date()}
                  isOpen={isCalendarOpen}
                  hideOnOutsideClick={false}
                  customCalendarIcon={
                    <img
                      className="calendar__arrow"
                      src={isCalendarOpen ? sort_up : sort_down}
                      alt="arrow"
                      onClick={() => {
                        if (isCalendarOpen) {
                          setIsCalendarOpen(false);
                        }
                      }}
                    />
                  }
                  onChange={handleDateRangeChange}
                  onCalendarOpen={() => setIsCalendarOpen(true)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-container__header--right">
          <button
            className="dashboard-container__header--right__action-btn"
            onClick={handleRefresh}
          >
            <img src={refreshIcon} />
            <p>Refresh</p>
          </button>
          <button
            className="dashboard-container__header--right__action-btn"
            onClick={handleExportIconClick}
          >
            <img src={file_download} />
            <p>Export Dashboard</p>
          </button>
        </div>
      </div>
      <div className="dashboard-container__body">
        {showWorldMap ? (
          <WorldMap onBack={handleMapBack} />
        ) : (
          <>
            <UserEngagement onIconClick={handleChevronIconClick} />
            <div className="bot-performance">
              <p>Bot Performance</p>
              <div className="bot-performance-row">
                <div className="bot-performance-container">
                  {resolvedRateDataStatus === ApiStatus.LOADING ? (
                    <ChartsSkeletonLoader />
                  ) : resolvedRateDataStatus === ApiStatus.ERROR ||
                    (resolvedRateDataStatus === ApiStatus.SUCCESS &&
                      !(
                        resolvedRatePieData?.[0]?.value ||
                        resolvedRatePieData?.[1]?.value
                      )) ? (
                    <p>No data found</p>
                  ) : (
                    <>
                      <div className="pie-chart-container">
                        <p className="chart-title">
                          Resolved Rate (%)
                          <Tooltip
                            text={
                              "It provides insights into the effectiveness of question resolution."
                            }
                          >
                            <img src={info} />
                          </Tooltip>
                        </p>
                        <ResponsiveContainer
                          width={130}
                          height={170}
                          debounce={250}
                        >
                          <PieChart
                            data={resolvedRatePieData}
                            dataKey="value"
                            legendInfo={[
                              {
                                label: "Resolved",
                                color: RESOLVED_RATE_COLORS.resolved,
                              },
                              {
                                label: "Unresolved",
                                color: RESOLVED_RATE_COLORS.unresolved,
                              },
                            ]}
                            labelTitle={"Rate"}
                            labelValue={Number(resolvedRateData.resolvedRate)}
                          >
                            {resolvedRatePieData.map((data, index) => (
                              <Cell key={`cell-${index}`} fill={data.color} />
                            ))}
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="line-chart-container">
                        <ResponsiveContainer
                          width="100%"
                          height={200}
                          debounce={250}
                        >
                          <LineChart
                            data={resolvedRateLineChartData}
                            xKey="name"
                            linesConfig={[
                              {
                                dataKey: "resolved",
                                stroke: RESOLVED_RATE_COLORS.resolved,
                                tooltipLabel: "Resolved Rate",
                              },
                              {
                                dataKey: "unresolved",
                                stroke: RESOLVED_RATE_COLORS.unresolved,
                                tooltipLabel: "Unresolved Rate",
                              },
                            ]}
                          ></LineChart>
                        </ResponsiveContainer>
                      </div>
                    </>
                  )}
                </div>

                <div className="bot-performance-container">
                  {sentimentDistDataStatus === ApiStatus.LOADING ? (
                    <ChartsSkeletonLoader />
                  ) : sentimentDistDataStatus === ApiStatus.ERROR ||
                    (sentimentDistDataStatus === ApiStatus.SUCCESS &&
                      !(
                        sentimentDistPieData?.[0]?.value ||
                        sentimentDistPieData?.[1]?.value
                      )) ? (
                    <p>No data found</p>
                  ) : (
                    <>
                      <div className="pie-chart-container sentiment-pie">
                        <p className="chart-title">
                          Sentiment Distribution
                          <Tooltip
                            text={
                              "Gauges the overall sentiment of users towards the chatbot's responses."
                            }
                          >
                            <img src={info} />
                          </Tooltip>
                        </p>
                        <ResponsiveContainer
                          width={130}
                          height={170}
                          debounce={250}
                        >
                          <PieChart
                            data={sentimentDistPieData}
                            dataKey="value"
                            legendInfo={[
                              {
                                label: "Likes",
                                color: SENTIMENT_DISTRIBUTION_COLORS.like,
                              },
                              {
                                label: "Dislikes",
                                color: SENTIMENT_DISTRIBUTION_COLORS.unlike,
                              },
                              {
                                label: "No Action",
                                color: SENTIMENT_DISTRIBUTION_COLORS.noAction,
                              },
                            ]}
                            labelTitle={"Likes"}
                            labelValue={Number(sentimentDistData.likes)}
                          >
                            {sentimentDistPieData.map((data, index) => (
                              <Cell key={`cell-${index}`} fill={data.color} />
                            ))}
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="bar-chart-container">
                        <ResponsiveContainer
                          width="100%"
                          height={200}
                          debounce={250}
                        >
                          <BarChart
                            data={sentimentDistBarChartData}
                            xKey="name"
                            barsConfig={[
                              {
                                dataKey: "likes",
                                fill: SENTIMENT_DISTRIBUTION_COLORS.like,
                                tooltipLabel: "Likes",
                              },
                              {
                                dataKey: "unlikes",
                                fill: SENTIMENT_DISTRIBUTION_COLORS.unlike,
                                tooltipLabel: "Dislikes",
                              },
                              {
                                dataKey: "noAction",
                                fill: SENTIMENT_DISTRIBUTION_COLORS.noAction,
                                tooltipLabel: "No action",
                              },
                            ]}
                          ></BarChart>
                        </ResponsiveContainer>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="bot-performance-row">
                <div className="bot-performance-container">
                  {avgTimeDataStatus === ApiStatus.LOADING ? (
                    <ChartsSkeletonLoader />
                  ) : avgTimeDataStatus === ApiStatus.ERROR ||
                    (avgTimeDataStatus === ApiStatus.SUCCESS &&
                      !avgTimeLineData.length) ? (
                    <p>No data found</p>
                  ) : (
                    <>
                      <div className="speedometer-container">
                        <p className="chart-title">
                          Avg. time to Resolve
                          <Tooltip
                            text={
                              "This metric represents the average time in seconds it takes to provide a response."
                            }
                          >
                            <img src={info} />
                          </Tooltip>
                        </p>

                        <SpeedoMeter value={avgTimeMeterData.value} />
                      </div>

                      <div className="line-chart-container">
                        <ResponsiveContainer
                          width="100%"
                          height={200}
                          debounce={250}
                        >
                          <LineChart
                            data={avgTimeLineData}
                            xKey="name"
                            isAvgTimeChart={true}
                            linesConfig={[
                              {
                                dataKey: "avgTime",
                                stroke: AVG_TIME_COLORS.avgTime,
                                tooltipLabel: "Avg time to resolve",
                              },
                            ]}
                          ></LineChart>
                        </ResponsiveContainer>
                      </div>
                    </>
                  )}
                </div>

                <div className="bot-performance-container">
                  {unresolvedRateDataStatus === ApiStatus.LOADING ? (
                    <ChartsSkeletonLoader />
                  ) : unresolvedRateDataStatus === ApiStatus.ERROR ||
                    (unresolvedRateDataStatus === ApiStatus.SUCCESS &&
                      !(
                        unresolvedRatePieData?.[0]?.value ||
                        unresolvedRatePieData?.[1]?.value
                      )) ? (
                    <p>No data found</p>
                  ) : (
                    <>
                      <div className="pie-chart-container">
                        <p className="chart-title">
                          Unresolved Rate (%)
                          <Tooltip
                            text={
                              "It measures percentage of questions that the bot was unable to answer and needed to be handed off to knowledge worker."
                            }
                          >
                            <img src={info} />
                          </Tooltip>
                        </p>
                        <ResponsiveContainer
                          width={130}
                          height={170}
                          debounce={250}
                        >
                          <PieChart
                            data={unresolvedRatePieData}
                            dataKey="value"
                            legendInfo={[
                              {
                                label: "Sent to Agent",
                                color: HAND_OFF_RATE_COLORS.sentToAgent,
                              },
                              {
                                label: "",
                                color: "",
                              },
                            ]}
                            labelTitle={"Rate"}
                            labelValue={Number(unresolvedRateData.handOffRate)}
                          >
                            {unresolvedRatePieData.map((data, index) => (
                              <Cell key={`cell-${index}`} fill={data.color} />
                            ))}
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div
                        className={`line-chart-container ${
                          !unresolvedRateLineChartData?.length
                            ? "line-chart-container__no-data"
                            : ""
                        }`}
                      >
                        {unresolvedRateLineChartData?.length ? (
                          <>
                            <ResponsiveContainer
                              width="100%"
                              height={200}
                              debounce={250}
                            >
                              <LineChart
                                data={unresolvedRateLineChartData}
                                xKey="name"
                                linesConfig={[
                                  {
                                    dataKey: "rate",
                                    stroke: HAND_OFF_RATE_COLORS.sentToAgent,
                                    tooltipLabel: "Unresolved Rate",
                                  },
                                ]}
                              ></LineChart>
                            </ResponsiveContainer>{" "}
                          </>
                        ) : (
                          <p>No data found</p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="user-feedback">
              <p>User Feedback</p>
              <div className="user-feedback-container">
                {userRatingsDataStatus === ApiStatus.LOADING ? (
                  <UserFeedBackSkeletonLoader />
                ) : userRatingsDataStatus === ApiStatus.ERROR ||
                  (userRatingsDataStatus === ApiStatus.SUCCESS &&
                    !userRatings.totalRatings) ? (
                  <p className="no-data">No data found</p>
                ) : (
                  <>
                    <div className="ratings-info">
                      <p className="ratings-info__heading">User Ratings</p>
                      <div className="ratings-info__rate">
                        <Rating value={userRatings?.averageRating} />
                        {`${userRatings?.averageRating} out of 5`}
                      </div>
                      <p className="ratings-info__description">
                        {`${userRatings?.totalRatings} overall satisfaction rating`}
                        <span>
                          <img
                            src={chevron_right}
                            onClick={() =>
                              handleChevronIconClick(TabType.USER_REVIEW)
                            }
                          />
                        </span>
                      </p>
                    </div>

                    <div className="ratings-container">
                      {userRatings?.ratingsPerStar?.map((item, index) => {
                        return (
                          <div className="ratings-container__row" key={index}>
                            <p className="ratings-container__row--label">
                              {item.starNum} star
                            </p>
                            <div className="ratings-container__row--outerline">
                              <div
                                className="ratings-container__row--innerline"
                                style={{ width: `${item.ratingPercent}%` }}
                              ></div>
                            </div>
                            <p className="ratings-container__row--value">{`${item.ratingPercent}%`}</p>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {showExportConfirmation && (
        <Modal>
          <ModalHeader onClose={() => setShowExportConfirmation(false)}>
            Export Confirmation
          </ModalHeader>
          <ModalBody>
            The export file is based on the filters applied. Do you want to
            continue ?
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={handleExport}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowExportConfirmation(false)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {exportStatus === ApiStatus.LOADING && (
        <Modal>
          <ModalBody className="user-review__export-progress">
            <ProgressBar label="Export is being generated. Please wait before taking any other action..." />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;
