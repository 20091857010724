import { ApiResult } from "common/models";
import { AppHeaderService } from "./header.service";
import { appHeaderActions } from "./header.slice";
import { put, takeLatest } from "redux-saga/effects";


const getAllCountries = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AppHeaderService.getAllCountries();
    
        if (hasErrors) {
            yield put({
                type: appHeaderActions.getAllCountriesFailed.type,
                payload: errors
            });
        } else {
            yield put({
                type: appHeaderActions.getAllCountriesSuccess.type,
                payload: value
            });
        }
    };
    
const getAllCountriesWatch = function* () {
    yield takeLatest(appHeaderActions.getAllCountries.type, getAllCountries);
};
const getAllAdmins = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AppHeaderService.getAllDocumentAdmins();
    
        if (hasErrors) {
            yield put({
                type: appHeaderActions.getAllDocumentAdminsFailed.type,
                payload: errors
            });
        } else {
            yield put({
                type: appHeaderActions.getAllDocumentAdminsSuccess.type,
                payload: value
            });
        }
    };
    
const getAllAdminsWatch = function* () {
    yield takeLatest(appHeaderActions.getAllDocumentAdmins.type, getAllAdmins);
};
const getAllDocumentStatues= function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AppHeaderService.getAllDocumentStatues();
    
        if (hasErrors) {
            yield put({
                type: appHeaderActions.getAllDocumentStatusesFailed.type,
                payload: errors
            });
        } else {
            yield put({
                type: appHeaderActions.getAllDocumentStatusesSuccess.type,
                payload: value
            });
        }
    };
    
const getAllDocumentStatuesWatch = function* () {
    yield takeLatest(appHeaderActions.getAllDocumentStatuses.type, getAllDocumentStatues);
};

const archiveDocuments= function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AppHeaderService.archiveDocument(action?.payload);
    
        if (hasErrors) {
            yield put({
                type: appHeaderActions.archiveDocumentError.type,
                payload: errors
            });
        } else {
            yield put({
                type: appHeaderActions.archiveDocumentSuccess.type,
                payload: value
            });
        }
    };
    
const archiveDocumentWtach = function* () {
    yield takeLatest(appHeaderActions.archiveDocument.type, archiveDocuments);
};

const downloadDocument = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AppHeaderService.downloadDocument(action?.payload);
    
        if (hasErrors) {
            yield put({
                type: appHeaderActions.downloadDocumentError.type,
                payload: errors
            });
        } else {
            yield put({
                type: appHeaderActions.downloadDocumentSuccess.type,
                payload: value
            });
        }
    };
    
const downloadDocumentWatch = function* () {
    yield takeLatest(appHeaderActions.downloadDocument.type, downloadDocument);
};

const exportDocument = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield AppHeaderService.exportDocument(action?.payload);
    
        if (hasErrors) {
            yield put({
                type: appHeaderActions.exportDocumentError.type,
                payload: errors
            });
        } else {
            yield put({
                type: appHeaderActions.exportDocumentSuccess.type,
                payload: value
            });
        }
    };
    
const exportDocumentWatch = function* () {
    yield takeLatest(appHeaderActions.exportDocument.type, exportDocument);
};

export {
    getAllCountriesWatch,
    getAllDocumentStatuesWatch,
    getAllAdminsWatch,
    archiveDocumentWtach,
    downloadDocumentWatch,
    exportDocumentWatch
}

