const isObject = (object) => {
    return object && typeof object === 'object' && !Array.isArray(object);
};

const isObjectEmpty = (obj) =>
    isObject(obj) &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype;

const compare = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !compare(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
};

const areObjValuesEqual = (baseObj, objToCompare) => {
    if (typeof baseObj !== 'object' || typeof objToCompare !== 'object') {
        return baseObj == objToCompare;
    }

    const keys2 = Object.keys(objToCompare);

    for (const key of keys2) {
        if (baseObj[key] === null && objToCompare[key] === null) {
            continue;
        } else if (!areObjValuesEqual(baseObj[key], objToCompare[key])) {
            return false;
        }
    }

    return true;
};

export default { isObjectEmpty, compare, isObject, areObjValuesEqual };
