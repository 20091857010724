import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { userActions } from "common/redux/store/user";
import { IDLE_TIME_IN_MIN } from "common/config/app.config";
import { useAppDispatch } from "common/redux/core/root.reducer";

const useSessionTimeout = () => {
  const dispatch = useAppDispatch();
  const IDLE_TIME_OUT = 1000 * 60 * parseInt(IDLE_TIME_IN_MIN.toString());

  const handleOnIdle = (event: Event | undefined) => {
    reset();
    dispatch(userActions.setSessionTimedOut(true));
    dispatch(userActions.logoutUser());
  };

  const { reset } = useIdleTimer({
    timeout: IDLE_TIME_OUT,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    reset();
  }, []);

  return null;
};

export default useSessionTimeout;
