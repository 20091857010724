import { ApiResult } from "common/models";
import { HttpClient } from "common/services/http-client.service";
import { DocumentTypes } from "modules/gpo-agent/enums/document-types.enum";
import { AnnotateMessageRequest } from "modules/gpo-agent/models/chat-history/chat-history-annotate-message.model";
import agentsChatHistoryApiConfig from "./agent-chat-history.config";

export class AgentsChatHistoryService extends HttpClient {
    public static async getChatHistoryList(searchText?:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.chatHistory(searchText));
    }
    public static async getChatHistoryMessages(handoffGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.chatHistoryMessages(handoffGuid));
    }
    public static async getReferenceDocuments(documentType:DocumentTypes): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.referenceDocument(documentType));
    }
    public static async completeAnnotation(handoffGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.completeAnnotation(handoffGuid));
    }
    public static async annotateMessage(requestDetails:AnnotateMessageRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.annotateMessage(requestDetails));
    }
    public static async getAllDocuments(): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.getAllDocuments());
    }
    public static async uploadDocument(data: FormData): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsChatHistoryApiConfig.uploadDocument(data));
    }
}
