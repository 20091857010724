import { put, takeLatest } from 'redux-saga/effects';
import { IGraphUser } from 'common/models/user/graph-user.model';
import { UserService } from './user.service';
import { userActions } from './index';
import { ApiResult } from 'common/models';

const getUserProfile = function* () {
    const profile: ApiResult<IGraphUser> = yield UserService.getUserProfile();
    yield put({
        type: userActions.getUserProfileSuccess.type,
        payload: profile.value
    });
};

const getUserProfileWatch = function* () {
    yield takeLatest(userActions.getUserProfile.type, getUserProfile);
};


const getCurrentUserAvatar = function* () {
    const profileImage: ApiResult<Blob> = yield UserService.getUserImage();
    try {
        yield put({
            type: userActions.getCurrentUserAvatarSuccess.type,
            payload: profileImage?.value ? URL.createObjectURL(profileImage.value) : ''
        });
    } catch (e) {
        yield put({
            type: userActions.getCurrentUserAvatarSuccess.type,
            payload: ''
        });
    }
};

const getCurrentUserAvatarWatch = function* () {
    yield takeLatest(userActions.getUserProfile.type, getCurrentUserAvatar);
};

const getUserAvatar = function* (action) {
    const profileImage: ApiResult<Blob> = yield UserService.getUserAvatar(action?.payload);    
        try {
            yield put({
                type: userActions.getUserAvatarSuccess.type,
                payload: profileImage?.value ? URL.createObjectURL(profileImage.value) : ''
            });
        } catch (e) {
            yield put({
                type: userActions.getUserAvatarSuccess.type,
                payload: ''
            });
        }
};

const getUserAvatarWatch = function* () {
    yield takeLatest(userActions.getUserAvatar.type, getUserAvatar);
};

const requestAccess = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield UserService.requestAccess(action?.payload);

    if (hasErrors) {
        yield put({
            type: userActions.requestAccessFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: userActions.requestAccessSuccess.type,
            payload: value
        });
    }
};

const requestAccessWatch = function* () {
    yield takeLatest(userActions.requestAccess.type, requestAccess);
};

const logoutUser = function* () {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield UserService.logoutUser();

    if (hasErrors) {
        yield put({
            type: userActions.logoutUserFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: userActions.logoutUserSuccess.type,
            payload: value
        });
    }
};

const logoutUserWatch = function* () {
    yield takeLatest(userActions.logoutUser.type, logoutUser);
};

export { getUserProfileWatch, getCurrentUserAvatarWatch,getUserAvatarWatch,requestAccessWatch, logoutUserWatch };
