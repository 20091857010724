import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "common/redux/core/configure-store";
import App from "./App";

import "./index.scss";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={configureStore()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);
