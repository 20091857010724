import { FC } from "react";
import "./UserEngmntFieldsSkeleton.scss";

const UserEngmntFieldsSkeleton: FC = () => {
  return (
    <div className="user-eng-field-loader">
      <div className="user-eng-field-loader--header">
        <p className="value"></p>
      </div>
    </div>
  );
};

export default UserEngmntFieldsSkeleton;
