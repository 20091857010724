import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSigningStatus } from 'common/enums/user.enum';
import { IGraphUser } from 'common/models';
import { RequestAccessModel, RequestAccessResponseModel } from 'common/models/request-access.model';
import { initialUserState } from './user.state';

const usersSlice = createSlice({
    name: 'users',
    initialState: initialUserState,
    reducers: {
        getUserProfile: (state) => {
            state.name = '';
        },
        getUserProfileSuccess: (state, { payload }: PayloadAction<IGraphUser>) => {
            state.id = payload.id;
            state.name = payload.displayName;
            state.emailId = payload.mail;
            state.isAuthenticated = true;
            state.isTokenBlacklisted = false;
        },
        getCurrentUserAvatar:(state) => {
            state.avatar = "";
        },
        getCurrentUserAvatarSuccess: (state, { payload }: PayloadAction<string>) => {
            state.avatar = payload;
        },
        setUserSigningStatus: (state, { payload }: PayloadAction<UserSigningStatus>) => {
            state.isSigned = payload;
        },
        getUserAvatar:(state, { payload }: PayloadAction<string>) => {
            state.userGuid = payload;
        },
        getUserAvatarSuccess: (state, { payload }: PayloadAction<string>) => {
            state.userAvatar = payload;
        },
        logoutUser:(state) => {
        },
        logoutUserSuccess: (state, { payload }: PayloadAction<any>) => {
            state.isTokenBlacklisted = true;
            state.isSigned = UserSigningStatus.SIGNED_OUT;
        },
        logoutUserFailed: (state, { payload }: PayloadAction<any>) => {
            state.isSigned = UserSigningStatus.SIGNED_OUT;
        },
        requestAccess:(state, { payload }: PayloadAction<RequestAccessModel>) => {
            
        },
        requestAccessSuccess: (state, { payload }: PayloadAction<RequestAccessResponseModel>) => {
            state.requestAccessResponse = payload;
        },
        requestAccessFailed: (state, { payload }: PayloadAction<any>) => {
            state.requestAccessResponse = undefined;
            console.log("Errors",payload);
        },
        setSessionTimedOut: (state, { payload }: PayloadAction<boolean>) => {
            state.isSessionTimedOut = payload;
        },

    }
});

export const userActions = usersSlice.actions;

export default usersSlice.reducer;
