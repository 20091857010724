import React, { FC, useEffect, useRef, useState } from "react";
import {
  RootState,
  useAppDispatch,
  useAppSelector
} from "common/redux/core/root.reducer";
import "./DocumentTableActions.scss";
import {
  add_version_icon,
  archive_grid_icon,
  download_grid_icon
} from "assets/images";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { ArchiveDocumentRequest } from "modules/gpo-agent/models/manage-document-filter/archive-document.model";
import { ManagedDocumentsRequest } from "modules/gpo-agent/models/filter-component/filter-values.model";
import { ManagedDocumentsFilterTypes } from "modules/gpo-agent/enums/managed-documents-filter-types.enum";
import { ManagedDocumentsType } from "modules/gpo-agent/enums/managed-documents-type.enum";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { DocumentTableOptions } from "modules/gpo-agent/enums/document-types.enum";
import { DocumentDownloadRequest } from "modules/gpo-agent/models/document/download-documentRequest.model";
import { FileUtil } from "common/utils";
import Loader from "common/components/base/Loader/Loader";

type DocumentTableActionsProps = {
  topValue: any;
  rowInfo: any;
  onCloseModal: (close: boolean) => void;
  onArchivedCompleted: () => void;
  onOptionClick: (option: DocumentTableOptions) => void;
};
const DocumentTableActions: FC<DocumentTableActionsProps> = ({
  topValue,
  rowInfo,
  onCloseModal,
  onArchivedCompleted,
  onOptionClick
}: DocumentTableActionsProps) => {
  const filterRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const archivedState = useAppSelector(
    (state: RootState) => state.header?.archiveDocumentState
  );
  const fileDownloadState = useAppSelector((state:RootState)=>state?.header?.downloadedFile);
  const [showLoader,setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (archivedState?.code == 200) {
      onArchivedCompleted();
      const request: ManagedDocumentsRequest = {
        filters: [
          {
            property: ManagedDocumentsFilterTypes?.ISARCHIVE,
            type: ManagedDocumentsType?.NONE,
            value: ["true"]
          }
        ]
      };
      dispatch(agentsActions.getTotalArchived(request));
      dispatch(appHeaderActions.resetTotalArchived());
      onCloseModal(false);
    }
  }, [archivedState]);

  useEffect(()=>{
    setShowLoader(false);
     if(fileDownloadState){
      if(fileDownloadState && fileDownloadState instanceof Blob){
        const element = document.createElement("a");
        element.href = URL.createObjectURL(fileDownloadState);
        element.download = rowInfo?.documentName;
        element.click();
        element.remove();
      }
      onCloseModal(false);
      dispatch(appHeaderActions?.resetDownloadDocument());
     }

  },[fileDownloadState])

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      onCloseModal(false);
    }
  };

  const downloadDocument = () => {
    if (rowInfo?.guidCode) {
      const request: DocumentDownloadRequest = {
        guidCode: rowInfo?.guidCode
      };
      dispatch(appHeaderActions.downloadDocument(request));
      setShowLoader(true);
      // onCloseModal(false);
    }
  };
  
  const archiveDocument = () => {
    if (rowInfo?.guidCode) {
      const request: ArchiveDocumentRequest = {
        guidCode: rowInfo?.guidCode
      };
      dispatch(appHeaderActions.archiveDocument(request));
      // onCloseModal(false);
    }
  };

  const handleDocumentOptionClick = (option: DocumentTableOptions) => {
    switch (option) {
      case DocumentTableOptions.Archive:
        archiveDocument();
        break;
      case DocumentTableOptions.Download:
        downloadDocument();
        break;
      case DocumentTableOptions.NewVersion:
        break;
    }
    onOptionClick(option);
  };

  return (
    <>
    {showLoader && (
    <Loader fullScreen={true} />
    )}
    <div
      className="document-table-actions"
      ref={filterRef}
      style={{ top: topValue, display: topValue ? "block" : "none" }}
    >
      {!rowInfo?.isArchived && rowInfo?.status!="In progress" &&(
      <button
        className="document-table-actions__version"
        onClick={() =>
          handleDocumentOptionClick(DocumentTableOptions.NewVersion)
        }
      >
        <img src={add_version_icon} />
        <span>Add New Version</span>
      </button>
      )}
      <button
        className="document-table-actions__download"
        onClick={() => handleDocumentOptionClick(DocumentTableOptions.Download)}
      >
        <img src={download_grid_icon} />
        <span>Download</span>
      </button>
      {!rowInfo?.isArchived && rowInfo?.status!="In progress" &&(
      <button
        className="document-table-actions__archive"
        onClick={() => handleDocumentOptionClick(DocumentTableOptions.Archive)}
      >
        <img src={archive_grid_icon} />
        <span>Archive</span>
      </button>
      )} 
    </div>
    </>
  );
};

export default DocumentTableActions;
