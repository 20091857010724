import React from 'react';
import './IconButton.scss';
import { close_icon_modal } from 'assets/images';

type IconButtonProps = {
    className?: string;
    onClick?: () => void;
}

const IconButton = ({className, onClick}: IconButtonProps) => {
    return (
        <button className={`${className} icon-button  icon-button-small`} onClick={onClick}>
            <img src={close_icon_modal}/>
        </button>
    );
};

export default IconButton;