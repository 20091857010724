import { add_button_white, clear, remove } from 'assets/media';
import { DateRangePicker, MultiSelect } from 'common/components';
import { SelectOptions } from 'common/models';
import { useAppSelector } from 'common/redux/core/root.reducer';
import { ObjectUtil } from 'common/utils';
import {
    CommentFilterOption,
    ReviewFilterTypes
} from 'modules/gpo-agent/enums/user-review-filter.enum';
import { UserReviewFilterBody, UserReviewFilters } from 'modules/gpo-agent/models';
import { feedbacksData } from 'modules/gpo-agent/models/dashboard/dashboard.model';
import { DashboardService } from 'modules/gpo-agent/redux/dashboard/dashboard.service';
import { UserReviewUtil } from 'modules/gpo-agent/utils';
import { Fragment, useEffect, useRef, useState } from 'react';
import './UserReviewFilter.scss';

type UserReviewFilterProps = {
    show: boolean;
    appliedFilters?: UserReviewFilters;
    searchFilter: string;
    autoRefreshCount: number;
    onCloseModal: () => void;
    onApply: (filter: UserReviewFilters) => void;
    onReset: () => void;
};
const UserReviewFilter = ({
    show,
    appliedFilters,
    searchFilter,
    autoRefreshCount,
    onCloseModal,
    onApply,
    onReset,
}: UserReviewFilterProps) => {
    const STAR_RATING = [5, 4, 3, 2, 1];
    const CommentsOptions: SelectOptions[] = [
        {
            id: CommentFilterOption.All,
            name: 'All'
        },
        {
            id: CommentFilterOption.Provided,
            name: 'Provided'
        },
        {
            id: CommentFilterOption.NotProvided,
            name: 'Not provided'
        }
    ];
    const filterRef: any = useRef(null);
    const countryResponse = useAppSelector((state) => state.header.countryResponse);
    const [filter, setFilter] = useState(UserReviewUtil.InitialFilter);
    const [showRating, setShowRating] = useState(true);
    const [feedbackData, setFeedbackData] = useState<feedbacksData[]>([]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (appliedFilters) {
            setFilter(appliedFilters);
        }
    }, [appliedFilters]);

    useEffect(() => {
        //call service and set the value
        const requestBody = UserReviewUtil.getFilterRequestBody(filter);
        requestBody.searchText = searchFilter;
        callUserFeedbackService(requestBody);
    }, [filter, searchFilter, autoRefreshCount]);

    const callUserFeedbackService = async (filter: UserReviewFilterBody | {}) => {
        const request = ObjectUtil.isObjectEmpty(filter)
            ? UserReviewUtil.UserFeedbackInitialRequest
            : (filter as UserReviewFilterBody);
        const response = await DashboardService.getUserRatingsData(request);
        if (response && response.value) {
            setFeedbackData(response.value.feedbacks);
        }
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            onCloseModal();
        }
    };

    const handleResetFilter = () => {
        setFilter(UserReviewUtil.EmptyFilter);
        onReset();
    };

    const handleDateRangeChange = (date) => {
        if (date && date[0] && date[1]) {
            setFilter((prev) => ({
                ...prev,
                duration: date
            }));
        } else {
            setFilter((prev) => ({
                ...prev,
                duration: [null, null]
            }));
        }
    };

    const handleRatingChange = (rating: number, checked: boolean) => {
        setFilter((prev) => ({
            ...prev,
            rating: {
                ...prev.rating,
                [rating]: checked
            }
        }));
    };

    const handleNotProvidedChange = (option: SelectOptions) => {
        setFilter((prev) => ({
            ...prev,
            [ReviewFilterTypes.Comment]: option
        }));
    };

    const transformCountries = () => {
        if (countryResponse && countryResponse.data.countries.length > 0) {
            return countryResponse.data.countries.map((country) => ({
                id: country.guidCode,
                name: country.name
            }));
        } else {
            return [];
        }
    };

    const handleCountryChange = (option: SelectOptions | SelectOptions[]) => {
        const selected = Array.isArray(option) ? option : [option];
        setFilter((prev) => ({
            ...prev,
            country: selected
        }));
    };

    const getRatingFeedbackCount = (rate: number) => {
        const ratingFeedback = feedbackData.find((feedback) => feedback.rate == rate);
        return ratingFeedback ? ratingFeedback.count : 0;
    };

    if(show)
    return (
        <div className="user-review-filter" ref={filterRef}>
            <div className="user-review-filter__header">
                <p>Filters</p>
                <img alt="close-filter" src={clear} onClick={onCloseModal} />
            </div>
            <div className="user-review-filter__body">
                <div className="user-review-filter__rating">
                    <div className="user-review-filter__rating__header">
                        <p>Rating</p>
                        <img
                            className={`${
                                showRating
                                    ? 'user-review-filter__rating--collapse'
                                    : 'user-review-filter__rating--expand'
                            }`}
                            alt="collapse-rating"
                            src={showRating ? remove : add_button_white}
                            onClick={() => setShowRating((prev) => !prev)}
                        />
                    </div>
                    {showRating &&
                        STAR_RATING.map((rating) => (
                            <Fragment key={rating}>
                                <div className="user-review-filter__rating__option" key={rating}>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={!!filter.rating[rating]}
                                            id={rating.toString()}
                                            onChange={(e) =>
                                                handleRatingChange(rating, e.target.checked)
                                            }
                                        />
                                        <label htmlFor={rating.toString()}>
                                            <span></span> {`${rating} Stars`}
                                        </label>
                                    </div>
                                    <p>{getRatingFeedbackCount(rating)}</p>
                                </div>
                            </Fragment>
                        ))}
                </div>

                <MultiSelect
                    className="user-review-filter__comments"
                    multi={false}
                    searchable={false}
                    optionsPosition="bottom"
                    defaultOption={filter.comment ?? undefined}
                    label={'Comments'}
                    placeholder=""
                    checkBoxName={'country'}
                    onChange={(option) => handleNotProvidedChange(option as SelectOptions)}
                    data={CommentsOptions}
                />
                <div className="user-review-filter__date-range">
                    <DateRangePicker
                        label="Duration:"
                        value={filter.duration}
                        format="dd/MM/y"
                        maxDate={new Date()}
                        onChange={handleDateRangeChange}
                    />
                </div>

                <div className="user-review-filter__country">
                    <MultiSelect
                        multi={true}
                        searchable={true}
                        optionsPosition="top"
                        defaultOption={filter.country}
                        label={'Country'}
                        placeholder="Search..."
                        checkBoxName={'country'}
                        onChange={handleCountryChange}
                        data={transformCountries()}
                    />
                </div>
            </div>
            <footer>
                <button className="btn btn-primary" disabled={!UserReviewUtil.hasFilterChanged(filter, appliedFilters)} onClick={() => onApply(filter)}>
                    Apply
                </button>
                <button className="btn btn-secondary " onClick={handleResetFilter}>
                    Clear all
                </button>
            </footer>
        </div>
    );
    else return <></>;
};
export default UserReviewFilter;
