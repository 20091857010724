import { avatar, close_white } from "assets/media/images";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { USER_ROLE_CODE } from "common/enums/user-role-code.enum";
import { AddUserRequest } from "common/models/user/add-user.model";
import React, { FC, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userManagementActions } from "common/redux/store/user-management";
import "./EditUser.scss";
import TableLoader from "common/components/base/TableLoader/TableLoader";
import DropdownSearch from "common/components/custom/DropdownSearch/DropdownSearch";
import { DropdownSearchData } from "common/models/dropdown-search-data.model";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { ICountryDetails } from "modules/gpo-agent/models/country-component/country-response.model";

type EditUserProps = {
  userData:any;
  onUpdateUser: (userStatus:boolean) => void;
  onCloseModal: () => void;
};
const EditUser : FC<EditUserProps> = ({
  userData,
  onUpdateUser,
  onCloseModal
}: EditUserProps) => {
  const [selectedUserRoleCodes, setSelectedUserRoleCodes] = useState<Array<USER_ROLE_CODE>>([]);
  const [userStatus, setUserStatus] = useState("");
  const[editUserInProgress, setEditUserInProgress] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userManagementState = useAppSelector((state: RootState) => state.userManagement);
  const [valueChanged,setValueChanged] = useState<boolean>(false);
  const userRoles = userData?.roles.map(function(item) {
    return item['roleCode'];
  });
  const [roleSelected,setRoleSelected]=useState<boolean>(false);
  const [dropdownData,setDropdownData] = useState<Array<DropdownSearchData>>([]);
  const [selectedCountry,setSelectedCountry] = useState<DropdownSearchData>();
  const countryState = useAppSelector((state: RootState) => state.header?.countryResponse?.data);

  useEffect(()=>{
   dispatch(appHeaderActions?.getAllCountries());
  },[])

  useEffect(()=>{
    if(countryState?.code==200){
       const dropdownData:Array<DropdownSearchData>=[];
       countryState?.countries?.map((country:ICountryDetails)=>{
         const dropdownoption:DropdownSearchData={
           id: country?.guidCode,
           value: country?.name
         };
         dropdownData?.push(dropdownoption);
       });
       setDropdownData(dropdownData);
    }

  },[countryState])
  useEffect(()=>{
    if(userManagementState?.addUserResponse){
        setEditUserInProgress(false);
        const status = userManagementState?.addUserResponse[0].isSuccess;
        onUpdateUser(status);
        dispatch(userManagementActions.clearaddNewUserResponse());
    }
  },[userManagementState?.addUserResponse])

  useEffect(()=>{
    if(userData?.isActive){
      setUserStatus("active");
    }
    else{
      setUserStatus("inactive");
    }
    if(userData?.roles){
      const userRoles = userData?.roles.map(function(item) {
        return item['roleCode'];
      });
      if(userRoles?.length){      
        setRoleSelected(true);
      }
      setSelectedUserRoleCodes(userRoles);
    }
  },[userData])

  
  const handleCheckBoxChange = (checkBoxValue , checkBoxId ) =>{
    let selectedUserRoles = selectedUserRoleCodes;
    if(checkBoxValue){
      selectedUserRoles.push(checkBoxId);
    }
    else{
      const index = selectedUserRoles.indexOf(checkBoxId);
       if (index > -1) { 
        selectedUserRoles.splice(index, 1);
      }
    }
    setSelectedUserRoleCodes(selectedUserRoles);
  }
  const handleRadioChange = (event:any) =>{
    setUserStatus(event?.target?.value);
  }

  const handleUpdateUser = () =>{
    const userRequest : AddUserRequest ={
      users: [
        {
          email:userData?.userEmail,
          roleCodes:selectedUserRoleCodes,
          isActive:userStatus=="active"?true:false,
          countryGuid:selectedCountry?.id?selectedCountry?.id:''
        }
      ]
    }
    setEditUserInProgress(true);
    dispatch(userManagementActions.addNewUsers(userRequest));
  }
  const markFormDirty = (e) =>{
    setValueChanged(true);
    const element = document.querySelector(".role__options");
      if(element){
        const checkedCheckBoxes = element?.querySelectorAll("input[type=checkbox]:checked");
        if(checkedCheckBoxes?.length){
           setRoleSelected(true);
        }
        else{
          setRoleSelected(false);
        }
      }
    const countryElement = document.querySelector(".select-country");
    if(countryElement){
      const textBox:any = countryElement?.querySelector("input[type=text]");
      if(!textBox?.value){
        setSelectedCountry(undefined);
      }
    }
  }
  return (
    <div className="edit-user">
       {editUserInProgress &&(
        <TableLoader/>
      )}
      <div className="edit-user__container">
        <div className="wrapper">
          <header>
            <h3>Edit User</h3>
            <img src={close_white} alt="Close"  onClick={(e) => onCloseModal()}/>
          </header>
          <div className="user">
            <ProfileImage profileId={userData?.userGuid}></ProfileImage>
            <span>{userData?.userName}</span>
          </div>
          <form onChange={(e) => markFormDirty(e)}>
          <div className="role">
            <h5>Role(s)</h5>
            <div className="role__options">
              <input type="checkbox" id="Client" defaultChecked={userRoles && userRoles?.indexOf(USER_ROLE_CODE.CLIENT)!=-1}  onChange={(e) => handleCheckBoxChange(e.target.checked,'Client')}/>
              <label htmlFor="Client">
                <span></span> User
              </label>

              <input type="checkbox" id="Agent" defaultChecked={userRoles && userRoles?.indexOf(USER_ROLE_CODE.AGENT)!=-1} onChange={(e) => handleCheckBoxChange(e.target.checked,'Agent')}/>
              <label htmlFor="Agent">
                <span></span>Knowledge worker
              </label>

              <input type="checkbox" id="Admin" defaultChecked={userRoles && userRoles?.indexOf(USER_ROLE_CODE.ADMIN)!=-1} onChange={(e) => handleCheckBoxChange(e.target.checked,'Admin')}/>
              <label htmlFor="Admin">
                <span></span> Admin
              </label>
            </div>
          </div>
          <div className="active">
          <h5>Active</h5>
          <div className="radio-buttons" onChange={(e) => handleRadioChange(e)}>
              <div className="form-group">
                <input type="radio" id="active" name="isActive" value="active" defaultChecked={userData && userData?.isActive} />
                <label htmlFor="active">Active</label>
              </div>

              <div className="form-group">
                <input type="radio" id="inactive" name="isActive" value="inactive" defaultChecked={userData && !userData?.isActive}/>
                <label htmlFor="inactive">Inactive</label>
              </div>
            </div>
          </div>
          <div className="select-country">
            <DropdownSearch 
              defaultData={userData?.countryGuid}
              label={"Select Country"} 
              dropdownOptions={dropdownData}
              showSelectedOption={setSelectedCountry}
            />
          </div>
          </form>
          <footer>
            <button className="btn btn-primary" disabled={!(roleSelected && selectedCountry) } onClick={(e) => handleUpdateUser()}>Update</button>
            <button className="btn btn-secondary"  onClick={(e) => onCloseModal()}>Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  );
};
export default EditUser;
