import React from 'react';
import './ProgressBar.scss';

type ProgressBarProps = {
    label: string;
}

const ProgressBar = ({label}:ProgressBarProps) => {
    return (
        <div className="progress-bar-wrapper  progress-bar-label-default">
            <span aria-live="polite" className="progress-bar-label">{label}</span>
            <div className="progress-bar progress-bar-indeterminate">
                <div className="progress"></div>
            </div>
        </div>
    );
};

export default ProgressBar;