import React, { FC, ReactNode } from "react";
import {
  LineChart as Chart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";

type LineData = {
  name: string;
  [key: string]: number | string;
};

type LinesConfig = {
  name?: string;
  dataKey: string;
  stroke: string;
  strokeWidth?: number;
  tooltipLabel?: string;
};

type LineChartProps = {
  data: LineData[];
  xKey: string;
  linesConfig: LinesConfig[];
  width?: number;
  height?: number;
  labelFontSize?: number;
  isAvgTimeChart?: boolean;
};

const LineChart: FC<LineChartProps> = ({
  data,
  xKey,
  linesConfig,
  width,
  height,
  labelFontSize = 8,
  isAvgTimeChart = false,
}: LineChartProps) => {
  return (
    <Chart width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#747480" />
      <XAxis dataKey={xKey} fontSize={labelFontSize} stroke="#747480" />
      <YAxis
        tickFormatter={(value) => `${value}${isAvgTimeChart ? " sec" : "%"}`} // for chart with average time, append 'sec' for Y axis labels
        fontSize={labelFontSize}
        stroke="#747480"
      />
      <Tooltip
        content={({ label, payload }) => {
          return (
            <div className="custom-tooltip">
              <div className="tooltip-arrow"></div>
              <div className="tooltip-content">
                {linesConfig.map((line, index) => (
                  <p>{`${line.tooltipLabel}: ${Number(
                    payload?.[index]?.value
                  )?.toFixed(2)}${isAvgTimeChart ? "s" : "%"}`}</p> //appending 's' to indicate seconds
                ))}
              </div>
            </div>
          );
        }}
      />
      {linesConfig.map((line, index) => (
        <Line
          key={index}
          type="monotone"
          dataKey={line.dataKey}
          stroke={line.stroke}
          strokeWidth={line.strokeWidth || 3}
          dot={{ fill: "#000" }}
        />
      ))}
    </Chart>
  );
};

export default LineChart;
