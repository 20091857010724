import React, { FC } from "react";
import {
  active_session,
  active_session_white,
  chat_history,
  chat_history_black,
  logo,
  user_management_black,
  user_management_white,
  star_outline_black, 
  star_outline_white,
} from "assets/media/images";
import { dashboard_white, dashboard_black, questionDetailsBlack, questionDetailsWhite } from "assets/media/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import { TabType } from "common/enums/tab-types.enum";
import { userActions } from "common/redux/store/user";
import "./SideBar.scss";
import { document_nav_icon_active, document_nav_icon_inactive } from "assets/images";

interface SideBarPropes {
  currentTab: TabType;
  onTabChange: (tabType: TabType, chatHistoryAnnotateView?: boolean) => void;
}

const SideBar: FC<SideBarPropes> = ({ currentTab, onTabChange }) => {
  const dispatch = useDispatch();
  const profileImage = useSelector((state: RootState) => state.user.avatar);
  const profileName = useSelector((state: RootState) => state.user.name);
  const session = useSelector((state: RootState) => state.agents.session.data);

  const handleLogout = () => {
    dispatch(userActions?.logoutUser());
  };

  return (
    <div className="side-bar">
      <div className="side-bar__container">
        <header>
          <img src={logo} />
          <span>
            ChatBot by <strong>GPO</strong>
          </span>
        </header>
        <nav>
          {session && session?.userRoles?.includes("Agent") && (
            <div
              className={
                currentTab === TabType.ACTIVE_SESSION ? "menu active" : "menu"
              }
              onClick={() => {
                onTabChange(TabType.ACTIVE_SESSION);
              }}
            >
              <img
                src={
                  currentTab === TabType.ACTIVE_SESSION
                    ? active_session
                    : active_session_white
                }
              />
              Active Session
            </div>
          )}
          {session && session?.userRoles?.includes("Agent") && (
            <div
              className={
                currentTab === TabType.CHAT_HISTORY ? "menu active" : "menu"
              }
              onClick={() => {
                onTabChange(TabType.CHAT_HISTORY);
              }}
            >
              <img
                src={
                  currentTab === TabType.CHAT_HISTORY
                    ? chat_history_black
                    : chat_history
                }
              />
              Chat History
            </div>
          )}
          {session && session?.userRoles?.includes("Admin") && (
            <div
              className={
                currentTab === TabType.METRIC_DASHBOARD ? "menu active" : "menu"
              }
              onClick={() => {
                onTabChange(TabType.METRIC_DASHBOARD);
              }}
            >
              <img
                src={
                  currentTab === TabType.METRIC_DASHBOARD
                    ? dashboard_black
                    : dashboard_white
                }
              />
              Metric Dashboard
            </div>
          )}
          {session && session?.userRoles?.includes("Admin") && (
            <div
              className={
                currentTab === TabType.USER_MANAGEMENT ? "menu active" : "menu"
              }
              onClick={() => {
                onTabChange(TabType.USER_MANAGEMENT);
              }}
            >
              <img
                src={
                  currentTab === TabType.USER_MANAGEMENT
                    ? user_management_black
                    : user_management_white
                }
              />
              User management
            </div>
          )}

          {session && session?.userRoles?.includes('Admin') &&(
           <div className={currentTab === TabType.QUESTION_DETAILS?'menu active':'menu'} onClick={() =>{onTabChange(TabType.QUESTION_DETAILS)}}>
           <img src={currentTab === TabType.QUESTION_DETAILS? questionDetailsBlack: questionDetailsWhite} />
             Question Details
           </div>            
          )}
          
          {session && session?.userRoles?.includes('Admin') &&(
           <div className={currentTab === TabType.USER_REVIEW?'menu active':'menu'} onClick={() =>{onTabChange(TabType.USER_REVIEW)}}>
           <img src={currentTab === TabType.USER_REVIEW? star_outline_black: star_outline_white} />
             User Reviews
           </div>            
          )}
          {session && session?.userRoles?.includes('Admin') &&(
           <div className={currentTab === TabType.MANAGE_DOCUMENTS?'menu active':'menu'} onClick={() =>{onTabChange(TabType.MANAGE_DOCUMENTS)}}>
           <img src={currentTab === TabType.MANAGE_DOCUMENTS? document_nav_icon_active: document_nav_icon_inactive} />
             Manage Documents
           </div>            
          )}
        </nav>
        <div className="user-details">
          <img src={profileImage} />
          <div className="user-details__action">
            <strong title={profileName}>{profileName}</strong>
            <span onClick={handleLogout}>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
