import { ApiResult } from "common/models";
import { HttpClient } from "common/services/http-client.service";
import { IACSContext } from "modules/gpo-agent/models/agents/acs-context.model";
import { IChatRequest } from "modules/gpo-agent/models/agents/chat-request.model";
import { ISession } from "modules/gpo-agent/models/agents/session.model";
import { WebPubSubDetails } from "modules/gpo-agent/models/agents/web-pub-sub-details.model";
import agentsClientApiConfig from "./agents.config";
import { OpinionDataRequest, UserEngFieldResponse } from "modules/gpo-agent/models/dashboard";
import { ExportTableQuestionDetails } from "modules/gpo-agent/models/export-table-question-details/export-table-question-details.model";
import { ManagedDocumentsRequest } from "modules/gpo-agent/models/filter-component/filter-values.model";

export class AgentsService extends HttpClient {
    public static async getSession(): Promise<ApiResult<ISession> | undefined> {
        return await this.invokeApi<ISession>(agentsClientApiConfig.session());
    }

    public static async getChatRequests(): Promise<ApiResult<IChatRequest[]> | undefined> {
        return await this.invokeApi<IChatRequest[]>(agentsClientApiConfig.chatRequests());
    }

    public static async acceptConversation(conversationThreadId  : string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsClientApiConfig.acceptConversation(conversationThreadId));
    }

    public static async getACSContext(): Promise<ApiResult<IACSContext> | undefined> {
        return await this.invokeApi<IACSContext>(agentsClientApiConfig.getACSContext());
    }

    public static async leaveConversation(conversationThreadId: string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsClientApiConfig.leaveConversation(conversationThreadId));
    }

    public static async webPubSubNegotiate(): Promise<ApiResult<WebPubSubDetails> | undefined> {
        return await this.invokeApi<WebPubSubDetails>(agentsClientApiConfig.webPubSubNegotiate());
    } 
    public static async getPDFDocument(documentGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(agentsClientApiConfig.getPDFDocument(documentGuid));
    }
    public static async getTotalLikes(
        request: OpinionDataRequest
      ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
        return await this.invokeApi(agentsClientApiConfig.getTotalLikes(request));
      }
    
    public  static async getTotalDislikes(
        request: OpinionDataRequest
      ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
        return await this.invokeApi(agentsClientApiConfig.getTotalDislikes(request));
      }
    public  static async getTotalNoActions(
        request: OpinionDataRequest
      ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
        return await this.invokeApi(agentsClientApiConfig.getTotalNoActions(request));
    }
    public  static async getTotalArchivedCount(
      request: ManagedDocumentsRequest
    ): Promise<ApiResult<any>>{
      return await this.invokeApi(agentsClientApiConfig.getTotalArchived(request));
   }
    public  static async exportQuestionDetailsTable(
        request: ExportTableQuestionDetails
      ): Promise<ApiResult<any>> {
        return await this.invokeApi(agentsClientApiConfig.exportQuestionDetailsTable(request));
    }
}
