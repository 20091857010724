import { ApiStatus } from "common/enums";
import { ApiResponse } from "common/models";
import { UploadDocumentState } from "modules/gpo-agent/models";
import { IChatHistory, IChatHistoryList } from "modules/gpo-agent/models/chat-history/chat-history-list.model";
import { IChatHistoryMessages } from "modules/gpo-agent/models/chat-history/chat-history-messages.model";
import { IReferenceDocumentsResponse } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";

interface IAgentsChatHistoryState{
    chatHistoryMessageHandoffGuid:string;
    chatHistoryList: IChatHistoryList|undefined,
    chatHistoryMessages:IChatHistoryMessages|undefined,
    currentSelectedChatGuid : string,
    selectedChatHistory:IChatHistory|undefined;
    isAnnotateView:boolean;
    documentType:string;
    referenceDocuments: IReferenceDocumentsResponse|undefined;
    allDocuments: any;
    completeAnnotationResponse:any;
    searchMessagesText:string;
    searchText:string;
    annotateDetailsResponse:any;
    searchTextChanged:boolean;
    uploadDocument: UploadDocumentState;
}

const initalAgentsChatHistoryState: IAgentsChatHistoryState = {
    chatHistoryMessageHandoffGuid:"",
    chatHistoryList:undefined,
    chatHistoryMessages:undefined,
    currentSelectedChatGuid : "",
    selectedChatHistory:undefined,
    isAnnotateView:false,
    documentType:"",
    referenceDocuments:undefined,
    allDocuments:undefined,
    completeAnnotationResponse:undefined,
    searchMessagesText:"",
    searchText:"" ,
    annotateDetailsResponse:undefined,
    searchTextChanged:false,
    uploadDocument: {
        status: ApiStatus.NONE
    }
};

export { initalAgentsChatHistoryState };
