import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialDashboardState } from "./dashboard.state";
import { ApiStatus } from "common/enums";
import {
  AvgTimeToResolveDataResponse,
  DashboardDataRequest,
  SentimentDistDataResponse,
  UnresolvedRateResponse,
  OpinionDataRequest,
  ResolvedDataResponse,
  Top5SourceDocsDataResponse,
  TrafficDataForMapResponse,
  UserEngFieldResponse,
  UserRatingsResponse,
} from "modules/gpo-agent/models/dashboard";
import { UserReviewFilterBody } from "modules/gpo-agent/models";
import { DateUtil } from "common/utils";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    getResolvedRateData: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.resolvedData.status = ApiStatus.LOADING;
    },
    getResolvedRateDataSuccess: (
      state,
      { payload }: PayloadAction<ResolvedDataResponse>
    ) => {
      state.resolvedData.data = payload;
      state.resolvedData.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getResolvedRateDataError: (state) => {
      state.resolvedData.data = initialDashboardState.resolvedData.data;
      state.resolvedData.status = ApiStatus.ERROR;
    },
    resetResolvedRateData: (state) => {
      state.resolvedData = initialDashboardState.resolvedData;
      state.resolvedData.status = ApiStatus.NONE;
    },

    getUnresolvedRateData: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.unresolvedRateData.status = ApiStatus.LOADING;
    },
    getUnresolvedRateSuccess: (
      state,
      { payload }: PayloadAction<UnresolvedRateResponse>
    ) => {
      state.unresolvedRateData.data = payload;
      state.unresolvedRateData.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getUnresolvedRateError: (state) => {
      state.unresolvedRateData.data =
        initialDashboardState.unresolvedRateData.data;
      state.unresolvedRateData.status = ApiStatus.ERROR;
    },
    resetUnresolvedRateData: (state) => {
      state.unresolvedRateData = initialDashboardState.unresolvedRateData;
      state.unresolvedRateData.status = ApiStatus.NONE;
    },

    getAvgTimeToResolveData: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.avgTimeToResolveData.status = ApiStatus.LOADING;
    },
    getAvgTimeToResolveSuccess: (
      state,
      { payload }: PayloadAction<AvgTimeToResolveDataResponse>
    ) => {
      state.avgTimeToResolveData.data = payload;
      state.avgTimeToResolveData.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getAvgTimeToResolveError: (state) => {
      state.avgTimeToResolveData.data =
        initialDashboardState.avgTimeToResolveData.data;
      state.avgTimeToResolveData.status = ApiStatus.ERROR;
    },
    resetAvgTimeToResolve: (state) => {
      state.avgTimeToResolveData = initialDashboardState.avgTimeToResolveData;
      state.avgTimeToResolveData.status = ApiStatus.NONE;
    },

    getSentimentDistData: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.sentimentDistData.status = ApiStatus.LOADING;
    },
    getSentimentDistDataSuccess: (
      state,
      { payload }: PayloadAction<SentimentDistDataResponse>
    ) => {
      state.sentimentDistData.data = payload;
      state.sentimentDistData.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getSentimentDistDataError: (state) => {
      state.sentimentDistData.data =
        initialDashboardState.sentimentDistData.data;
      state.sentimentDistData.status = ApiStatus.ERROR;
    },
    resetSentimentDistData: (state) => {
      state.sentimentDistData = initialDashboardState.sentimentDistData;
      state.sentimentDistData.status = ApiStatus.NONE;
    },

    getUserRatingsData: (
      state,
      { payload }: PayloadAction<DashboardDataRequest | UserReviewFilterBody>
    ) => {
      state.userRatingsData.status = ApiStatus.LOADING;
    },
    getUserRatingsSuccess: (
      state,
      { payload }: PayloadAction<UserRatingsResponse>
    ) => {
      state.userRatingsData.data = payload;
      state.userRatingsData.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getUserRatingsError: (state) => {
      state.userRatingsData.data = initialDashboardState.userRatingsData.data;
      state.userRatingsData.status = ApiStatus.ERROR;
    },
    resetUserRatingsData: (state) => {
      state.userRatingsData = initialDashboardState.userRatingsData;
      state.userRatingsData.status = ApiStatus.NONE;
    },

    getTotalTraffic: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.userEngagementData.totalTraffic.status = ApiStatus.LOADING;
    },
    getTotalTrafficSuccess: (
      state,
      { payload }: PayloadAction<UserEngFieldResponse>
    ) => {
      state.userEngagementData.totalTraffic.data = payload;
      state.userEngagementData.totalTraffic.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTotalTrafficError: (state) => {
      state.userEngagementData.totalTraffic.data =
        initialDashboardState.userEngagementData.totalTraffic.data;
      state.userEngagementData.totalTraffic.status = ApiStatus.ERROR;
    },
    resetTotalTraffic: (state) => {
      state.userEngagementData.totalTraffic =
        initialDashboardState.userEngagementData.totalTraffic;
    },

    getTotalLikes: (state, { payload }: PayloadAction<OpinionDataRequest>) => {
      state.userEngagementData.totalLikes.status = ApiStatus.LOADING;
    },
    getTotalLikesSuccess: (
      state,
      { payload }: PayloadAction<UserEngFieldResponse>
    ) => {
      state.userEngagementData.totalLikes.data = payload;
      state.userEngagementData.totalLikes.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTotalLikesError: (state) => {
      state.userEngagementData.totalLikes.data =
        initialDashboardState.userEngagementData.totalLikes.data;
      state.userEngagementData.totalLikes.status = ApiStatus.ERROR;
    },
    resetTotalLikes: (state) => {
      state.userEngagementData.totalLikes =
        initialDashboardState.userEngagementData.totalLikes;
    },

    getTotalDislikes: (
      state,
      { payload }: PayloadAction<OpinionDataRequest>
    ) => {
      state.userEngagementData.totalDislikes.status = ApiStatus.LOADING;
    },
    getTotalDislikesSuccess: (
      state,
      { payload }: PayloadAction<UserEngFieldResponse>
    ) => {
      state.userEngagementData.totalDislikes.data = payload;
      state.userEngagementData.totalDislikes.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTotalDislikesError: (state) => {
      state.userEngagementData.totalDislikes.data =
        initialDashboardState.userEngagementData.totalDislikes.data;
      state.userEngagementData.totalDislikes.status = ApiStatus.ERROR;
    },
    resetTotalDislikes: (state) => {
      state.userEngagementData.totalDislikes =
        initialDashboardState.userEngagementData.totalDislikes;
    },

    getTotalConversations: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.userEngagementData.totalConversations.status = ApiStatus.LOADING;
    },
    getTotalConversationsSuccess: (
      state,
      { payload }: PayloadAction<UserEngFieldResponse>
    ) => {
      state.userEngagementData.totalConversations.data = payload;
      state.userEngagementData.totalConversations.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTotalConversationsError: (state) => {
      state.userEngagementData.totalConversations.data =
        initialDashboardState.userEngagementData.totalConversations.data;
      state.userEngagementData.totalConversations.status = ApiStatus.ERROR;
    },
    resetTotalConversations: (state) => {
      state.userEngagementData.totalConversations =
        initialDashboardState.userEngagementData.totalConversations;
    },

    getTop5SourceDocsBegin: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.userEngagementData.top5SourceDocs.status = ApiStatus.LOADING;
    },
    getTop5SourceDocsSuccess: (
      state,
      { payload }: PayloadAction<Top5SourceDocsDataResponse>
    ) => {
      state.userEngagementData.top5SourceDocs.data = payload;
      state.userEngagementData.top5SourceDocs.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTop5SourceDocsError: (state) => {
      state.userEngagementData.top5SourceDocs.data =
        initialDashboardState.userEngagementData.top5SourceDocs.data;
      state.userEngagementData.top5SourceDocs.status = ApiStatus.ERROR;
    },
    resetTop5SourceDocs: (state) => {
      state.userEngagementData.top5SourceDocs =
        initialDashboardState.userEngagementData.top5SourceDocs;
    },

    getTotalQuestions: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.userEngagementData.totalQuestions.status = ApiStatus.LOADING;
    },
    getTotalQuestionsSuccess: (
      state,
      { payload }: PayloadAction<UserEngFieldResponse>
    ) => {
      state.userEngagementData.totalQuestions.data = payload;
      state.userEngagementData.totalQuestions.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTotalQuestionsError: (state) => {
      state.userEngagementData.totalQuestions.data =
        initialDashboardState.userEngagementData.totalQuestions.data;
      state.userEngagementData.totalQuestions.status = ApiStatus.ERROR;
    },
    resetTotalQuestions: (state) => {
      state.userEngagementData.totalQuestions =
        initialDashboardState.userEngagementData.totalQuestions;
    },

    getEngagedUsers: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.userEngagementData.engagedUsers.status = ApiStatus.LOADING;
    },
    getEngagedUsersSuccess: (
      state,
      { payload }: PayloadAction<UserEngFieldResponse>
    ) => {
      state.userEngagementData.engagedUsers.data = payload;
      state.userEngagementData.engagedUsers.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getEngagedUsersError: (state) => {
      state.userEngagementData.engagedUsers.data =
        initialDashboardState.userEngagementData.engagedUsers.data;
      state.userEngagementData.engagedUsers.status = ApiStatus.ERROR;
    },
    resetEngagedUsers: (state) => {
      state.userEngagementData.engagedUsers =
        initialDashboardState.userEngagementData.engagedUsers;
    },

    getTrafficDataForMapsBegin: (
      state,
      { payload }: PayloadAction<DashboardDataRequest>
    ) => {
      state.trafficDataForMap.status = ApiStatus.LOADING;
    },
    getTrafficDataForMapsSuccess: (
      state,
      { payload }: PayloadAction<TrafficDataForMapResponse>
    ) => {
      state.trafficDataForMap.data = payload;
      state.trafficDataForMap.status = ApiStatus.SUCCESS;
      state.lastUpdatedTime = DateUtil.getTimeInDDMMMYYYY();
    },
    getTrafficDataForMapsError: (state) => {
      state.trafficDataForMap.data =
        initialDashboardState.trafficDataForMap.data;
      state.trafficDataForMap.status = ApiStatus.ERROR;
    },
    resetTrafficDataForMaps: (state) => {
      state.trafficDataForMap = initialDashboardState.trafficDataForMap;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
