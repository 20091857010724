import React, { FC, useEffect, useRef, useState } from "react";
import { UserService } from "common/redux/store/user/user.service";
import { avatar } from "assets/media/images";

import "./DropdownSearch.scss";
import { DropdownSearchData } from "common/models/dropdown-search-data.model";
import useDebounce from "common/hooks/useDebounce";

export const DEBOUNCE_TIME_OUT = 500;
interface DropdownSearchPropes {
    defaultData?:string
    label:string;
    dropdownOptions:Array<DropdownSearchData>
    showSelectedOption: (option:DropdownSearchData|undefined) => void;
}

const DropdownSearch: FC<DropdownSearchPropes> = ({
    defaultData,
    label,
    dropdownOptions,
    showSelectedOption 
}) => {
    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const textBoxRef:any = useRef<HTMLInputElement>(null);
    const ref:any = useRef<any>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm:any = useDebounce(searchTerm, DEBOUNCE_TIME_OUT);
    const [options, setOptions] = useState<Array<DropdownSearchData>>([]);
    const [selectedData, setSelectedData] = useState<DropdownSearchData>();
    const [defaultSelectedData,setDefaultSelectedData] = useState<DropdownSearchData>();
    
    useEffect(()=>{
      if(options){
        console.log("options",options);
      }
    },[options])
    useEffect(()=>{
      if(defaultData && dropdownOptions?.length){
        const countryDefault= dropdownOptions?.filter((option:DropdownSearchData)=>option.id==defaultData);
        if(countryDefault?.length && countryDefault[0]){
           setDefaultSelectedData(countryDefault[0]);
           setSelectedData(countryDefault[0]);
           if(textBoxRef?.current){
            textBoxRef.current.value = countryDefault[0]?.value;
            const filteredDropdownData:Array<DropdownSearchData> = dropdownOptions?.filter((data:DropdownSearchData)=>data?.value?.toLowerCase().includes(countryDefault[0]?.value?.toLowerCase()));
            setOptions(filteredDropdownData);
            setIsDropdownOpen(false);
            showSelectedOption(countryDefault[0])
          }
        }
      }
    },[defaultData,dropdownOptions])
    useEffect(() => {
        const checkIfClickedOutside = e => {
          if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
            setOptions(dropdownOptions);
            setIsDropdownOpen(false);
            if(!selectedData){
              if(textBoxRef?.current){
                textBoxRef.current.value = "";
                showSelectedOption(selectedData);
              }
            }
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isDropdownOpen])

    useEffect(()=>{
      if(dropdownOptions?.length && !defaultData){
        setOptions(dropdownOptions)
      }
    },[dropdownOptions])
    const openDropdown = (isDropdownOpen) => {
        setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
    };
    const handleSearchInputChange = (searchValue) => {
        if (searchValue) {
          setSearchTerm(searchValue);
        } else {
          setSearchTerm('');
        }
      }
      const selectDataToAdd = (selectedData : DropdownSearchData) =>{
        setSelectedData(selectedData);
        if(textBoxRef?.current){
          textBoxRef.current.value = selectedData?.value;
          setOptions(dropdownOptions);
          setIsDropdownOpen(false);
          showSelectedOption(selectedData)
        }
      }  
        useEffect(() => {
            setOptions(dropdownOptions);
            if(debouncedSearchTerm){
              filterOptions(debouncedSearchTerm);
            }
            else{
              setSelectedData(undefined);
              showSelectedOption(undefined);
            }
        }, [debouncedSearchTerm]);
    
    const filterOptions = (searchData:string) =>{
       const filteredDropdownData:Array<DropdownSearchData> = dropdownOptions?.filter((data:DropdownSearchData)=>data?.value?.toLowerCase().includes(searchData?.toLowerCase()));
       setOptions(filteredDropdownData);
    }
    return (
        <div className="select-data" ref={ref}>
            <div
              className={
                !isDropdownOpen ? "material-input" : "material-input active"
              }
              onClick={openDropdown}
            >
              <input type="text" ref={textBoxRef} required  onChange={(e) => handleSearchInputChange(e.target.value)}   />
              <label>{label}</label>
            </div>
            {isDropdownOpen && (
              <div className="options">
                {!options?.length &&(
                <span>No data found</span>
                )}
                {options && options?.map((option:DropdownSearchData, i) => 
                 <span key={option.id} onClick={(e) => selectDataToAdd(option)}>
                 {option.value}
                 </span>
                )}
              </div>
            )}
          </div>
    );
};

export default DropdownSearch;