export enum ReviewFilterTypes {
    Rating = "rating",
    Country =  "country",
    Duration = "duration",
    Comment = "comment"
}

export enum CommentFilterOption {
    All = "all",
    Provided =  "provided",
    NotProvided = "notProvided"
}