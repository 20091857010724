import { DateValue } from 'common/models/date-range.model';
import { UserReviewFilterBody, UserReviewFilters } from '../models';
import { CommentFilterOption, ReviewFilterTypes } from '../enums/user-review-filter.enum';
import moment from 'moment';
import { SelectOptions } from 'common/models';
import { TABLE_SORT_ORDER } from 'common/enums/table-sort-order.enum';
import { GridSortModel } from 'common/models/grid-sort-model';

const UserFeedbackInitialRequest: UserReviewFilterBody = {
    filter: {
        countryGuids: [],
        startDate: null,
        endDate: null
    },
    ratingFilters: {
        isCommentNotProvided: null,
        ratingValues: []
    },
    searchText: ''
};

const InitialFilter: UserReviewFilters = {
    country: [],
    duration: [moment(new Date()).subtract(30, "days")?.startOf('day').toDate(), moment(new Date())?.endOf('day')?.toDate()],
    rating: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    },
    comment: { id: CommentFilterOption.All, name: 'All'}
};

const InitialSortModel: GridSortModel = {
    colId: 'createdDate',
    sort: TABLE_SORT_ORDER.DESCENDING
};

const EmptyFilter: UserReviewFilters = {
    country: [],
    duration: [null, null],
    rating: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    },
    comment: null
};

const getISODate = (date: DateValue) => {
    if (date) {
        // const utcDate = moment(date).utcOffset(0);
        // utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        // return utcDate.toISOString();
        return date?.toISOString;
    }
};

const showSelectedFilter = (filter: UserReviewFilters) => {
    let show = false;
    if (filter) {
        Object.keys(filter).map((key) => {
            const value = filter[key];
            if (
                (key == ReviewFilterTypes.Country && value.length > 0) ||
                (key == ReviewFilterTypes.Duration && value.every((item) => item != null)) ||
                (key == ReviewFilterTypes.Rating &&
                    Object.keys(value).filter((item) => value[item] == true).length > 0) ||
                (key == ReviewFilterTypes.Comment && value)
            ) {
                show = true;
            }
        });
    }
    return show;
};

const transformCommentFilter = (option: SelectOptions | null) => {
    if(option){
        switch (option.id) {
            case CommentFilterOption.All:
                return null;
            case CommentFilterOption.Provided:
                return false;
            case CommentFilterOption.NotProvided:
                return true;
            default:
                return null;
        }
    }   
    return null;
};

const getFilterRequestBody = (filter?: UserReviewFilters): UserReviewFilterBody => {
    if (filter && showSelectedFilter(filter)) {
        // const startDate = getISODate(filter[ReviewFilterTypes.Duration][0]);
        // const endDate = getISODate(filter[ReviewFilterTypes.Duration][1]);

        const startDate = filter[ReviewFilterTypes.Duration][0]?.toISOString();
        const endDate = filter[ReviewFilterTypes.Duration][1]?.toISOString();

        const body: UserReviewFilterBody = {
            filter: {
                countryGuids: filter[ReviewFilterTypes.Country].map((item) => item.id),
                startDate: startDate ?? null,
                endDate: endDate ?? null
            },
            ratingFilters: {
                isCommentNotProvided: transformCommentFilter(filter[ReviewFilterTypes.Comment]),
                ratingValues: Object.keys(filter[ReviewFilterTypes.Rating])
                    .filter((item) => filter[ReviewFilterTypes.Rating][item] == true)
                    .map((item) => Number(item))
            },
            searchText: ''
        };
        return body;
    } else return UserFeedbackInitialRequest;
};

const hasFilterChanged = (current: UserReviewFilters, previous: UserReviewFilters | undefined) =>{
    if(current && previous){
        if(current.comment?.id != previous.comment?.id){
            return true;
        }else if(current.country.length != previous.country.length){
            return true;
        } else if(current.duration[0]?.toISOString() != previous.duration[0]?.toISOString()){
            return true;
        } else if(current.duration[1]?.toISOString() != previous.duration[1]?.toISOString()){
            return true;
        } else if(JSON.stringify(current.rating) != JSON.stringify(previous.rating)){
            return true;
        }
    } else return true;

return false;
}

export default {
    showSelectedFilter,
    getFilterRequestBody,
    UserFeedbackInitialRequest,
    InitialFilter,
    EmptyFilter,
    InitialSortModel,
    hasFilterChanged
};
