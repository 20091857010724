import { Fragment, useEffect, useRef, useState } from "react";
import "./QuestionDetailsFilter.scss";
import { clear, down, pdf, remove } from "assets/media";
import { SelectOptions } from "common/models";
import moment from "moment";
import { DateRangePicker, MultiSelect } from "common/components";
import { UserReviewFilters } from "modules/gpo-agent/models";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { ReviewFilterTypes } from "modules/gpo-agent/enums/user-review-filter.enum";
import { agentChatHistoryActions } from "modules/gpo-agent/redux/agent-chat-history";
import { DocumentTypes } from "modules/gpo-agent/enums/document-types.enum";
import { IDocumentDetails, IReferenceDocumentsResponse,IDocumentDetailsDoc } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import DropdownSearch from "common/components/custom/DropdownSearch/DropdownSearch";
import { DropdownSearchData } from "common/models/dropdown-search-data.model";
import { QuestionDetailFilter, QuestionDetailsFilters } from "modules/gpo-agent/models/filter-component/filter-values.model";
import { DashboardDataRequest } from "modules/gpo-agent/models/dashboard";
import { QuestionDetailFilterTypes } from "modules/gpo-agent/enums/question-details-filter.enum";
import { DateValue } from "common/models/date-range.model";
import { dashboardActions } from "modules/gpo-agent/redux/dashboard";
import { agentsActions } from "modules/gpo-agent/redux/agents";

type QuestionDetailsFilterProps = {
  appliedFilters?: QuestionDetailsFilters;
  autoRefreshCount: number;
  onCloseModal: () => void;
  onApply: (filter: QuestionDetailsFilters) => void;
  onReset: () => void;
};
const QuestionDetailsFilter = ({
  appliedFilters,
  autoRefreshCount,
  onCloseModal,
  onApply,
  onReset
}: QuestionDetailsFilterProps) => {
  const STAR_RATING = ["Like","Dislike"];
  const filterRef: any = useRef(null);
  const countryResponse = useAppSelector(
    (state) => state.header.countryResponse
  );
  const initialFilter: QuestionDetailsFilters = {
    country: [],
    duration: [null, null],
    like: false,
    dislike:false,
    noaction:false,
    documentGuids: []
  };
  const [filter, setFilter] = useState(initialFilter);

  const chatHistoryState = useAppSelector((state: RootState) => state.agentChatHistory);
  const [pages, setPages] = useState("");
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState("");
  const dispatch = useAppDispatch();
  const [dropdownData,setDropdownData] = useState<Array<DropdownSearchData>>([]);
  const [selectedDocument,setSelectedDocument] = useState<DropdownSearchData>();
  const [dropdownValue, setDropdownValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState<IDocumentDetailsDoc[]>();
  const [likesData,setLikesData] = useState<number>(0);
  const [disLikesData,setDislikesData] = useState<number>(0);
  const [noActionsData,setNoActions] = useState<number>(0);
  const agentsData = useAppSelector((state: RootState) => state.agents.userEngagementData);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(()=>{
    dispatch(agentChatHistoryActions?.getAllDocuments());
  },[])

  useEffect(()=>{
    const referenceDocumentsResponse:any = chatHistoryState?.allDocuments;
    if(referenceDocumentsResponse?.code==200){
      const referenceDocuments:IDocumentDetailsDoc[] = referenceDocumentsResponse?.documents;
      if(referenceDocuments){
        setDropdownOptions(referenceDocuments);
        const dropdownData:Array<DropdownSearchData>=[];
        referenceDocuments?.map((document:IDocumentDetailsDoc)=>{
          const dropdownoption:DropdownSearchData={
            id: document?.guidCode,
            value: document?.documentName
          };
          dropdownData?.push(dropdownoption);
        });
        setDropdownData(dropdownData);
      }
    }
  },[chatHistoryState?.allDocuments])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (appliedFilters) {
      setFilter(appliedFilters);
      const startDate = getISODate(
        appliedFilters[QuestionDetailFilterTypes.Duration][0]
      ) ? getISODate(
        appliedFilters[QuestionDetailFilterTypes.Duration][0]
      ) : null;
    const endDate = getISODate(appliedFilters[QuestionDetailFilterTypes.Duration][1]) ?
      getISODate(appliedFilters[QuestionDetailFilterTypes.Duration][1]) : null;

      const request: DashboardDataRequest = {
        filter: {
          countryGuids: appliedFilters[QuestionDetailFilterTypes.Country].map(
            (item) => item.id
          ),
          startDate: startDate?startDate:null,
          endDate: endDate ?endDate :null
        },
        questionDetailsFilters:{
            documentGuids:appliedFilters[QuestionDetailFilterTypes.Documents].map(
                (item) => item.id
             ),
             messageFeedbackFilter:{
              includeLikes:appliedFilters[QuestionDetailFilterTypes.Like],
              includeDislikes:appliedFilters[QuestionDetailFilterTypes.Dislike],
              includeNoAction:appliedFilters[QuestionDetailFilterTypes.NoAction]
            }
        },
        //searchText:null
        searchText:null
  }
  

    dispatch(agentsActions.getTotalLikes({ ...request, thumbValue: true }));
    dispatch(
      agentsActions.getTotalDislikes({ ...request, thumbValue: false })
      );
    dispatch(
      agentsActions.getTotalNoActions({ ...request, thumbValue: null })
    );
    }
  }, [appliedFilters, autoRefreshCount]);

  useEffect(()=>{
    if(agentsData?.totalLikes?.data){
        setLikesData(agentsData?.totalLikes?.data?.total)
    }
  },[agentsData.totalLikes?.data])
  useEffect(()=>{
    if(agentsData.totalDislikes.data){
        setDislikesData(agentsData.totalDislikes.data?.total)
    }
  },[agentsData.totalDislikes?.data])

  useEffect(()=>{
    if(agentsData?.totalNoActions?.data){
        setNoActions(agentsData?.totalNoActions?.data?.total);
    }
  },[agentsData.totalNoActions?.data])

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      onCloseModal();
    }
  };
  
  const getISODate = (date: DateValue) => {
    if (date) {
      // const utcDate = moment(date).utcOffset(0);
      // utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      // return utcDate.toISOString();
      return date?.toISOString();
    }
  };
  
  const handleResetFilter = () => {
    setFilter(initialFilter);
    onReset();
  };

  const handleDateRangeChange = (date) => {
    if (date && date[0] && date[1]) {
      setFilter((prev) => ({
        ...prev,
        duration: date
      }));
      updateLikeDislike("date",date);
    } else {
      setFilter((prev) => ({
        ...prev,
        duration: [null, null]
      }));
      updateLikeDislike("date",[null, null]);
      
    }
  };

  const handleRatingChange = (rating: QuestionDetailFilterTypes, checked: boolean) => {
    if(rating==QuestionDetailFilterTypes.Like){
      setFilter((prev) => ({
        ...prev,
        like: checked
      }));
    }
    if(rating==QuestionDetailFilterTypes.Dislike){
      setFilter((prev) => ({
        ...prev,
        dislike: checked
      }));
    }
    if(rating==QuestionDetailFilterTypes.NoAction){
      setFilter((prev) => ({
        ...prev,
        noaction: checked
      }));
    }
  };

//   const handleNotProvidedChange = (checked: boolean) => {
//     setFilter((prev) => ({
//       ...prev,
//       [ReviewFilterTypes.NotProvided]: checked
//     }));
//   };

  const transformCountries = () => {
    if (countryResponse && countryResponse.data.countries.length > 0) {
      return countryResponse.data.countries.map((country) => ({
        id: country.guidCode,
        name: country.name
      }));
    } else {
      return [];
    }
  };

  const transformDocuments = () => {
    if (chatHistoryState?.allDocuments && chatHistoryState?.allDocuments?.documents) {
      return chatHistoryState?.allDocuments?.documents.map((document) => ({
        id: document?.guidCode,
        name: document?.documentName
      }));
    } else {
      return [];
    }
  };

  const handleCountryChange = (option: SelectOptions | SelectOptions[]) => {
    const selected = Array.isArray(option) ? option : [option];
    setFilter((prev) => ({
      ...prev,
      country: selected
    }));
    updateLikeDislike("country",selected);
  };
  const handleDocumentChange = (option: SelectOptions | SelectOptions[]) => {
    const selected = Array.isArray(option) ? option : [option];
    setFilter((prev) => ({
      ...prev,
      documentGuids: selected
    }));
    updateLikeDislike("documentGuids",selected);
  };

  const updateLikeDislike  = (name,value) => {
    const filterData= {
      ...filter,
      [name]:value
    }

    const startDate = getISODate(
      filterData[QuestionDetailFilterTypes.Duration][0]
    );
   const endDate = getISODate(filterData[QuestionDetailFilterTypes.Duration][1]);
    const request: DashboardDataRequest = {
      filter: {
        countryGuids: filterData[QuestionDetailFilterTypes.Country].map(
          (item) => item.id
        ),
        startDate: startDate?startDate:"",
        endDate: endDate ?endDate :""
      },
      questionDetailsFilters:{
          documentGuids:filterData[QuestionDetailFilterTypes.Documents].map(
              (item) => item.id
           ),
           messageFeedbackFilter:{
            includeLikes:filterData[QuestionDetailFilterTypes.Like],
            includeDislikes:filterData[QuestionDetailFilterTypes.Dislike],
            includeNoAction:filterData[QuestionDetailFilterTypes.NoAction]
          }
      },
      //searchText:null
      searchText:null
}

  dispatch(agentsActions.getTotalLikes({ ...request, thumbValue: true }));
  dispatch(
    agentsActions.getTotalDislikes({ ...request, thumbValue: false })
    );
    dispatch(
      agentsActions.getTotalNoActions({ ...request, thumbValue: null })
      );
  }
  const handleChange = (value,key) => {
    setDropdownValue(value);
    setSelectedDocument(key)
    setOpen(false);
  };

  const checkFilterEmpty = () : boolean =>{
    const isNullish = Object.values(filter)?.every((value:any) => {
      if (value === null || value?.length==0 || value==false || (Array?.isArray(value) && value?.every((val=>val==null)))) {
        return true;
      }
    
      return false;
    });
    return isNullish;
  }

  return (
    <div className="question-details-filter" ref={filterRef}>
      <div className="question-details-filter__header">
        <p>Filters</p>
        <img src={clear} onClick={onCloseModal} />
      </div>
      <div className="question-details-filter__body">
        <div className=" body-item question-details-filter__rating">
          <div className="question-details-filter__rating__header">
            <p>Feedback</p>
            <img src={remove} />
          </div>
          <div className="question-details-filter__rating__option">
                <div>
                  <input
                    type="checkbox"
                    checked={filter?.like? true : false}
                    id={"like"}
                    onChange={(e) =>
                      handleRatingChange(QuestionDetailFilterTypes.Like, e.target.checked)
                    }
                  />
                  <label htmlFor={"like"}>
                    <span></span> Like
                  </label>
                </div>          
                <p>{likesData}</p>
              </div>

              <div className="question-details-filter__rating__option">
                <div>
                  <input
                    type="checkbox"
                    checked={filter?.dislike? true : false}
                    id={"dislike"}
                    onChange={(e) =>
                      handleRatingChange(QuestionDetailFilterTypes.Dislike, e.target.checked)
                    }
                  />
                  <label htmlFor={"dislike"}>
                    <span></span> Dislike
                  </label>
                </div>          
                <p>{disLikesData}</p>
              </div>

              <div className="question-details-filter__rating__option">
                <div>
                  <input
                    type="checkbox"
                    checked={filter?.noaction? true : false}
                    id={"noaction"}
                    onChange={(e) =>
                      handleRatingChange(QuestionDetailFilterTypes.NoAction, e.target.checked)
                    }
                  />
                  <label htmlFor={"noaction"}>
                    <span></span> No action
                  </label>
                </div>          
                <p>{noActionsData}</p>
              </div>

          {/* <div className="user-review-filter__rating__option">
            <div>
              <input
                type="checkbox"
                checked={filter.notProvided}
                id={ReviewFilterTypes.NotProvided.toString()}
                onChange={(e) => handleNotProvidedChange(e.target.checked)}
              />
              <label htmlFor={ReviewFilterTypes.NotProvided.toString()}>
                <span></span> Not provided
              </label>
            </div>
            <p>140</p>
          </div> */}
        </div>
        
        <div className="body-item">
        <DateRangePicker
          label="Duration:"
          value={filter.duration}
          format="dd/MM/y"
          maxDate={new Date()}
          onChange={handleDateRangeChange}
        />
        </div>
        <div className="body-item">
          {/* <span>Select the source document</span> */}
          {/* <DropdownSearch 
              label={"Source Document"} 
              dropdownOptions={dropdownData}
              showSelectedOption={setSelectedDocument}
            /> */}
          {/* <div className="dropdown">
            <button onClick={handleOpen}>
              {!dropdownValue && (
                <>
                <span>Select a document</span>
                <img src={down} className={open ? "active arrow" : "arrow"} />
                </>
              )} 
              {dropdownValue && (
                <>
                 <span className="hasValue">Select a document</span>
                 <div className="dropdown-value">
                 <img className="pdf" src={pdf} />
                 {dropdownValue} 
                 <img src={down} className={open ? "active arrow" : "arrow"} />
                 </div>
                </>
               
              )}
              
            </button>
            {open ? (
              <ul>
                {dropdownOptions?.map((item,index) => (
                  <li onClick={() => handleChange(item?.documentName,item?.documentGuid)} key={index}>
                    <img src={pdf} /> {item?.documentName}
                  </li>
                ))}
              </ul>
            ) : null}
          </div> */}
          <MultiSelect
          multi={true}
          searchable={true}
          optionsPosition="top"
          defaultOption={filter.documentGuids}
          label={"Source Document"}
          placeholder="Search..."
          checkBoxName={"source-document"}
          onChange={handleDocumentChange}
          data={transformDocuments()}
        />
        </div>
        <div className="body-item">
        <MultiSelect
          multi={true}
          searchable={true}
          optionsPosition="top"
          defaultOption={filter.country}
          label={"Country"}
          placeholder="Search..."
          checkBoxName={"country"}
          onChange={handleCountryChange}
          data={transformCountries()}
        />
        </div>
      </div>
      <footer>
        <button className="btn btn-primary" onClick={() => onApply(filter)} disabled={checkFilterEmpty()}>
          Apply
        </button>
        <button className="btn btn-secondary " onClick={handleResetFilter}>
          Clear all
        </button>
      </footer>
    </div>
  );
};
export default QuestionDetailsFilter;
