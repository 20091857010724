import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import {
  edit_icon,
  reject_icon,
  search,
  tick_icon,
  filter,
  refreshIcon
} from "assets/media";
import { GridService } from "common/services/table-service";
import ActionCellRenderer from "common/components/custom/ActionCellRenderer/ActionCellRenderer";
import ArrayofDataCellRenderer from "common/components/custom/ArrayOfDataCellRenderer/ArrayofDataCellRenderer";
import AvatarCellRenderer from "common/components/custom/AvatarCellRenderer/AvatarCellRenderer";
import StatusCellRenderer from "common/components/custom/StatusCellRenderer/StatusCellRenderer";
import "./UserManagement.scss";
import { add_button_white, plusIcon24px } from "assets/media";
import GPOAgGrid from "common/components/custom/GPOAgGrid/GPOAgGrid";
import GridDateCellRenderer from "common/components/custom/GridDateCellRenderer/GridDateCellRenderer";
import useDebounce from "common/hooks/useDebounce";
import {
  RootState,
  useAppDispatch,
  useAppSelector
} from "common/redux/core/root.reducer";
import { userManagementActions } from "common/redux/store/user-management/user-management.slice";
import AddUser from "../AddUser/AddUser";
import Filter from "../Filter/Filter";
import { IFilterNameValues } from "modules/gpo-agent/models/filter-component/filter-values.model";
import EditUser from "../EditUser/EditUser";
import ApproveReject from "../ApproveReject/ApproveReject";
import { ApproveRejectRequestModel } from "modules/gpo-agent/models/user-confirmation/user-confirmation.model";
import Toaster from "../Toaster/Toaster";
import { TABLE_SORT_ORDER } from "common/enums/table-sort-order.enum";
import { useDispatch, useSelector } from "react-redux";
import { ApiStatus } from "common/enums";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import { TabType } from "common/enums/tab-types.enum";
import { ICountryDetails } from "modules/gpo-agent/models/country-component/country-response.model";
import DataGetterCellRenderer from "common/components/custom/DataGetterCellRenderer/DataGetterCellRenderer";
import { DataGetterCellRendererMapper } from "common/models/data-cell-renderer.model";
import { useInterval } from "common/hooks";
import { DateUtil } from "common/utils";
import { AUTO_REFRESH_INTERVAL_MS } from "common/config/app.config";

const DEBOUNCE_TIME_OUT = 1000;
const UserManagement = () => {
  const userManagementDispatch = useAppDispatch();
  const userManagementState = useAppSelector(
    (state: RootState) => state.userManagement
  );
  const [activeTab, setActiveTab] = useState("tab1");
  const [showAddUser, setShowAddUser] = useState(false);
  const searchBoxRef: any = useRef<HTMLInputElement>(null);
  const profileId = useSelector((state: RootState) => state.user.id);

  const [filterValuesForUsers, setFilterValuesForUsers] = useState<
    Array<IFilterNameValues>
  >([
    {
      filterName: "User Type",
      filterCode: "RoleCodes",
      filterValues: [
        {
          code: "Client",
          value: "User"
        },
        {
          code: "Agent",
          value: "Knowledge worker"
        },
        {
          code: "Admin",
          value: "Admin"
        }
      ]
    },
    {
      filterName: "Status",
      filterCode: "IsActive",
      filterValues: [
        {
          code: "isActive",
          value: "Active"
        },
        {
          code: "isInactive",
          value: "Inactive"
        }
      ]
    }
  ]);
  const [filterValuesForRequests, setFilterValuesForRequests] = useState<
    Array<IFilterNameValues>
  >([
    {
      filterName: "Status",
      filterCode: "RequestStatuses",
      filterValues: [
        {
          code: "Pending",
          value: "Pending"
        },
        {
          code: "Approved",
          value: "Approved"
        },
        {
          code: "Rejected",
          value: "Rejected"
        }
      ]
    }
  ]);

  const addUser = () => {
    setShowAddUser(true);
  };

  const handleTab = (tab: string) => {
    if (searchBoxRef?.current) {
      searchBoxRef.current.value = "";
    }
    setSearchTerm(undefined);
    setSearchFilterValueUser(undefined);
    setSearchFilterValueRequest(undefined);
    setFilteredValueRequest("");
    setFilteredValueUser("");
    setSelectedFilterMap({});
    setActiveTab(tab);
  };

  const [searchTerm, setSearchTerm] = useState<any>("");
  const debouncedSearchTerm: any = useDebounce(searchTerm, DEBOUNCE_TIME_OUT);
  const [searchFilterValueUser, setSearchFilterValueUser] =
    useState<any>(undefined);
  const [searchFilterValueRequest, setSearchFilterValueRequest] =
    useState<any>(undefined);
  const [refreshUserGrid, setRefreshUserGrid] = useState<boolean>(false);
  const [refreshRequestsGrid, setRefreshRequestsGrid] =
    useState<boolean>(false);
  const [filteredValueUser, setFilteredValueUser] = useState<any>(undefined);
  const [filteredValueRequest, setFilteredValueRequest] =
    useState<any>(undefined);
  const [selectedFilterMap, setSelectedFilterMap] = useState({});
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [editUserData, setEditUserData] = useState<any>();
  const [showUserRequestConfirmation, setShowUserRequestConfirmation] =
    useState<boolean>(false);
  const [requestConfirmationData, setRequestConfirmationData] = useState<any>();
  const [requestConfirmationMessage, setRequestConfirmationMessage] =
    useState<any>();
  const [requestConfirmationBtn, setRequestConfirmationBtn] =
    useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastMessageSuccess, setToastMessageSuccess] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const acceptConversation = useSelector(
    (state: RootState) => state.agents.acceptConversation.status
  );
  const countryState = useAppSelector(
    (state: RootState) => state.header?.countryResponse?.data
  );
  const [countryData, setCountryData] = useState<
    Array<DataGetterCellRendererMapper>
  >([]);
  const [columnDefsUser, setColumnDefsUser] = useState<Array<any>>([]);
  const [lastUpdatedDatetime, setLastUpdatedDatetime] = useState(
    DateUtil.getTimeInDDMMMYYYY()
  );

  useEffect(() => {
    dispatch(appHeaderActions?.getAllCountries());
  }, []);

  useEffect(() => {
    if (countryState?.code == 200) {
      if (countryState?.countries?.length) {
        const dropdownData: Array<DataGetterCellRendererMapper> = [];
        countryState?.countries?.map((country: ICountryDetails) => {
          const dropdownoption: DataGetterCellRendererMapper = {
            guid: country?.guidCode,
            value: country?.name
          };
          dropdownData?.push(dropdownoption);
        });
        setCountryData(dropdownData);
      }
      setColumnDefsUser([
        {
          field: "userName",
          headerName: "USER",
          sortable: true,
          unSortIcon: true,
          flex: 2,
          cellRendererFramework: (params) => {
            return (
              <AvatarCellRenderer
                profileIdField={params?.data?.userGuid}
                displayName={params?.value}
              ></AvatarCellRenderer>
            );
          }
        },
        {
          field: "userEmail",
          headerName: "EMAIL",
          sortable: true,
          unSortIcon: true,
          flex: 2
        },
        {
          field: "roles",
          headerName: "USER TYPE",
          flex: 2,
          cellRendererFramework: (params) => {
            return (
              <ArrayofDataCellRenderer
                arrayofData={params?.value}
                fieldToShow={"transformedRoleName"}
              ></ArrayofDataCellRenderer>
            );
          }
        },
        {
          field: "isActive",
          headerName: "STATUS",
          sortable: true,
          unSortIcon: true,
          flex: 1,
          cellRendererFramework: (params) => {
            return (
              <StatusCellRenderer
                statusMapper={[
                  {
                    value: true,
                    displayName: "Active",
                    color: "#57E188"
                  },
                  {
                    value: false,
                    displayName: "Inactive",
                    color: "#F95D54"
                  }
                ]}
                statusValue={params?.value}
              ></StatusCellRenderer>
            );
          }
        },
        {
          field: "countryGuid",
          headerName: "COUNTRY",
          sortable: true,
          unSortIcon: true,
          flex: 1,
          cellRendererFramework: (params) => {
            return (
              <DataGetterCellRenderer
                dataGetter={countryState?.countries}
                dataValue={params?.value}
              />
            );
          }
        },
        {
          field: "userGuid",
          headerName: "",
          flex: 1,
          cellRendererFramework: (params) => {
            return (
              <ActionCellRenderer
                rowData={params?.data}
                actionItems={[
                  {
                    actionName: "edit",
                    actionSrc: edit_icon,
                    isActionDisabled: false,
                    displayConditions: [
                      {
                        conditions: [
                          {
                            fieldName: "userGuid",
                            value: `${profileId}`,
                            matchType: "!=",
                            joinCondition: ""
                          }
                        ],
                        groupJoinCondition: ""
                      }
                    ]
                  }
                ]}
                onActionClicked={onEditIconClicked}
              ></ActionCellRenderer>
            );
          }
        }
      ]);
    }
  }, [countryState]);

  useEffect(() => {
    if (acceptConversation === ApiStatus.SUCCESS) {
      dispatch(appHeaderActions?.updateTabType(TabType.ACTIVE_SESSION));
    }
  }, [acceptConversation]);

  useEffect(() => {
    if (activeTab == "tab1") {
      setSearchFilterValueUser(debouncedSearchTerm);
    } else {
      setSearchFilterValueRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const onEditIconClicked = (rowData: any, actionName: string) => {
    setShowEditUser(true);
    setEditUserData(rowData);
  };

  const onApproveRejectClicked = (rowData: any, actionName: string) => {
    setShowUserRequestConfirmation(true);
    if (actionName == "approve") {
      setRequestConfirmationData(rowData);
      setRequestConfirmationBtn(false);
      setRequestConfirmationMessage(
        "Are you sure you want to approve this user?"
      );
    } else if (actionName == "reject") {
      setRequestConfirmationData(rowData);
      setRequestConfirmationBtn(true);
      setRequestConfirmationMessage(
        "Are you sure you want to reject this user?"
      );
    }
  };

  // const [columnDefsUser, setColumnDefsUser] = useState([
  //   {
  //     field: "userName",
  //     headerName: "USER",
  //     sortable: true,
  //     unSortIcon: true,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <AvatarCellRenderer
  //           profileIdField={params?.data?.userGuid}
  //           displayName={params?.value}
  //         ></AvatarCellRenderer>
  //       );
  //     },
  //   },
  //   {
  //     field: "userEmail",
  //     headerName: "EMAIL" ,
  //     sortable: true,
  //     unSortIcon: true,
  //   },
  //   {
  //     field: "roles",
  //     headerName: "USER TYPE",
  //     cellRendererFramework: (params) => {
  //       return (
  //         <ArrayofDataCellRenderer
  //           arrayofData={params?.value}
  //           fieldToShow={"transformedRoleName"}
  //         ></ArrayofDataCellRenderer>
  //       );
  //     },
  //   },
  //   {
  //     field: "isActive",
  //     headerName: "STATUS",
  //     sortable: true,
  //     unSortIcon: true,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <StatusCellRenderer
  //           statusMapper={[
  //             {
  //               value: true,
  //               displayName: "Active",
  //               color: "#57E188",
  //             },
  //             {
  //               value: false,
  //               displayName: "Inactive",
  //               color: "#F95D54",
  //             },
  //           ]}
  //           statusValue={params?.value}
  //         ></StatusCellRenderer>
  //       );
  //     },
  //   },
  //   {
  //     field: "countryGuid",
  //     headerName: "COUNTRY" ,
  //     sortable: true,
  //     unSortIcon: true,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <DataGetterCellRenderer
  //         dataGetter={countryState?.countries}
  //         dataValue={params?.value}/>
  //       );
  //     },
  //   },
  //   {
  //     field: "userGuid",
  //     headerName: "",
  //     cellRendererFramework: (params) => {
  //       return (
  //         <ActionCellRenderer
  //           rowData={params?.data}
  //           actionItems={[
  //             {
  //               actionName: "edit",
  //               actionSrc: edit_icon,
  //               isActionDisabled: false,
  //               displayConditions:[
  //                 {
  //                 conditions:[
  //                 {
  //                   fieldName:"userGuid",
  //                   value:`${profileId}`,
  //                   matchType:"!=",
  //                   joinCondition:""
  //                 }
  //                 ],
  //                 groupJoinCondition:''
  //                 }
  //               ]
  //             },
  //           ]}
  //           onActionClicked={onEditIconClicked}
  //         ></ActionCellRenderer>
  //       );
  //     },
  //   },
  // ]);

  const [columnDefsRequests, setColumnDefsRequests] = useState([
    {
      field: "userName",
      headerName: "USER",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <AvatarCellRenderer
            profileIdField={params?.data?.userGuid}
            displayName={params?.value}
          ></AvatarCellRenderer>
        );
      }
    },
    {
      field: "email",
      headerName: "EMAIL",
      sortable: true,
      unSortIcon: true
    },
    {
      field: "requestType",
      headerName: "REQUEST SOURCE",
      sortable: true,
      unSortIcon: true
    },
    {
      field: "createdDate",
      headerName: "REQUESTED DATE",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <GridDateCellRenderer
            dateToConvert={params?.value}
            format={"DD MMM YYYY h:mm a"}
          ></GridDateCellRenderer>
        );
      }
    },
    {
      field: "requestStatusCode",
      headerName: "REQUEST STATUS",
      sortable: true,
      unSortIcon: true,
      cellRendererFramework: (params) => {
        return (
          <StatusCellRenderer
            statusMapper={[
              {
                value: "Pending",
                displayName: "Pending",
                color: "#35A4E8"
              },
              {
                value: "Approved",
                displayName: "Approved",
                color: "#34C768"
              },
              {
                value: "Rejected",
                displayName: "Rejected",
                color: "#F95D54"
              }
            ]}
            statusValue={params?.value}
          ></StatusCellRenderer>
        );
      }
    },
    {
      field: "userGuid",
      headerName: "",
      cellRendererFramework: (params) => {
        return (
          <ActionCellRenderer
            rowData={params?.data}
            actionItems={[
              {
                actionName: "approve",
                actionSrc: tick_icon,
                isActionDisabled: false,
                displayConditions: [
                  {
                    conditions: [
                      {
                        fieldName: "requestStatusCode",
                        value: "Approved",
                        matchType: "!=",
                        joinCondition: ""
                      },
                      {
                        fieldName: "requestStatusCode",
                        value: "Rejected",
                        matchType: "!=",
                        joinCondition: "&&"
                      }
                    ],
                    groupJoinCondition: ""
                  }
                ]
              },
              {
                actionName: "reject",
                actionSrc: reject_icon,
                isActionDisabled: false,
                displayConditions: [
                  {
                    conditions: [
                      {
                        fieldName: "requestStatusCode",
                        value: "Approved",
                        matchType: "!=",
                        joinCondition: ""
                      },
                      {
                        fieldName: "requestStatusCode",
                        value: "Rejected",
                        matchType: "!=",
                        joinCondition: "&&"
                      }
                    ],
                    groupJoinCondition: ""
                  }
                ]
              }
            ]}
            onActionClicked={onApproveRejectClicked}
          ></ActionCellRenderer>
        );
      }
    }
  ]);

  const handleSearchInputChange = (searchValue) => {
    if (searchValue) {
      setSearchTerm(searchValue);
    } else {
      setSearchTerm("");
    }
  };
  const handleUserAdded = (userStatus: any) => {
    hideAddUser();
    if (userStatus?.isSuccess) {
      if (activeTab == "tab1") {
        setRefreshUserGrid(true);
      } else if (activeTab == "tab2") {
        setRefreshRequestsGrid(true);
      }
      setToastMessage("User added succesfully!");
      setToastMessageSuccess(true);
      setShowToast(true);
    } else {
      if (
        !userStatus?.isSuccess &&
        userStatus?.errorCode == "SelfAlterProhibited"
      ) {
        setToastMessage(
          "Access Denied! You cannot add or modify your own user roles."
        );
        setToastMessageSuccess(false);
        setShowToast(true);
      }
    }
  };
  const handleUserUpdated = (userStatus: boolean) => {
    if (userStatus) {
      hideEditUser();
      setTimeout(() => {
        if (activeTab == "tab1") {
          setRefreshUserGrid(true);
        } else if (activeTab == "tab2") {
          setRefreshRequestsGrid(true);
        }
      }, 100);
      setToastMessage("User details updated succesfully!");
      setToastMessageSuccess(true);
      setShowToast(true);
    }
  };
  const handleUserRequestAcknowledged = (userStatus: boolean) => {
    //if (userStatus) {
    hideApproveReject();
    setTimeout(() => {
      if (activeTab == "tab1") {
        setRefreshUserGrid(true);
      } else if (activeTab == "tab2") {
        setRefreshRequestsGrid(true);
      }
    }, 100);
    // setToastMessage("User request acknowledged!");
    // setToastMessageSuccess(true);
    // setShowToast(true);
    //}
  };
  const hideAddUser = () => {
    setRefreshUserGrid(false);
    setRefreshRequestsGrid(false);
    setShowAddUser(false);
  };
  const hideEditUser = () => {
    setRefreshUserGrid(false);
    setRefreshRequestsGrid(false);
    setShowEditUser(false);
  };
  const hideFilterModal = () => {
    setRefreshUserGrid(false);
    setRefreshRequestsGrid(false);
    setIsShowFilter(false);
  };
  const hideApproveReject = () => {
    setRefreshUserGrid(false);
    setRefreshRequestsGrid(false);
    setShowUserRequestConfirmation(false);
  };
  const approveRejectActionClicked = (data: any, actionName: string) => {
    console.log(`${actionName}:${data}`);
    const userRequest: ApproveRejectRequestModel = {
      requestGuid: data?.requestGuid,
      isApproved: actionName == "approve" ? true : false
    };
    userManagementDispatch(
      userManagementActions?.approveRejectUser(userRequest)
    );
  };
  const applyFilter = (filterMap: any) => {
    let filterQuery = "";
    if (activeTab == "tab1") {
      setFilteredValueUser(undefined);
    } else {
      setFilteredValueRequest(undefined);
    }
    setSelectedFilterMap(filterMap);
    for (const key in filterMap) {
      let filterValues: any = filterMap[key];
      if (activeTab == "tab1" && key == "IsActive") {
        if (
          filterValues?.indexOf("isActive") != -1 &&
          filterValues?.indexOf("isInactive") != -1
        ) {
          filterQuery = filterQuery;
        } else if (
          filterValues?.indexOf("isActive") != -1 &&
          filterValues?.indexOf("isInactive") == -1
        ) {
          filterQuery = filterQuery + `&${key}=` + true;
        } else if (
          filterValues?.indexOf("isActive") == -1 &&
          filterValues?.indexOf("isInactive") != -1
        ) {
          filterQuery = filterQuery + `&${key}=` + false;
        }
      } else {
        filterValues?.map((data: any) => {
          filterQuery = filterQuery + `&${key}=` + data;
        });
      }
    }
    if (activeTab == "tab1") {
      setFilteredValueUser(filterQuery);
    } else {
      setFilteredValueRequest(filterQuery);
    }
    hideFilterModal();
  };
  const [isShowFilter, setIsShowFilter] = useState(false);
  const showFilter = (isShowFilter) => {
    setIsShowFilter((isShowFilter) => !isShowFilter);
  };
  const resetFilter = () => {
    setSelectedFilterMap({});
    if (activeTab == "tab1") {
      setFilteredValueUser("");
      //setRefreshUserGrid(true);
    } else {
      setFilteredValueRequest("");
      //setRefreshRequestsGrid(true);
    }
    hideFilterModal();
  };
  const hideToast = () => {
    setShowToast(false);
  };
  /* Auto Refresh */
  const handleAutoRefresh = () => {
    setRefreshUserGrid(true);
  };
  // auto-refresh the data
  const { startInterval } = useInterval(
    handleAutoRefresh,
    AUTO_REFRESH_INTERVAL_MS,
    false
  );

  const handleGridApiSuccess = () => {
    setLastUpdatedDatetime(DateUtil.getTimeInDDMMMYYYY());
    setRefreshUserGrid(false);
    startInterval();
  };
    /* Auto Refresh */

  return (
    <div className="user-management">
      <div className="user-management__container">
        <div className="header">
          <div className="header__left">
            <h2>User Management</h2>
            <p>{`Last updated on ${lastUpdatedDatetime}`}</p>
          </div>
          <div className="header__right">
            <button onClick={(event) => addUser()}>
              <img src={add_button_white} />
              Add User
            </button>
          </div>
        </div>
        <div className="body">
          <div className="body__panel">
            <div className="body__tabs">
              <span
                className={activeTab === "tab1" ? "active" : ""}
                onClick={(event) => handleTab("tab1")}
              >
                Active users
              </span>
              <span
                className={activeTab === "tab2" ? "active" : ""}
                onClick={(event) => handleTab("tab2")}
              >
                User request
              </span>
            </div>
            <button
              className="user-review__selected-filter__refresh-btn"
              onClick={handleAutoRefresh}
            >
              <img src={refreshIcon} />
              <p>Refresh</p>
            </button>
          </div>

          <div className="body__tab-content">
            <div className="tab-content-search">
              <img src={search} />
              <input
                type="search"
                placeholder="Search"
                ref={searchBoxRef}
                onChange={(e) => handleSearchInputChange(e.target.value)}
              />
              <div className="filter-section">
                <div className="filter" onClick={showFilter}>
                  <img src={filter} />
                  <span>Filter</span>
                </div>
                {isShowFilter && (
                  <Filter
                    currentSelectedFilter={selectedFilterMap}
                    onFilterApplied={applyFilter}
                    onCloseModal={hideFilterModal}
                    filterValues={
                      activeTab == "tab1"
                        ? filterValuesForUsers
                        : filterValuesForRequests
                    }
                    onResetFilter={resetFilter}
                  />
                )}
              </div>
            </div>
            {showToast && (
              <Toaster
                message={toastMessage}
                isSuccess={toastMessageSuccess}
                closeTime={3000}
                onCloseToast={hideToast}
              />
            )}
            {showAddUser && (
              <AddUser
                onAddNewUser={handleUserAdded}
                onCloseModal={hideAddUser}
              />
            )}
            {showEditUser && (
              <EditUser
                userData={editUserData}
                onCloseModal={hideEditUser}
                onUpdateUser={handleUserUpdated}
              />
            )}
            {showUserRequestConfirmation && (
              <ApproveReject
                gridData={requestConfirmationData}
                message={requestConfirmationMessage}
                hasErrorBtn={requestConfirmationBtn}
                onAction={approveRejectActionClicked}
                onCloseModal={hideApproveReject}
                onActionCompleted={handleUserRequestAcknowledged}
              />
            )}

            {activeTab === "tab1" ? (
              <div className="active-users">
                <GPOAgGrid
                  key={activeTab}
                  method="GET"
                  url={"User"}
                  payloadType="query"
                  paginationSize={10}
                  columnDefinitions={columnDefsUser}
                  searchFilter={searchFilterValueUser}
                  refreshGrid={refreshUserGrid}
                  additionalQuery={filteredValueUser}
                  initialSortModel={{
                    colId: "userName",
                    sort: TABLE_SORT_ORDER.ASCENDING
                  }}
                  onGridApiSuccess={handleGridApiSuccess}
                ></GPOAgGrid>
              </div>
            ) : (
              <div className="user-request">
                <GPOAgGrid
                  key={activeTab}
                  url={"Admin/requests"}
                  method="GET"
                  paginationSize={10}
                  columnDefinitions={columnDefsRequests}
                  searchFilter={searchFilterValueRequest}
                  refreshGrid={refreshRequestsGrid}
                  additionalQuery={filteredValueRequest}
                  payloadType="query"
                  initialSortModel={{
                    colId: "createdDate",
                    sort: TABLE_SORT_ORDER.DESCENDING
                  }}
                ></GPOAgGrid>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
