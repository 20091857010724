import { CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { RequestAccessModel } from "common/models/request-access.model";

const userApiConfig = {   
    requestAccess: (requestDetails:RequestAccessModel): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.USER}/${METHOD.REQUEST_ACCESS}`, 'POST','', requestDetails),  
    
    logoutUser :(): ApiConfig<any> =>
       new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.LOGOUT}`, 'POST')
        
};

export default userApiConfig;