import { ApiResult } from "common/models";
import { DashboardService } from "./dashboard.service";
import { dashboardActions } from "./dashboard.slice";
import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  AvgTimeToResolveDataResponse,
  DashboardDataRequest,
  SentimentDistDataResponse,
  UnresolvedRateResponse,
  OpinionDataRequest,
  ResolvedDataResponse,
  Top5SourceDocsDataResponse,
  TrafficDataForMapResponse,
  UserEngFieldResponse,
  UserRatingsResponse,
} from "modules/gpo-agent/models/dashboard";

const dashboardWatch = function* () {
  yield takeLatest(
    dashboardActions.getResolvedRateData.type,
    getResolvedRateData
  );
  yield takeLatest(
    dashboardActions.getUnresolvedRateData.type,
    getUnresolvedRateData
  );
  yield takeLatest(
    dashboardActions.getAvgTimeToResolveData.type,
    getAvgTimeToResolveData
  );
  yield takeLatest(
    dashboardActions.getSentimentDistData.type,
    getSentimentDistData
  );
  yield takeLatest(
    dashboardActions.getUserRatingsData.type,
    getUserRatingsData
  );
  yield takeLatest(dashboardActions.getTotalTraffic.type, getTotalTraffic);
  yield takeLatest(dashboardActions.getTotalLikes.type, getTotalLikes);
  yield takeLatest(dashboardActions.getTotalDislikes.type, getTotalDislikes);
  yield takeLatest(
    dashboardActions.getTotalConversations.type,
    getTotalConversations
  );
  yield takeLatest(dashboardActions.getTotalQuestions.type, getTotalQuestions);
  yield takeLatest(dashboardActions.getEngagedUsers.type, getEngagedUsers);
  yield takeLatest(
    dashboardActions.getTop5SourceDocsBegin.type,
    getTop5SourceDocs
  );
  yield takeLatest(
    dashboardActions.getTrafficDataForMapsBegin.type,
    getTrafficDataForMaps
  );
};

const getResolvedRateData = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<ResolvedDataResponse> =
    yield DashboardService.getResolvedRateData(payload);

  if (hasErrors) {
    yield put(dashboardActions.getResolvedRateDataError());
  } else {
    yield put(dashboardActions.getResolvedRateDataSuccess(value));
  }
};

const getUnresolvedRateData = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<UnresolvedRateResponse> =
    yield DashboardService.getUnresolvedRateData(payload);

  if (hasErrors) {
    yield put(dashboardActions.getUnresolvedRateError());
  } else {
    yield put(dashboardActions.getUnresolvedRateSuccess(value));
  }
};

const getAvgTimeToResolveData = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<AvgTimeToResolveDataResponse> =
    yield DashboardService.getAvgTimeToResolveData(payload);

  if (hasErrors) {
    yield put(dashboardActions.getAvgTimeToResolveError());
  } else {
    yield put(dashboardActions.getAvgTimeToResolveSuccess(value));
  }
};

const getSentimentDistData = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<SentimentDistDataResponse> =
    yield DashboardService.getSentimentDistData(payload);

  if (hasErrors) {
    yield put(dashboardActions.getSentimentDistDataError());
  } else {
    yield put(dashboardActions.getSentimentDistDataSuccess(value));
  }
};

const getUserRatingsData = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<UserRatingsResponse> =
    yield DashboardService.getUserRatingsData(payload);

  if (hasErrors) {
    yield put(dashboardActions.getUserRatingsError());
  } else {
    yield put(dashboardActions.getUserRatingsSuccess(value));
  }
};

const getTotalTraffic = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<UserEngFieldResponse> =
    yield DashboardService.getTotalTraffic(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTotalTrafficError());
  } else {
    yield put(dashboardActions.getTotalTrafficSuccess(value));
  }
};

const getTotalLikes = function* ({
  payload,
}: PayloadAction<OpinionDataRequest>) {
  const { hasErrors, value }: ApiResult<UserEngFieldResponse> =
    yield DashboardService.getTotalLikes(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTotalLikesError());
  } else {
    yield put(dashboardActions.getTotalLikesSuccess(value));
  }
};

const getTotalDislikes = function* ({
  payload,
}: PayloadAction<OpinionDataRequest>) {
  const { hasErrors, value }: ApiResult<UserEngFieldResponse> =
    yield DashboardService.getTotalDislikes(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTotalDislikesError());
  } else {
    yield put(dashboardActions.getTotalDislikesSuccess(value));
  }
};

const getTotalConversations = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<UserEngFieldResponse> =
    yield DashboardService.getTotalConversations(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTotalConversationsError());
  } else {
    yield put(dashboardActions.getTotalConversationsSuccess(value));
  }
};

const getTotalQuestions = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<UserEngFieldResponse> =
    yield DashboardService.getTotalQuestions(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTotalQuestionsError());
  } else {
    yield put(dashboardActions.getTotalQuestionsSuccess(value));
  }
};

const getEngagedUsers = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<UserEngFieldResponse> =
    yield DashboardService.getEngagedUsers(payload);

  if (hasErrors) {
    yield put(dashboardActions.getEngagedUsersError());
  } else {
    yield put(dashboardActions.getEngagedUsersSuccess(value));
  }
};

const getTop5SourceDocs = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<Top5SourceDocsDataResponse> =
    yield DashboardService.getTop5SourceDocs(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTop5SourceDocsError());
  } else {
    yield put(dashboardActions.getTop5SourceDocsSuccess(value));
  }
};

const getTrafficDataForMaps = function* ({
  payload,
}: PayloadAction<DashboardDataRequest>) {
  const { hasErrors, value }: ApiResult<TrafficDataForMapResponse> =
    yield DashboardService.getTrafficDataForMaps(payload);

  if (hasErrors) {
    yield put(dashboardActions.getTrafficDataForMapsError());
  } else {
    yield put(dashboardActions.getTrafficDataForMapsSuccess(value));
  }
};

export { dashboardWatch };
