import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  annotation_dots,
  chevron_right,
  info,
  message_question,
  thumbs_down,
  thumbs_up,
  total_traffic,
  users_check,
} from "assets/media/icons";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";
import {
  UserEngmntFieldsSkeleton,
  TreeMap,
  Tooltip,
  TreeMapSkeletonLoader,
} from "common/components";
import { TreeMapData } from "modules/gpo-agent/models/dashboard";
import { useDashboard } from "../Dashboard/hooks/useDashboard";
import { TabType } from "common/enums/tab-types.enum";
import "./UserEngagement.scss";

type UserEngagementProps = {
  onIconClick: (componentName: TabType) => void;
};

const UserEngagement: FC<UserEngagementProps> = ({
  onIconClick,
}: UserEngagementProps) => {
  const {
    totalTraffic,
    engagedUsers,
    totalLikes,
    totalDislikes,
    totalQuestions,
    totalConversations,
    top5SourceDocs,
  } = useSelector((state: RootState) => state.dashboard.userEngagementData);

  const [treeMapData, setTreeMapData] = useState<TreeMapData>({
    name: "",
    children: [],
  });

  const { transformTopSourceDocsData } = useDashboard();

  // transform data for top source docs
  useEffect(() => {
    if (top5SourceDocs.status === ApiStatus.SUCCESS) {
      const topSourceDocsData = transformTopSourceDocsData(top5SourceDocs.data);
      setTreeMapData(topSourceDocsData);
    }

    return () => setTreeMapData({ name: "", children: [] });
  }, [top5SourceDocs]);

  return (
    <div className="user-engagement">
      <p>User engagement</p>
      <div className="user-engagement-summary">
        <div className="user-engagement-summary__left">
          <div className="user-engagement-summary__left--block">
            <img src={total_traffic} />
            <div>
              <p className="block-label">
                Total traffic
                <Tooltip text={"Total number of user entrances"}>
                  <img src={info} />
                </Tooltip>
              </p>
              {totalTraffic.status === ApiStatus.LOADING ? (
                <UserEngmntFieldsSkeleton />
              ) : (
                <p className="block-value">
                  {totalTraffic.data.total}
                  <span>
                    <img
                      src={chevron_right}
                      onClick={() => onIconClick(TabType.METRIC_DASHBOARD)}
                    />
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="user-engagement-summary__left--block">
            <img src={users_check} />
            <div>
              <p className="block-label">
                Engaged Users{" "}
                <Tooltip
                  text={
                    "The total number of unique users who have engaged in a conversation with a chatbot within the selected duration filter."
                  }
                >
                  <img src={info} />
                </Tooltip>
              </p>
              {engagedUsers.status === ApiStatus.LOADING ? (
                <UserEngmntFieldsSkeleton />
              ) : (
                <p className="block-value">{engagedUsers.data.total}</p>
              )}
            </div>
          </div>

          <div className="user-engagement-summary__left--block">
            <img src={thumbs_up} />
            <div>
              <p className="block-label">
                Total Likes{" "}
                <Tooltip text={"Total positive feedback received from users."}>
                  <img src={info} />
                </Tooltip>
              </p>
              {totalLikes.status === ApiStatus.LOADING ? (
                <UserEngmntFieldsSkeleton />
              ) : (
                <p className="block-value">
                  {totalLikes.data.total}
                  <span>
                    <img
                      src={chevron_right}
                      onClick={() => onIconClick(TabType.QUESTION_DETAILS)}
                    />
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="user-engagement-summary__left--block">
            <img src={thumbs_down} />
            <div>
              <p className="block-label">
                Total Dislikes{" "}
                <Tooltip text={"Total negative feedback received from users."}>
                  <img src={info} />
                </Tooltip>
              </p>
              {totalDislikes.status === ApiStatus.LOADING ? (
                <UserEngmntFieldsSkeleton />
              ) : (
                <p className="block-value">
                  {totalDislikes.data.total}
                  <span>
                    <img
                      src={chevron_right}
                      onClick={() => onIconClick(TabType.QUESTION_DETAILS)}
                    />
                  </span>
                </p>
              )}
            </div>
          </div>

          <div className="user-engagement-summary__left--block">
            <img src={message_question} />
            <div>
              <p className="block-label">
                Total Questions{" "}
                <Tooltip
                  text={"The total number of questions that have been asked."}
                >
                  <img src={info} />
                </Tooltip>
              </p>
              {totalQuestions.status === ApiStatus.LOADING ? (
                <UserEngmntFieldsSkeleton />
              ) : (
                <p className="block-value">{totalQuestions.data.total}</p>
              )}
            </div>
          </div>

          <div className="user-engagement-summary__left--block">
            <img src={annotation_dots} />
            <div>
              <p className="block-label">
                Total Conversations{" "}
                <Tooltip
                  text={"The total number of conversations that have occurred."}
                >
                  <img src={info} />
                </Tooltip>
              </p>
              {totalConversations.status === ApiStatus.LOADING ? (
                <UserEngmntFieldsSkeleton />
              ) : (
                <p className="block-value">{totalConversations.data.total} </p>
              )}
            </div>
          </div>
        </div>

        <div className="user-engagement-summary__right">
          <p className="label">
            Top-5 source documents sourced
            <Tooltip
              text={
                "This section presents the total volume and percentage breakdown of questions originating from the top 5 documents."
              }
            >
              <img src={info} />
            </Tooltip>
          </p>
          <div
            className={`documents-container ${
              top5SourceDocs.status === ApiStatus.LOADING
                ? "documents-container--loading"
                : ""
            }`}
          >
            {top5SourceDocs.status === ApiStatus.LOADING ? (
              <TreeMapSkeletonLoader />
            ) : top5SourceDocs.status === ApiStatus.ERROR ||
              (top5SourceDocs.status === ApiStatus.SUCCESS &&
                !top5SourceDocs.data.top5SourceDocuments.length) ? (
              <p className="no-data">No data found</p>
            ) : (
              <TreeMap data={treeMapData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEngagement;
