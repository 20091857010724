import { FC, useEffect, useRef, useState } from "react";
import Datamap from "datamaps";
import { useSelector } from "react-redux";
import { RootState } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";
import { useDashboard } from "modules/gpo-agent/components/Dashboard/hooks/useDashboard";
import { right_arrow } from "assets/images";
import { ProgressBar } from "common/components";
import "./WorldMap.scss";

type WorldMapProps = {
  onBack: () => void;
};

const WorldMap: FC<WorldMapProps> = ({ onBack }: WorldMapProps) => {
  const mapRef = useRef(null);

  const { data, status } = useSelector(
    (state: RootState) => state.dashboard.trafficDataForMap
  );

  const { getFills, getColor, transformTrafficDataForMap } = useDashboard();
  const [trafficData, setTrafficData] = useState({});
  const [hasMapRendered, setMapRendered] = useState<boolean>(false);
  const hasTrafficData = Object.keys(trafficData)?.length || false;

  useEffect(() => {
    return () => {
      setTrafficData({});
    };
  }, []);

  useEffect(() => {
    if (status === ApiStatus.SUCCESS) {
      const transformedData = transformTrafficDataForMap(data);
      setTrafficData(transformedData);
    }
  }, [status]);

  useEffect(() => {
    if (hasTrafficData) {
      const myMap = new Datamap({
        element: mapRef.current,
        scope: "world",
        responsive: true,
        fills: {
          defaultFill: "#C4C4CD",
          ...getFills(),
        },
        geographyConfig: {
          popupOnHover: true,
          borderWidth: 0.5,
          highlightOnHover: true,
          highlightBorderColor: "#FFF",
          highlightBorderWidth: 1,
          highlightFillColor: (data) => getColor(data?.count),
          popupTemplate: function (geography, data) {
            return data
              ? `<div class="hoverinfo"> <p class="hoverinfo__content"> 
            <span class="fi fi-${data?.isoAlpha2?.toLowerCase()} fib country-flag"></span>
            <span>${data?.name}</span>
            <span class="user-count">${data?.count || 0}</span> 
            </p>`
              : "";
          },
        },
        data: trafficData,
      });

      // Check if the map has been rendered
      if (myMap.svg) {
        setMapRendered(true);
      }

      window.addEventListener("resize", function () {
        myMap.resize();
      });

      return () => {
        window.removeEventListener("resize", function () {
          myMap.resize();
        });
      };
    }
  }, [trafficData]);

  return (
    <div className="map-container">
      <p className="map-container__title">
        <img src={right_arrow} onClick={() => onBack()}></img> Total Traffic by
        Country
      </p>

      {status === ApiStatus.LOADING ? (
        <div className="map-container__custom-loader">
          <ProgressBar label="Data is being fetched. Please wait..." />
        </div>
      ) : status === ApiStatus.ERROR ||
        (status === ApiStatus.SUCCESS &&
          !data?.trafficDistributionMaps.length) ? (
        <p className="map-container__no-data">No data found</p>
      ) : hasTrafficData ? (
        <div className="map-container__body">
          {hasMapRendered ? ( // to show legend once map is rendered
            <div className="legend-wrapper">
              <div className="legend-wrapper__legend"></div>
              <div className="legend-wrapper__numbers">
                {Array.from({ length: 6 }, (_, index) => (
                  <p>
                    {index * 2}
                    {index ? "K" : ""}
                  </p>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            className="map"
            ref={mapRef}
            style={{
              margin: "-50px 50px",
              height: "60%",
              width: "90%",
            }}
          ></div>
        </div>
      ) : (
        <div className="map-container__custom-loader">
          <ProgressBar label="Data is being fetched. Please wait..." />
        </div>
      )}
    </div>
  );
};

export default WorldMap;
