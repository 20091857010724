import { FC } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import "./SpeedoMeter.scss";

type SpeedoMeterProps = {
  width?: number;
  height?: number;
  ringWidth?: number;
  value: number;
  start?: number;
  end?: number;
};

const SpeedoMeter: FC<SpeedoMeterProps> = ({
  width = 130,
  height = 170,
  ringWidth = 15,
  value = 0,
  start = 0,
  end = 500,
}: SpeedoMeterProps) => {
  return (
    <ReactSpeedometer
      forceRender={true}
      width={width}
      height={height}
      ringWidth={ringWidth}
      customSegmentStops={[start, value, end]}
      segmentColors={["#9C82D4", "#3A3A4A"]}
      needleColor={"#747480"}
      needleHeightRatio={0.4}
      maxValue={end}
      value={Number(value)}
      currentValueText={"${value} Seconds"}
      valueTextFontSize={"12px"}
      labelFontSize={"8px"}
      textColor={"#F6F6FA"}
    />
  );
};

export default SpeedoMeter;
