import React, { FC, useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { AgChartOptions } from "ag-charts-community";
import { TreeMapData } from "modules/gpo-agent/models/dashboard";
import "./TreeMap.scss";

type TreeMapProps = {
  data: TreeMapData;
};

const TreeMap: FC<TreeMapProps> = ({ data }: TreeMapProps) => {
  const [options, setOptions] = useState<AgChartOptions>({
    data: data,
    background: {
      visible: false,
    },
    height: 230,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    series: [
      {
        type: "treemap",
        labelKey: "docName",
        gradient: false,
        nodePadding: 10,
        nodeGap: 0,
        sizeKey: "percent",
        tileStroke: "white",
        tileStrokeWidth: 1,
        highlightGroups: false,
        highlightStyle: {
          item: {
            fill: undefined,
          },
          text: {
            color: undefined,
          },
        },
        labelShadow: {
          enabled: false,
        },
        groupFill: "transparent",
        groupStrokeWidth: 0,
        tooltip: {
          renderer: function (params) {
            return `
                <div class="ag-chart-tooltip-title" style="background-color: ${
                  params.color
                }">
                    ${params.title}
                </div>
                <div class="tooltip-content--wrapper">
                  <div class="score-info">
                  (${params.datum.percent}%, ${params.datum.value})
                  </div>
                  <div class="doc-info">
                      <p class="doc-info__status-container"> 
                        <span class=${
                          params.datum.active ? "active" : "archived"
                        }></span>
                        <span>${
                          params.datum.active
                            ? "Active Document"
                            : "Archived Document"
                        }</span>
                      </p>
                      <p class="doc-info__version-container">Version ${
                        params.datum.version
                      }</p> 
                  </div>
                </div>
                `;
          },
        },
        labels: {
          value: {
            formatter: (params) =>
              `(${params.datum.percent}%, ${params.datum.value})`,
            style: { fontSize: 10 },
          },
          large: {
            fontSize: 10,
            fontWeight: "normal",
            wrapping: "always",
          },
          medium: {
            fontSize: 10,
            fontWeight: "normal",
            wrapping: "always",
          },
          small: {
            fontSize: 10,
            fontWeight: "normal",
            wrapping: "always",
          },
        },
      },
    ],
  });

  useEffect(() => {
    setOptions({ ...options, data: data as any });
  }, [data]);

  return <AgChartsReact containerStyle={{ padding: 0 }} options={options} />;
};

export default TreeMap;
