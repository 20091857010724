import React from 'react';
import './ModalHeader.scss';
import { close_icon_modal } from 'assets/images';
import IconButton from '../../IconButton/IconButton';

type ModalHeaderProps = {
    children: React.ReactNode;
    onClose?: () => void;
}
const ModalHeader = ({children, onClose}: ModalHeaderProps) => {
    return (
        <div className='modal-header '>
        <h4 className="modal-headline">{children}</h4>
        <IconButton className='modal--header-icon-button' onClick={onClose}/>
    </div>
    );
};

export default ModalHeader;