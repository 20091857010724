import { CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { DocumentDownloadRequest } from "modules/gpo-agent/models/document/download-documentRequest.model";
import { ArchiveDocumentRequest, ArchiveDocumentResponse } from "modules/gpo-agent/models/manage-document-filter/archive-document.model";
import { ExportManagedDocumentDetails } from "modules/gpo-agent/models/manage-document-filter/export-managed-document.model";

const appHeaderConfig = {
    getAllCountries: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.COUNTRY}`, 'GET'),

    getAllDocumentStatuses : () :ApiConfig<any> =>
        new ApiConfig(`${METHOD.DOCUMENTS}/${METHOD.MANAGED_STATUS}`, 'GET'),

    getAllAdmins : () :ApiConfig<any> =>
        new ApiConfig(`${METHOD.ADMIN}`, 'GET'),
    
    archiveDocument : (request:ArchiveDocumentRequest) :ApiConfig<any> =>
        new ApiConfig(`${METHOD.MANAGED_DOCUMENTS}/${METHOD.ARCHIVE}`, 'POST', "", {
            ...request,
      }),
    downloadDocument : (documentGuid:DocumentDownloadRequest) :ApiConfig<any> =>
      new ApiConfig(`${METHOD.MANAGED_DOCUMENTS}/${METHOD.DOWNLOAD}`, 'POST', "", {
          ...documentGuid,
    },undefined,"blob"
    ),
    exportDocument : (request:ExportManagedDocumentDetails) :ApiConfig<any> =>
    new ApiConfig(`${METHOD.MANAGED_DOCUMENTS}/${METHOD.EXPORT}`, 'POST', "", {
        ...request,
    })
};

export default appHeaderConfig;
