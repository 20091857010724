import React, { useState } from "react";
import { DateRangePicker as ReactDateRangePicker } from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import './DateRangePicker.scss';
import { DateRange } from "common/models";
import { ReactComponent as CalendarIcon } from "assets/media/images/ic_date_range.svg";
import "./DateRangePicker.scss";

type DateRangePickerProps = {
  format?: string;
  minDate?: Date;
  maxDate?: Date;
  value: DateRange;
  className?: string;
  label: string;
  isOpen?: boolean;
  hideOnOutsideClick?: boolean;
  customCalendarIcon?: React.ReactElement;
  onChange: (dateRange: DateRange) => void;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
};

const DateRangePicker = ({
  format,
  maxDate,
  value,
  className,
  minDate,
  label,
  isOpen,
  hideOnOutsideClick = true,
  customCalendarIcon,
  onChange,
  onCalendarOpen,
  onCalendarClose,
}: DateRangePickerProps) => {
  const handleChange = (date) => {
    onChange(date as DateRange);
  };
  return (
    <div className="date-picker-form-field date-picker-form-field-date-input date-picker-form-field-focused">
      <label className="date-picker-label">{label}</label>
      <div className="gpo-date-picker-wrapper">
        <ReactDateRangePicker
          calendarAriaLabel="Toggle calendar"
          clearAriaLabel="Clear value"
          isOpen={isOpen}
          dayAriaLabel="Day"
          monthAriaLabel="Month"
          yearAriaLabel="Year"
          nativeInputAriaLabel="Date"
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          onChange={handleChange}
          value={value}
          maxDate={maxDate}
          format={format}
          className={`gpo-date-picker gpo-date-picker-has-value gpo-date-picker-not-focused ${
            className ?? ""
          }`}
          minDate={minDate}
          calendarClassName={"gpo-calendar"}
          calendarIcon={
            customCalendarIcon ? (
              customCalendarIcon
            ) : (
              <CalendarIcon className="gpo-calendar-icon" />
            )
          }
          shouldCloseCalendar={({ reason }) =>
            hideOnOutsideClick ? true : reason !== "outsideAction"
          }
          onCalendarOpen={onCalendarOpen}
          onCalendarClose={onCalendarClose}
        />
      </div>
    </div>

  );
};
export default DateRangePicker;
