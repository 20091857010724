import {
  annotation_dots,
  clear,
  file_download,
  filter,
  left,
  notification_text,
  refreshIcon,
  search,
  star_yellow,
  total_users
} from "assets/media";
import React, { useEffect, useState } from "react";
import UserReviewFilter from "../UserReviewFilter/UserReviewFilter";
import "./QuestionDetails.scss";
import GPOAgGrid from "common/components/custom/GPOAgGrid/GPOAgGrid";
import AvatarCellRenderer from "common/components/custom/AvatarCellRenderer/AvatarCellRenderer";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ProgressBar,
  StarRating
} from "common/components";
import {
  close_icon_modal,
  document_viewer_icon,
  total_dislikes,
  total_likes,
  total_questions
} from "assets/images";
import GridDateCellRenderer from "common/components/custom/GridDateCellRenderer/GridDateCellRenderer";
import DocumentViewerCellRenderer from "common/components/custom/DocumentViewerCellRenderer/DocumentViewerCellRenderer";
import { IChatHistoryMessage } from "modules/gpo-agent/models/chat-history/chat-history-messages.model";
import { ChatMessageType } from "modules/gpo-agent/enums/chat-message-types.enum";
import moment from "moment";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { ChatMessages } from "modules/gpo-agent/enums/chat-messages.enum";
import { DateRange, DateValue } from "common/models/date-range.model";
import { useDebounce, useInterval } from "common/hooks";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import {
  RootState,
  useAppDispatch,
  useAppSelector
} from "common/redux/core/root.reducer";
import QuestionDetailsFilter from "../QuestionDetailsFilter/QuestionDetailsFilter";
import { TABLE_SORT_ORDER } from "common/enums/table-sort-order.enum";
import {
  QuestionDetailsFilters,
  QuestiondetailsFilterBody,
  SortOrder
} from "modules/gpo-agent/models/filter-component/filter-values.model";
import DataGetterCellRenderer from "common/components/custom/DataGetterCellRenderer/DataGetterCellRenderer";
import { bot } from "assets/media/images";
import {
  DocumentViewerCellMapper,
  DocumentViewerPage
} from "common/models/document-viewer-cell-renderer.model";
import {
  IDocumentDetails,
  IDocumentPageDetails
} from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import PDFViewer from "../PDFViewer/PDFViewer";
import { QuestionDetailFilterTypes } from "modules/gpo-agent/enums/question-details-filter.enum";
import { DashboardDataRequest } from "modules/gpo-agent/models/dashboard";
import { dashboardActions } from "modules/gpo-agent/redux/dashboard";
import { ExportTableQuestionDetails } from "modules/gpo-agent/models/export-table-question-details/export-table-question-details.model";
import { QuestionDetailExportSortOrderTypes } from "modules/gpo-agent/enums/question-details-export.enum";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { GridSortModel } from "common/models/grid-sort-model";
import { DateUtil, FileUtil } from "common/utils";
import StatusCellRenderer from "common/components/custom/StatusCellRenderer/StatusCellRenderer";
import { AUTO_REFRESH_INTERVAL_MS } from "common/config/app.config";
const QuestionDetails: React.FC = () => {
  const [showSelectedRowInfo, setShowSelectedRowInfo] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("tab1");
  const [chatHistoryMessages, setChatHistoryMessages] = useState<
    Array<IChatHistoryMessage>
  >([]);
  const [selectedRowInfo, setSelectedRowInfo] = useState<any>();
  const [columnDef, setColumnDef] = useState<Array<any>>([]);

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const DEBOUNCE_TIME_OUT = 1000;
  const [showFilter, setShowFilter] = useState(false);
  const initialFilter: QuestionDetailsFilters = {
    country: [],
    duration: [moment(new Date()).subtract(30, "days")?.startOf('day').toDate(), moment(new Date())?.endOf('day')?.toDate()],
    like: true,
    dislike: true,
    noaction: true,
    documentGuids: []
  };
  const [appliedFilter, setAppliedFilter] = useState<
    QuestionDetailsFilters | undefined
  >(initialFilter);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [filterRequest, setFilterRequest] = useState<
    QuestionDetailsFilters | {}
  >({});
  const [autoRefreshCount, setAutoRefreshCount] = useState(0);
  const debouncedSearchText: string = useDebounce(
    searchText,
    DEBOUNCE_TIME_OUT
  );

  const [documentDetails, setDocumentDetails] = useState<IDocumentDetails>();
  const [likesData, setLikesData] = useState<number>(0);
  const [disLikesData, setDislikesData] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const countryState = useAppSelector(
    (state: RootState) => state.header?.countryResponse?.data
  );
  const dashBoardData = useAppSelector(
    (state: RootState) => state.dashboard.userEngagementData
  );
  const [showExportConfirmation, setShowExportConfirmation] = useState(false);
  const [showExportProgress, setShowExportProgress] = useState(false);
  const exportedQuestionDetailsData = useAppSelector(
    (state: RootState) => state.agents.exportedQuestionDetailsData
  );
  const [gridSortParams, setGridSortParams] = useState<GridSortModel>({
    colId: "createdDate",
    sort: TABLE_SORT_ORDER.DESCENDING
  });
  const [lastUpdatedDatetime, setLastUpdatedDatetime] = useState(DateUtil.getTimeInDDMMMYYYY());

  useEffect(() => {
    dispatch(appHeaderActions.getAllCountries());
  }, []);
  useEffect(() => {
    callCardServices();
  }, [appliedFilter, searchFilter]);

  useEffect(() => {
    if (dashBoardData?.totalLikes?.data) {
      setLikesData(dashBoardData?.totalLikes?.data?.total);
    }
  }, [dashBoardData.totalLikes.data]);
  useEffect(() => {
    if (dashBoardData.totalDislikes.data) {
      setDislikesData(dashBoardData.totalDislikes.data?.total);
    }
  }, [dashBoardData.totalDislikes.data]);
  useEffect(() => {
    if (dashBoardData.totalQuestions.data) {
      setTotalQuestions(dashBoardData.totalQuestions.data?.total);
    }
  }, [dashBoardData.totalQuestions.data]);
  useEffect(() => {
    setSearchFilter(debouncedSearchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    setFilterRequest(appliedFilter ? getFilterRequestBody() : {});
  }, [appliedFilter, searchFilter]);

  useEffect(() => {
    if (exportedQuestionDetailsData) {
      const dataToCSV = exportedQuestionDetailsData;
      const fileName = `Question Details_${moment().format(
        "YYYYMMDDHHmm"
      )}.csv`;
      FileUtil.stringToFile(dataToCSV, fileName, "text/csv");
      setShowExportProgress(false);
      dispatch(agentsActions?.clearExportData());
    }
  }, [exportedQuestionDetailsData]);

  useEffect(() => {
    if (countryState?.code == 200) {
      if (countryState?.countries?.length) {
        const columnDef = [
          {
            field: "question",
            headerName: "USER QUESTION",
            sortable: true,
            unSortIcon: true,
            flex: 2
          },
          {
            field: "response",
            headerName: "CHATBOT RESPONSE",
            sortable: true,
            unSortIcon: true,
            flex: 2
          },
          {
            field: "documents",
            headerName: "SOURCE DOCUMENTS",
            sortable: false,
            unSortIcon: true,
            flex: 2,
            cellRendererFramework: (params) => (
              <DocumentViewerCellRenderer
                documentsArray={params?.value}
                openPDF={setDocumentDetails}
              />
            )
          },
          {
            field: "userName",
            headerName: "USER",
            sortable: true,
            unSortIcon: true,
            flex: 1,
            cellRendererFramework: (params) => (
              <AvatarCellRenderer
                showName={false}
                profileIdField={params?.data?.userGuid}
                displayName={params?.value}
              />
            )
          },
          {
            field: "countryGuid",
            headerName: "COUNTRY",
            sortable: true,
            unSortIcon: true,
            flex: 1,
            cellRendererFramework: (params) => {
              return (
                <DataGetterCellRenderer
                  dataGetter={countryState?.countries}
                  dataValue={params?.value}
                />
              );
            }
          },
          {
            field: "like",
            headerName: "LIKE",
            sortable: true,
            unSortIcon: true,
            flex: 1,
            cellRendererFramework: (params) => (params.value ? "Yes" : "-")
          },
          {
            field: "disLike",
            headerName: "DISLIKE",
            sortable: true,
            unSortIcon: true,
            flex: 1,
            cellRendererFramework: (params) => (params.value ? "Yes" : "-")
          },
          {
            field: "createdDate",
            headerName: "DATE",
            sortable: true,
            unSortIcon: true,
            flex: 1,
            cellRendererFramework: (params) => (
              <GridDateCellRenderer
                dateToConvert={params?.value}
                format={"DD MMM, YYYY"}
              />
            )
          }
        ];
        setColumnDef(columnDef);
      }
    }
  }, [countryState]);

  const callCardServices = () => {
    if (appliedFilter) {
      const startDate = appliedFilter[QuestionDetailFilterTypes.Duration][0]
        ? getISODate(appliedFilter[QuestionDetailFilterTypes.Duration][0])
        : null;
      const endDate = appliedFilter[QuestionDetailFilterTypes.Duration][1]
        ? getISODate(appliedFilter[QuestionDetailFilterTypes.Duration][1])
        : null;

      const request: DashboardDataRequest = {
        filter: {
          countryGuids: appliedFilter[QuestionDetailFilterTypes.Country].map(
            (item) => item.id
          ),
          startDate: startDate ? startDate : null,
          endDate: endDate ? endDate : null
        },
        questionDetailsFilters: {
          documentGuids: appliedFilter[QuestionDetailFilterTypes.Documents].map(
            (item) => item.id
          ),
          messageFeedbackFilter: {
            includeLikes: appliedFilter[QuestionDetailFilterTypes.Like],
            includeDislikes: appliedFilter[QuestionDetailFilterTypes.Dislike],
            includeNoAction: appliedFilter[QuestionDetailFilterTypes.NoAction]
          }
        },
        //searchText:null
        searchText: searchFilter ? searchFilter : null
      };

      if (
        !appliedFilter[QuestionDetailFilterTypes.NoAction] &&
        !appliedFilter[QuestionDetailFilterTypes.Like] &&
        !appliedFilter[QuestionDetailFilterTypes.Dislike]
      ) 
      {
        dispatch(
          dashboardActions.getTotalLikes({ ...request, thumbValue: true })
        );
        dispatch(
          dashboardActions.getTotalDislikes({ ...request, thumbValue: false })
        );
      }
      if (
        appliedFilter[QuestionDetailFilterTypes.NoAction] &&
        !appliedFilter[QuestionDetailFilterTypes.Like] &&
        !appliedFilter[QuestionDetailFilterTypes.Dislike]
      ) 
      {
        setLikesData(0);
        setDislikesData(0);
      } 
      if (appliedFilter[QuestionDetailFilterTypes.Like]) {
        dispatch(
          dashboardActions.getTotalLikes({ ...request, thumbValue: true })
        );
        if (!appliedFilter[QuestionDetailFilterTypes.Dislike]) {
          setDislikesData(0);
        }
      } 
      if (appliedFilter[QuestionDetailFilterTypes.Dislike]) {
        if (!appliedFilter[QuestionDetailFilterTypes.Like]) {
          setLikesData(0);
        }
        dispatch(
          dashboardActions.getTotalDislikes({ ...request, thumbValue: false })
        );
      }

      dispatch(dashboardActions.getTotalQuestions(request));
    }
  };

  const handleApplyFilter = (filter: QuestionDetailsFilters) => {
    //call api
    setAppliedFilter(structuredClone(filter));
    setShowFilter(false);
  };

  const formatDateRange = (date: DateRange) => {
    return (
      moment(date[0]).format("D/M/YYYY") +
      " - " +
      moment(date[1]).format("D/M/YYYY")
    );
  };

  const handleResetFilter = () => {
    setAppliedFilter({
      country: [],
      duration: [null, null],
      like: false,
      dislike: false,
      noaction: false,
      documentGuids: []
    });
  };

  const handleClearSelectedFilter = (
    key: QuestionDetailFilterTypes,
    value: boolean | string | number | undefined
  ) => {
    switch (key) {
      case QuestionDetailFilterTypes.Country:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [QuestionDetailFilterTypes.Country]: prev[
                  QuestionDetailFilterTypes.Country
                ].filter((item) => item.id !== value)
              }
            : prev
        );
        break;

      case QuestionDetailFilterTypes.Duration:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [QuestionDetailFilterTypes.Duration]: [null, null]
              }
            : prev
        );
        break;
      case QuestionDetailFilterTypes.Like:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [QuestionDetailFilterTypes.Like]: false
              }
            : prev
        );
        break;
      case QuestionDetailFilterTypes.Dislike:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [QuestionDetailFilterTypes.Dislike]: false
              }
            : prev
        );
        break;
      case QuestionDetailFilterTypes.NoAction:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [QuestionDetailFilterTypes.NoAction]: false
              }
            : prev
        );
        break;
      case QuestionDetailFilterTypes.Documents:
        setAppliedFilter((prev) =>
          prev
            ? {
                ...prev,
                [QuestionDetailFilterTypes.Documents]: prev[
                  QuestionDetailFilterTypes.Documents
                ].filter((item) => item.id !== value)
              }
            : prev
        );
        break;
    }
  };

  const getFilterElements = (key: string) => {
    switch (key) {
      case QuestionDetailFilterTypes.Country:
        if (appliedFilter && appliedFilter[key].length > 0) {
          return appliedFilter[key].map((item) => (
            <div className="user-review__chip">
              <p>{item.name}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    QuestionDetailFilterTypes.Country,
                    item.id
                  )
                }
              />
            </div>
          ));
        }
        break;
      case QuestionDetailFilterTypes.Duration:
        if (appliedFilter && appliedFilter[key].every((item) => item != null)) {
          return (
            <div className="user-review__chip">
              <p>{formatDateRange(appliedFilter[key])}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    QuestionDetailFilterTypes.Duration,
                    undefined
                  )
                }
              />
            </div>
          );
        }
        break;
      case QuestionDetailFilterTypes.Like:
        if (appliedFilter && appliedFilter[key]) {
          return (
            <div className="user-review__chip">
              <p>Likes</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    QuestionDetailFilterTypes.Like,
                    false
                  )
                }
              />
            </div>
          );
        }
        break;
      case QuestionDetailFilterTypes.Dislike:
        if (appliedFilter && appliedFilter[key]) {
          return (
            <div className="user-review__chip">
              <p>Dislikes</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    QuestionDetailFilterTypes.Dislike,
                    false
                  )
                }
              />
            </div>
          );
        }
        break;
      case QuestionDetailFilterTypes.NoAction:
        if (appliedFilter && appliedFilter[key]) {
          return (
            <div className="user-review__chip">
              <p>No action</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    QuestionDetailFilterTypes.NoAction,
                    false
                  )
                }
              />
            </div>
          );
        }
        break;
      case QuestionDetailFilterTypes.Documents:
        if (appliedFilter && appliedFilter[key].length > 0) {
          return appliedFilter[key].map((item) => (
            <div className="user-review__chip">
              <p>{item.name}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    QuestionDetailFilterTypes.Documents,
                    item.id
                  )
                }
              />
            </div>
          ));
        }
        break;
    }

    return <></>;
  };

  const showSelectedFilter = () => {
    let show = false;
    if (appliedFilter) {
      Object.keys(appliedFilter).map((key) => {
        const value = appliedFilter[key];
        if (
          (key == QuestionDetailFilterTypes.Country && value.length > 0) ||
          (key == QuestionDetailFilterTypes.Duration &&
            value.every((item) => item != null)) ||
          (key == QuestionDetailFilterTypes.Documents && value.length > 0) ||
          (key == QuestionDetailFilterTypes.Like && value) ||
          (key == QuestionDetailFilterTypes.Dislike && value) ||
          (key == QuestionDetailFilterTypes.NoAction && value)
        ) {
          show = true;
        }
      });
    }
    return show;
  };

  const getISODate = (date: DateValue) => {
    if (date) {
      // const utcDate = moment(date).utcOffset(0);
      // utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      // return utcDate.toISOString();
      return date?.toISOString();
    }
  };

  const getFilterRequestBody = () => {
    if (appliedFilter) {
      // const startDate = appliedFilter[QuestionDetailFilterTypes.Duration][0]
      //   ? getISODate(appliedFilter[QuestionDetailFilterTypes.Duration][0])
      //   : null;
      // const endDate = appliedFilter[QuestionDetailFilterTypes.Duration][1]
      //   ? getISODate(appliedFilter[QuestionDetailFilterTypes.Duration][1])
      //   : null;
      const startDate = appliedFilter[QuestionDetailFilterTypes.Duration][0]
        ? appliedFilter[QuestionDetailFilterTypes.Duration][0]?.toISOString()
        : null;
      const endDate = appliedFilter[QuestionDetailFilterTypes.Duration][1]
        ? appliedFilter[QuestionDetailFilterTypes.Duration][1]?.toISOString()
        : null;


      const body: QuestiondetailsFilterBody = {
        filter: {
          countryGuids: appliedFilter[QuestionDetailFilterTypes.Country].map(
            (item) => item.id
          ),
          startDate: startDate ? startDate : null,
          endDate: endDate ? endDate : null
        },
        questionDetailsFilters: {
          //isCommentNotProvided: appliedFilter[QuestionDetailFilterTypes.NotProvided],
          messageFeedbackFilter: {
            includeLikes: appliedFilter[QuestionDetailFilterTypes.Like],
            includeDislikes: appliedFilter[QuestionDetailFilterTypes.Dislike],
            includeNoAction: appliedFilter[QuestionDetailFilterTypes.NoAction]
          },
          documentGuids: appliedFilter[QuestionDetailFilterTypes.Documents].map(
            (item) => item.id
          )
        },
        searchText: searchFilter ? searchFilter : null
      };
      return body;
    } else return {};
  };

  const onRowClicked = (rowData: any, rowIndex: number) => {
    setShowSelectedRowInfo(true);
    setSelectedRowInfo(rowData);
    const activeRow = document.querySelector(
      `.question-details__table .ag-root-wrapper .ag-row.selectedRow`
    );
    if (activeRow) {
      activeRow?.classList?.remove("selectedRow");
    }
    const selectedRow = document.querySelector(
      '.question-details__table .ag-root-wrapper .ag-center-cols-container div.ag-row[row-index="' +
        rowIndex +
        '"]'
    );
    if (selectedRow) {
      selectedRow?.classList?.add("selectedRow");
    }
  };

  const closeSelectedRowModal = () => {
    const activeRow = document.querySelector(
      `.question-details__table .ag-root-wrapper .ag-row.selectedRow`
    );
    if (activeRow) {
      activeRow?.classList?.remove("selectedRow");
    }
    setShowSelectedRowInfo(false);
    setSelectedRowInfo(undefined);
    setActiveTab("tab1");
  };

  const exportTable = () => {
    if (appliedFilter) {
      const startDate = getISODate(
        appliedFilter[QuestionDetailFilterTypes.Duration][0]
      );
      const endDate = getISODate(
        appliedFilter[QuestionDetailFilterTypes.Duration][1]
      );
      const request: ExportTableQuestionDetails = {
        filter: {
          countryGuids: appliedFilter[QuestionDetailFilterTypes.Country].map(
            (item) => item.id
          ),
          startDate: startDate ?? null,
          endDate: endDate ?? null
        },
        questionDetailsFilters: {
          documentGuids: appliedFilter[QuestionDetailFilterTypes.Documents].map(
            (item) => item.id
          ),
          messageFeedbackFilter: {
            includeLikes: appliedFilter[QuestionDetailFilterTypes.Like],
            includeDislikes: appliedFilter[QuestionDetailFilterTypes.Dislike],
            includeNoAction: appliedFilter[QuestionDetailFilterTypes.NoAction]
          }
        },
        searchText: searchFilter ? searchFilter : null,
        sortColumn: gridSortParams.colId,
        sortOrder: SortOrder[gridSortParams.sort],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      dispatch(agentsActions?.exportQuestionDetailsData(request));
      setShowExportProgress(true);
    }
  };

  const handleExport = async () => {
    setShowExportConfirmation(false);
    exportTable();
  };

  const handleExportIconClick = () => {
    if ((appliedFilter && showSelectedFilter()) || searchFilter.length > 0) {
      setShowExportConfirmation(true);
    } else {
      handleExport();
    }
  };

  const handleAutoRefresh = () => {
    setAutoRefreshCount((prev) => prev + 1);
    callCardServices();
  };
    // auto-refresh the data
    const { startInterval } = useInterval(
      handleAutoRefresh,
      AUTO_REFRESH_INTERVAL_MS,
      false
    );

  const handleGridApiSuccess = () =>{
    setLastUpdatedDatetime(DateUtil.getTimeInDDMMMYYYY());
    startInterval();
  }

  return (
    <div className="question-details">
      <div className="question-details__container">
        <div className="question-details__header">
          <div className="question-details__header__left">
            <h2>Question Details</h2>
            <p>{`Last updated on ${lastUpdatedDatetime}`}</p>
          </div>
        </div>

        <div className="question-details__body">
          {showFilter && (
            <QuestionDetailsFilter
              appliedFilters={appliedFilter}
              autoRefreshCount={autoRefreshCount}
              onCloseModal={() => setShowFilter(false)}
              onApply={handleApplyFilter}
              onReset={handleResetFilter}
            />
          )}
          {appliedFilter && showSelectedFilter() && (
            <div className="question-details__selected-filter">
              <div className="question-details__selected-filter__wrapper">
              <p className="question-details__selected-filter__label">
                Selected filters
              </p>
              {Object.keys(appliedFilter).map((key) => getFilterElements(key))}
              <p
                className="question-details__selected-filter__clear"
                onClick={handleResetFilter}
              >
                Clear all
              </p>
              </div>
              <button
                  className="question-details__selected-filter__refresh-btn"
                  onClick={handleAutoRefresh}
                >
                  <img src={refreshIcon} />
                  <p>Refresh</p>
                </button>
            </div>
          )}
          {/* {appliedFilter && showSelectedFilter() && (
          <div className="question-details__selected-filter">
            <p className="question-details__selected-filter__label">
              Selected filters
            </p>
            <div className="question-details__chip">
              <p>Likes</p>
              <img src={clear} />
            </div>
            <p className="question-details__selected-filter__clear">Clear all</p>
          </div>
        )} */}
          {documentDetails && (
            <PDFViewer
              documentDetails={documentDetails}
              onPDFClose={setDocumentDetails}
            />
          )}

          <div className="question-details__action-panel">
            <div className="question-details__action-panel__search">
              <img src={search} />
              <input
                type="text"
                placeholder="Search"
                onChange={(evt) => setSearchText(evt.target.value)}
              />
            </div>
            <div className="question-details__action-panel__right">
              <div
                className="question-details__action-panel__filter-btn"
                onClick={() => setShowFilter(true)}
              >
                <img src={filter} />
                <span>Filter</span>
              </div>
              <button
                className="question-details__action-panel__export-btn"
                onClick={() => handleExportIconClick()}
              >
                <img src={file_download} />
                <p>Export</p>
              </button>
            </div>
          </div>
          <div className="question-details__total-count">
            {/* <div>
                  <img src={total_users} />
                  <p className="question-details__total-count__label">Total Traffic</p>
                  <p>100</p>
            </div> */}
            <div>
              <img src={total_questions} />
              <p className="question-details__total-count__label">
                Total Questions
              </p>
              <p>{totalQuestions}</p>
            </div>
            <div>
              <img src={total_likes} />
              <p className="question-details__total-count__label">
                Total Likes
              </p>
              <p>{likesData}</p>
            </div>
            <div>
              <img src={total_dislikes} />
              <p className="question-details__total-count__label">
                Total Dislikes
              </p>
              <p>{disLikesData}</p>
            </div>
          </div>

          <div className="question-details__table">
            {showSelectedRowInfo && (
              <div className="table-selectedRow-details-wrapper">
                <div className="table-selectedRow-details">
                  <div className="table-selectedRow-details__header">
                    <span>Question Details</span>
                    <img
                      className="header-close"
                      src={close_icon_modal}
                      onClick={(event) => closeSelectedRowModal()}
                    />
                  </div>
                  <div className="table-selectedRow-details__body">
                    <div className="body-tabs">
                      <span
                        className={activeTab === "tab1" ? "active" : ""}
                        onClick={(event) => handleTab("tab1")}
                      >
                        Chat Thread
                      </span>
                      <span
                        className={activeTab === "tab2" ? "active" : ""}
                        onClick={(event) => handleTab("tab2")}
                      >
                        Source Documents (
                        {selectedRowInfo?.documents?.length
                          ? selectedRowInfo?.documents?.length
                          : 0}
                        )
                      </span>
                    </div>
                    <div className="body-tabs-data">
                      {activeTab == "tab1" ? (
                        <div className="body-tabs-data__chatWrapper">
                          <>
                            {selectedRowInfo?.question && (
                              <section className="user">
                                <ProfileImage
                                  profileId={selectedRowInfo?.userGuid}
                                />
                                <div className="chat-data">
                                  <div className="chat-data__header">
                                    <strong>{selectedRowInfo?.userName}</strong>
                                    <span className="header-date">
                                      {moment
                                        .utc(selectedRowInfo?.createdDate)
                                        ?.local()
                                        .format("DD-MM-YYYY")}
                                    </span>
                                  </div>
                                  <div className="chat-data__body">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          selectedRowInfo?.question?.replace(
                                            /\n/g,
                                            "</br>"
                                          )
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </section>
                            )}
                            {selectedRowInfo?.response && (
                              <section className="bot">
                                <img src={bot} />
                                <div className="chat-data">
                                  <div className="chat-data__header">
                                    <strong>GPO ChatBot</strong>
                                    <span className="header-date">
                                      {moment
                                        .utc(
                                          selectedRowInfo?.response
                                            ?.responseCreatedDate
                                        )
                                        ?.local()
                                        .format("DD-MM-YYYY")}
                                    </span>
                                  </div>
                                  <div className="chat-data__body">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          selectedRowInfo?.response?.replace(
                                            /\n/g,
                                            "</br>"
                                          )
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </section>
                            )}
                          </>
                        </div>
                      ) : (
                        <div className="body-tabs-data__documentListWrapper">
                          <div className="documentList-header">
                            <span>SOURCE DOCUMENTS</span>
                            <span>STATUS</span>
                            <span>PAGE NUMBER</span>
                          </div>
                          {selectedRowInfo?.documents?.map(
                            (document: IDocumentDetails) => (
                              <div
                                className="documentList-body"
                                key={document?.documentGuid}
                              >
                                <div className="documentList-body__doc">
                                  <span
                                    title={document?.documentName}
                                    className="doc-name"
                                  >
                                    {document?.documentName}
                                  </span>
                                  <span className="version">
                                    {document?.version?.toUpperCase()}
                                  </span>
                                  {!document?.pages?.map((page:IDocumentPageDetails)=>page?.pageNumber)?.includes(0) && (
                                  <img
                                    className="view-doc"
                                    src={document_viewer_icon}
                                    onClick={() => {
                                      setDocumentDetails(document);
                                    }}
                                  />
                                  )}
                                </div>
                                <div className="documentList-body__status">
                                  <StatusCellRenderer
                                    statusMapper={[
                                      {
                                        value: true,
                                        displayName: "Active",
                                        color: "#34C768"
                                      },
                                      {
                                        value: false,
                                        displayName: "Archived",
                                        color: "#C4C4CD"
                                      }
                                    ]}
                                    statusValue={document?.active}
                                  />
                                </div>
                                <p
                                  title={document?.pages
                                    ?.map(
                                      (page: IDocumentPageDetails) =>
                                        page?.pageNumber
                                    )
                                    ?.toString()}
                                  className="documentList-body__page"
                                >
                                  {document?.pages
                                    ?.map(
                                      (page: IDocumentPageDetails) =>
                                        page?.pageNumber
                                    )
                                    ?.toString()}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <GPOAgGrid
              method="POST"
              url={"QuestionDetails"}
              paginationSize={10}
              columnDefinitions={columnDef}
              requestBody={filterRequest}
              responsePropertyKey={"questionDetails"}
              searchFilter={searchFilter}
              payloadType="body"
              initialSortModel={gridSortParams}
              onRowClicked={onRowClicked}
              onSort={setGridSortParams}
              refreshGrid={autoRefreshCount}
              onGridApiSuccess={handleGridApiSuccess}
            />
          </div>
        </div>
      </div>
      {showExportConfirmation && (
        <Modal>
          <ModalHeader onClose={() => setShowExportConfirmation(false)}>
            Export Confirmation
          </ModalHeader>
          <ModalBody>
            The export file is based on the filters applied. Do you want to
            continue ?
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={handleExport}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowExportConfirmation(false)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showExportProgress && (
        <Modal>
          <ModalBody className="user-review__export-progress">
            <ProgressBar label="Export is being generated. Please wait before taking any other action..." />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
export default QuestionDetails;
