import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { RootState } from "common/redux/core/root.reducer";
import { UserSigningStatus } from "common/enums/user.enum";
import Logout from "Layout/Logout/Logout";
import MsalAuthProvider from "common/components/custom/MsalAuthProvider";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Layout from "Layout/Layout";
import SessionTimeout from "Layout/SessionTimeout/SessionTimeout";

const App: FC = () => {
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (userState.isSigned === UserSigningStatus.SIGNED_OUT) {
      if (userState.isSessionTimedOut) {
        navigate("/session-timeout");
      } else {
        navigate("/logout");
      }
    }
  }, [userState.isSigned]);

  return (
    <>
      <MsalAuthProvider>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/session-timeout" element={<SessionTimeout />} />
          <Route
            path="/"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={{
                  scopes: ["User.Read"],
                }}
              >
                <Layout />
              </MsalAuthenticationTemplate>
            }
          />
        </Routes>
      </MsalAuthProvider>
    </>
  );
};

export default App;
