import { useEffect } from "react";

type fn = () => void;
let intervalId: NodeJS.Timeout | null = null;

const useInterval = (fn: fn, delay: number, startOnMount: boolean = true) => {
  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      const newIntervalId = setInterval(fn, delay);
      intervalId = newIntervalId;
    }
  };

  const startInterval = () =>{
    if (intervalId) {
      clearInterval(intervalId);
    }
    const newIntervalId = setInterval(fn, delay);
    intervalId = newIntervalId;
  }

  useEffect(() => {
    if (startOnMount && delay > 0 && !intervalId) {
      startInterval();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };
  }, []);


  return { startInterval, resetInterval };
};

export default useInterval;
