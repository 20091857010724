import React from 'react';
import Dropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import './FileUploader.scss';
import { CloudUpload } from 'assets/media';

type FileUploaderProps = {
    onDrop: (acceptedFiles: File[]) => void;
    maxFiles?: number;
    maxSize?: number;
    multiple?: boolean;
    accept?: Accept;
    errors?: string[];
    onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
    onFileDialogCancel?: () => void;
};
const FileUploader = ({
    multiple,
    maxFiles,
    accept,
    errors,
    maxSize,
    onDrop,
    onDropRejected,
    onFileDialogCancel
}: FileUploaderProps) => {
    return (
        <Dropzone
            onDrop={onDrop}
            maxFiles={maxFiles}
            maxSize={maxSize}
            multiple={multiple}
            accept={accept}
            onDropRejected={onDropRejected}
            onFileDialogCancel={onFileDialogCancel}>
            {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps()}
                    className={`file-uploader ${errors && errors.length > 0 ? 'invalid' : ''}`}
                    data-file-uploader="true"
                    data-testid="file-uploader-wrapper">
                    <button className="dropzone-container" tabIndex={0}>
                        <input {...getInputProps()} />
                        <span className="file-uploader-label" id="my-id-label">
                            <span className="icon">
                                <CloudUpload />
                            </span>
                            <span className="file-uploader-label-text">
                                Drag and drop a document or <b>browse</b>
                            </span>
                        </span>
                    </button>
                    {errors &&
                        errors.map((error) => <p className="file-uploader__error">{error}</p>)}
                </div>
            )}
        </Dropzone>
    );
};

export default FileUploader;
