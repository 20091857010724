import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ProfileImage from "../ProfileImage/ProfileImage";
import './AvatarCellRenderer.scss';

interface AvatarCellRendererProps {
    profileIdField:string;
    displayName:string;
    emailID?:string;
    showName?:boolean;
  }
  
  const AvatarCellRenderer: FC<AvatarCellRendererProps> = ({
    profileIdField,
    displayName,
    emailID,
    showName=true
  }) => {
    return profileIdField && displayName ?(
           <div className="avatar-cell-renderer" title={showName?displayName:""}>
            <ProfileImage profileId={profileIdField} hoverValue={!showName?displayName:emailID?emailID:""}></ProfileImage>
            {showName && (
              <span>{displayName}</span>
            )}           
           </div>
    ):<></>
  }

  export default AvatarCellRenderer;