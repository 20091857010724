import { FC } from "react";
import "./UserFeedBackSkeletonLoader.scss";

const UserFeedBackSkeletonLoader: FC = () => {
  return (
    <div className="user-feedback-loader">
      <div className="user-feedback-loader__container">
        <div className="user-feedback-loader__container--header">
          <p className="label"></p>
          <p className="content-line-1"></p>
          <p className="content-line-2"></p>
          <div className="rating-row__wrapper">
            {[...Array(3)].map((item, index) => {
              return <p className="rating-row"></p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFeedBackSkeletonLoader;
