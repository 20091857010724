import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ProfileImage from "../ProfileImage/ProfileImage";
import './DataGetterCellRenderer.scss';
import { DataGetterCellRendererMapper } from "common/models/data-cell-renderer.model";

interface DataGetterCellRendererProps {
    dataGetter:Array<any>;
    dataValue:string;
  }
  
  const DataGetterCellRenderer: FC<DataGetterCellRendererProps> = ({
    dataGetter,
    dataValue
  }) => {
    const [currentData, setCurrentData] = useState<DataGetterCellRendererMapper>();
    useEffect(()=>{
        if(dataGetter?.length && dataValue){
                const dropdownData:Array<DataGetterCellRendererMapper>=[];
                dataGetter?.map((country:any)=>{
                 const dropdownoption:DataGetterCellRendererMapper={
                   guid: country?.guidCode,
                   value: country?.name
                 };
                 dropdownData?.push(dropdownoption);
            });
             
            let selectedData = dropdownData.filter((status:DataGetterCellRendererMapper) => status.guid == dataValue );  
            if(selectedData){
                setCurrentData(selectedData[0]);
            } 
        }
    },[dataValue,dataGetter])
    return(
        <div className="data-getter-cell-renderer">
             <span title={currentData?.value} className="data-getter-cell-renderer__name">{currentData?.value}</span>
        </div>
    )
  }

  export default DataGetterCellRenderer;