import { ApiStatus } from "common/enums";
import { TabType } from "common/enums/tab-types.enum";
import { ApiResponse } from "common/models";
import { ICountryResponse } from "modules/gpo-agent/models/country-component/country-response.model";
import { ArchiveDocumentResponse } from "modules/gpo-agent/models/manage-document-filter/archive-document.model";
import { DocumentAdminResponse } from "modules/gpo-agent/models/manage-document-filter/document-admins.model";
import { DocumentStatusesResponse } from "modules/gpo-agent/models/manage-document-filter/document-status.model";

interface IAppHeaderState {
  tabType: TabType;
  isNavBarTabChange: boolean;
  countryResponse: ApiResponse<ICountryResponse>;
  documentAdminsResponse:ApiResponse<DocumentAdminResponse>;
  documentStatusesResponse:ApiResponse<DocumentStatusesResponse>;
  archiveDocumentState:ArchiveDocumentResponse|undefined;
  downloadedFile:any;
  exportedManagedDocumentDetails:any;
}

const initialAppHeaderState: IAppHeaderState = {
  tabType: TabType.ACTIVE_SESSION,
  isNavBarTabChange: false,
  countryResponse: {
    data: {
      countries: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  documentAdminsResponse: {
    data: {
      admins: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  documentStatusesResponse: {
    data: {
      documentStatuses: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  archiveDocumentState:undefined,
  downloadedFile:undefined,
  exportedManagedDocumentDetails:undefined
};

export { initialAppHeaderState };
