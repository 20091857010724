import { ApiResult, IGraphUser } from "common/models";
import { RequestAccessModel } from "common/models/request-access.model";
import { GraphClient } from "common/services/graph-client.service";
import { HttpClient } from "common/services/http-client.service";
import userApiConfig from "./user.config";


export class UserService extends HttpClient {
    public static async getUserProfile(): Promise<ApiResult<IGraphUser>> {
        return await GraphClient.getCurrentUserProfile();
    }

    public static async getUserImage(): Promise<ApiResult<Blob>> {
        return await GraphClient.getCurrentUserAvatar();
    }

    public static async getUserAvatar(userGuid:string): Promise<ApiResult<Blob>> {
        return await GraphClient.getUserAvatar(userGuid);
    }
    public static async requestAccess(requestDetails:RequestAccessModel): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(userApiConfig.requestAccess(requestDetails));
    }
    public static async logoutUser():Promise<ApiResult<any>>{
        return await this.invokeApi<any>(userApiConfig.logoutUser());
    }
}
