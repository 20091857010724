import moment from "moment";

const getTimeInDDMMMYYYY = () => {
  const date = moment().format("Do MMM YYYY, h:mm A");
  return date;
};

export default {
  getTimeInDDMMMYYYY,
};
