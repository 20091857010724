import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { session_timeout_dark } from "assets/images";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import { logo } from "assets/media/images";
import "./SessionTimeout.scss";

const SessionTimeout: FC = ({}) => {
  const userState = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    window.history.pushState(null, "null", window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, "null", window.location.href);
    };
  }, []);

  const navigateToLogin = () => {
    window.location.replace(window.location.origin);
  };

  return (
    <div className="session-timeout-container">
      <div className="session-timeout-container__header">
        <img src={logo} />
        <span>
          ChatBot by <strong>GPO</strong>
        </span>
      </div>
      <div className="session-timeout-container__content">
        <div className="content-wrapper">
          <div className="content-wrapper__logo">
            <img className="logo-image" src={session_timeout_dark} />
          </div>
          <div className="content-wrapper__message">
            <div className="message-title">
              <span>Your session has timed out!</span>
            </div>
            <div className="message-body">
              <span>Click below to login again</span>
            </div>
          </div>
          <div className="content-wrapper__action">
            <button onClick={() => navigateToLogin()}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionTimeout;
