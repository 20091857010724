const stringToFile = (fileToSave: any, name: string, type: string) => {
    const file = new Blob([fileToSave], { type });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = name;
    element.click();
    element.remove();
  };

  const getFileSizeFormat = (size: number) => {
    let result = "";
    if (size < 1000) {
      result = size + "bytes";
    } else if (size < 1000 * 1000) {
      result = Math.round(size / 1000) + "KB";
    } else if (size < 1000 * 1000 * 1000) {
      result = Math.round(size / 1000 / 1000) + "MB";
    } else {
      result = Math.round(size / 1000 / 1000 / 1000) + "GB";
    }
    return result;
  };
  
  export default {
    stringToFile,
    getFileSizeFormat
  };
  