import { FC } from "react";
import "./PieLabel.scss";

type PieLabelProps = {
  label?: string;
  value?: number;
};

const PieLabel: FC<PieLabelProps> = ({ label, value }: PieLabelProps) => {
  return (
    <>
      <text
        className="label"
        x={65}
        y={58}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#747480"
      >
        {label}
      </text>

      <text
        className="value"
        x={65}
        y={75}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="white"
      >
        {value}%
      </text>
    </>
  );
};

export default PieLabel;
