import React, { useState, useEffect, useRef, FC } from "react";
import ReactDOM from "react-dom";
import "./Tooltip.scss";

type TooltipProps = {
  text: string;
  children: React.ReactNode;
};

const Tooltip: FC<TooltipProps> = ({ text, children }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const leftOffset = 15;

  useEffect(() => {
    const showTooltip = () => {
      const triggerElement = triggerRef.current;
      if (triggerElement) {
        const { top, left } = triggerElement.getBoundingClientRect();
        setPosition({ top: top, left: left + leftOffset });
        setIsVisible(true);
      }
    };

    const hideTooltip = () => {
      setIsVisible(false);
    };

    const triggerElement = triggerRef.current;
    if (triggerElement) {
      triggerElement.addEventListener("mouseenter", showTooltip);
      triggerElement.addEventListener("mouseleave", hideTooltip);
    }

    return () => {
      if (triggerElement) {
        triggerElement.removeEventListener("mouseenter", showTooltip);
        triggerElement.removeEventListener("mouseleave", hideTooltip);
      }
    };
  }, []);

  return (
    <>
      <div className="tooltip-trigger-container" ref={triggerRef}>
        {children}
      </div>
      {isVisible &&
        ReactDOM.createPortal(
          <div
            className="tooltip-text"
            ref={tooltipRef}
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
          >
            {text}
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
