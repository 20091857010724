import React from "react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import './MoreActionsDocumentCellRenderer.scss';
import { GridService } from "common/services/table-service";
import { add_version_icon, archive_grid_icon, download_grid_icon, more_action_icon } from "assets/images";

interface MoreActionsDocumentCellRendererProps {
    rowData:any;
    onActionClicked: (event:any,rowData:any,actionName:string) => void;
  }
  
  const MoreActionsDocumentCellRenderer: FC<MoreActionsDocumentCellRendererProps> = ({
    rowData,
    onActionClicked,
  }) => {
    const [showDropdown,setShowDropdown] = useState<boolean>(false);
    

   const handleMoreActionClick = (event) =>{
    onActionClicked(event,rowData,"action");
    setShowDropdown(!showDropdown)
   }

    return rowData ?(
        <>
        <div className="more-action-cell-renderer">
            <img src={more_action_icon} onClick={(event)=>{handleMoreActionClick(event)}}/>
        </div>
                    {/* {showDropdown && (
                        <div className="add-actions">
                          <button className="add-actions__version" >
                            <img src={add_version_icon}/>
                            <span>Add New Version</span>
                          </button>
                          <button className="add-actions__download" >
                            <img src={download_grid_icon}/>
                            <span>Download</span>
                          </button>
                          <button className="add-actions__archive" >
                            <img src={archive_grid_icon}/>
                            <span>Archive</span>
                          </button>
                        </div>
                    )} */}
        </>
    ):<></>
  }

  export default MoreActionsDocumentCellRenderer