import React from 'react';
import './FileUploaderItem.scss';
import { close_white, ic_description } from 'assets/media';
import { FileUtil } from 'common/utils';

type FileUploaderItemProps = {
    fileName: string;
    fileSize: number;
    onRemove: () => void;
};
const FileUploaderItem = ({ fileName, fileSize, onRemove }: FileUploaderItemProps) => {
    return (
        <div className="file-uploader-item">
            <div className="file-uploader-item__wrapper">
                <div className="file-uploader-item__left">
                    <img src={ic_description} />
                    <span title={fileName}>{fileName}</span>
                </div>
                <div className="file-uploader-item__right">
                    <span>{FileUtil.getFileSizeFormat(fileSize)}</span>
                    <img src={close_white} onClick={onRemove} />
                </div>
            </div>
        </div>
    );
};

export default FileUploaderItem;
