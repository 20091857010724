import { DATE_FORMAT_MONTHS_SHORT } from "common/config/date-format-months_short.config";
import {
  COUNTRY_COLORS_BY_USER_COUNT,
  SENTIMENT_DISTRIBUTION_COLORS,
  HAND_OFF_RATE_COLORS,
  RESOLVED_RATE_COLORS,
  TOP_5_SOURCE_DOCS_COLORS,
} from "common/config/metric-dashboard.config";
import {
  AvgTimeLineData,
  AvgTimeToResolveDataResponse,
  BarData,
  UnresolvedRateResponse,
  UnresolvedRateLineData,
  PieData,
  ResolvedDataResponse,
  ResolvedRateLineData,
  SentimentDistDataResponse,
  Top5SourceDocsDataResponse,
  TrafficDataForMapResponse,
  TreeMapData,
  UserRatings,
  UserRatingsResponse,
} from "modules/gpo-agent/models/dashboard";

export const useDashboard = () => {
  const transformResolvedRatePieData = (
    resolvedRateData: ResolvedDataResponse
  ) => {
    const res: PieData[] = [
      {
        name: "Resolved rate",
        value: Number(resolvedRateData.resolvedRate),
        color: RESOLVED_RATE_COLORS.resolved,
      },
      {
        name: "Unresolved rate",
        value: Number(resolvedRateData.unresolvedRate),
        color: RESOLVED_RATE_COLORS.unresolved,
      },
    ];
    return res;
  };

  const transformResolvedRateLineData = (
    resolvedRateData: ResolvedDataResponse
  ) => {
    const res: ResolvedRateLineData[] = [];
    resolvedRateData.resolvedQuestionsByDay?.forEach((item, index) => {
      const obj = {
        name: `${item.day} ${DATE_FORMAT_MONTHS_SHORT[item.month]}`,
        resolved: Number(item.ratePercentage),
        unresolved: Number(
          resolvedRateData.totalQuestionsByDay?.[index].ratePercentage
        ),
      };
      res.push(obj);
    });
    return res;
  };

  const transformUnresolvedRatePieData = (
    handOffData: UnresolvedRateResponse
  ) => {
    const res: PieData[] = [
      {
        name: "Hand off rate",
        value: Number(handOffData.handOffRate),
        color: HAND_OFF_RATE_COLORS.sentToAgent,
      },
      {
        name: "Bot resolve rate",
        value: Number(handOffData.botResolvedRate),
        color: HAND_OFF_RATE_COLORS.botResolvedRate,
      },
    ];
    return res;
  };

  const transformUnresolvedRateLineData = (
    handOffData: UnresolvedRateResponse
  ) => {
    const res: UnresolvedRateLineData[] = [];
    handOffData.handsOffByDay?.forEach((item, index) => {
      const obj = {
        name: `${item.day} ${DATE_FORMAT_MONTHS_SHORT[item.month]}`,
        rate: item.rateCount,
      };
      res.push(obj);
    });
    return res;
  };

  const transformAvgTimeMeterData = (
    avgTimeData: AvgTimeToResolveDataResponse
  ) => {
    const res: PieData = {
      name: "Avg time",
      value: Number(avgTimeData.averageTimeToResolve?.toFixed(2)),
    };
    return res;
  };

  const transformAvgTimeLineData = (
    avgTimeData: AvgTimeToResolveDataResponse
  ) => {
    const res: AvgTimeLineData[] = [];
    avgTimeData.averageTimeToResolveByDay?.forEach((item, index) => {
      const obj = {
        name: `${item.day} ${DATE_FORMAT_MONTHS_SHORT[item.month]}`,
        avgTime: item.averageTimeToResolve,
      };
      res.push(obj);
    });
    return res;
  };

  const transformSentimentDistPieData = (
    sentimentDistData: SentimentDistDataResponse
  ) => {
    const res: PieData[] = [
      {
        name: "Likes",
        value: Number(sentimentDistData.likes),
        color: SENTIMENT_DISTRIBUTION_COLORS.like,
      },
      {
        name: "Dislikes",
        value: Number(sentimentDistData.unlikes),
        color: SENTIMENT_DISTRIBUTION_COLORS.unlike,
      },
      {
        name: "No Action",
        value: Number(sentimentDistData.noAction),
        color: SENTIMENT_DISTRIBUTION_COLORS.noAction,
      },
    ];
    return res;
  };

  const transformSentimentDistBarData = (
    sentimentDistData: SentimentDistDataResponse
  ) => {
    const res: BarData[] = [];
    sentimentDistData.sentimentByDay?.forEach((item, index) => {
      const obj = {
        name: `${item.day} ${DATE_FORMAT_MONTHS_SHORT[item.month]}`,
        likes: Number(item.likes),
        unlikes: Number(item.unlikes),
        noAction: Number(item.noActions),
      };
      res.push(obj);
    });
    return res;
  };

  const transformUserRatingsData = (userRatingsData: UserRatingsResponse) => {
    const dataObj: UserRatings = {
      averageRating: 0,
      totalRatings: 0,
      ratingsPerStar: [],
    };
    const res: any[] = [];

    [...Array(5)].forEach((item, index) => {
      const starData = userRatingsData.feedbacks.find(
        (el) => el.rate === index + 1
      );
      const data = starData?.rate
        ? {
            starNum: starData.rate,
            ratingPercent: starData.percentage.toFixed(2),
            ratingCount: starData.count,
          }
        : {
            starNum: index + 1,
            ratingPercent: 0,
            ratingCount: 0,
          };
      res.unshift(data);
    });

    dataObj.averageRating = Number(userRatingsData.average.toFixed(1));
    dataObj.totalRatings = userRatingsData.count;
    dataObj.ratingsPerStar = res;
    return dataObj;
  };

  const transformTopSourceDocsData = (
    top5SourceDocsData: Top5SourceDocsDataResponse
  ) => {
    const dataObj: TreeMapData = {
      name: "Documents",
      children: [],
    };

    top5SourceDocsData.top5SourceDocuments?.forEach((doc, index) => {
      const obj = {
        docName: `${doc.rank}\n\n ${doc.documentName}`,
        percent: Number(doc.percent),
        value: doc.value,
        color: TOP_5_SOURCE_DOCS_COLORS[doc.rank] as string,
        active: doc.active,
        version: doc.version,
      };
      dataObj.children.push(obj);
    });

    return dataObj;
  };

  const getFills = () => {
    const fills = {};

    COUNTRY_COLORS_BY_USER_COUNT.forEach((range) => {
      fills[range.color] = range.color;
    });

    return fills;
  };

  const getColor = (count: number) => {
    let color = "#C4C4CD"; // default color

    COUNTRY_COLORS_BY_USER_COUNT.forEach((range) => {
      if (count >= range.min && count <= range.max) {
        color = range.color;
      }
    });

    return color;
  };

  const transformTrafficDataForMap = (
    trafficDataForMap: TrafficDataForMapResponse
  ) => {
    const dataObj = {};

    trafficDataForMap.trafficDistributionMaps?.forEach((countryData) => {
      dataObj[countryData.isoAlpha3] = {
        fillKey: getColor(countryData.count),
        ...countryData,
      };
    });

    return dataObj;
  };

  return {
    transformResolvedRatePieData,
    transformResolvedRateLineData,
    transformUnresolvedRatePieData,
    transformUnresolvedRateLineData,
    transformAvgTimeMeterData,
    transformAvgTimeLineData,
    transformSentimentDistPieData,
    transformSentimentDistBarData,
    transformUserRatingsData,
    transformTopSourceDocsData,
    getFills,
    getColor,
    transformTrafficDataForMap,
  };
};
