import { CONTROLLER } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import {
  DashboardDataRequest,
  OpinionDataRequest,
} from "modules/gpo-agent/models/dashboard";
import { UserReviewFilterBody } from "modules/gpo-agent/models";

const dashboardConfig = {
  getResolvedRateData: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.RESOLVED_RATE}`, "POST", "", {
      ...request,
    }),

  getUnresolvedRateData: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.HAND_OFF_RATE}`, "POST", "", {
      ...request,
    }),

  getAvgTimeToResolveData: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.AVG_TIME_RES}`, "POST", "", {
      ...request,
    }),

  getSentimentDistData: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.SENTIMENT_DIST_RATE}`, "POST", "", {
      ...request,
    }),

  getUserRatingsData: (
    request: DashboardDataRequest | UserReviewFilterBody
  ): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.USER_FEEDBACKS}`, "POST", "", {
      ...request,
    }),

  getTotalTraffic: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.TOTAL_TRAFFIC}`, "POST", "", {
      ...request,
    }),

  getTotalLikes: (request: OpinionDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.OPINION}`, "POST", "", {
      ...request,
    }),

  getTotalDislikes: (request: OpinionDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.OPINION}`, "POST", "", {
      ...request,
    }),

  getTotalConversations: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.TOTAL_CONVERSATION}`, "POST", "", {
      ...request,
    }),

  getTotalQuestions: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.TOTAL_QUESTIONS}`, "POST", "", {
      ...request,
    }),

  getEngagedUsers: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.TOTAL_ENGAGED_USERS}`, "POST", "", {
      ...request,
    }),

  getTop5SourceDocs: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.TOP_SOURCE_DOCS}`, "POST", "", {
      ...request,
    }),

  getTrafficDataForMaps: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.TRAFFIC_FOR_MAP}`, "POST", "", {
      ...request,
    }),

  export: (request: DashboardDataRequest): ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.EXPORT_METRICS}`, "POST", "", request),
};

export default dashboardConfig;
