import { ApiResult } from "common/models/api-service/api-result.model";
import { HttpClient } from "common/services/http-client.service";
import dashboardConfig from "./dashboard.config";
import {
  AvgTimeToResolveDataResponse,
  DashboardDataRequest,
  SentimentDistDataResponse,
  UnresolvedRateResponse,
  OpinionDataRequest,
  ResolvedDataResponse,
  TrafficDataForMapResponse,
  UserEngFieldResponse,
  UserRatingsResponse,
} from "modules/gpo-agent/models/dashboard";
import { UserReviewFilterBody } from "modules/gpo-agent/models";

export class DashboardService extends HttpClient {
  static async getResolvedRateData(
    request: DashboardDataRequest
  ): Promise<ApiResult<ResolvedDataResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getResolvedRateData(request));
  }

  static async getUnresolvedRateData(
    request: DashboardDataRequest
  ): Promise<ApiResult<UnresolvedRateResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getUnresolvedRateData(request));
  }

  static async getAvgTimeToResolveData(
    request: DashboardDataRequest
  ): Promise<ApiResult<AvgTimeToResolveDataResponse> | undefined> {
    return await this.invokeApi(
      dashboardConfig.getAvgTimeToResolveData(request)
    );
  }

  static async getSentimentDistData(
    request: DashboardDataRequest
  ): Promise<ApiResult<SentimentDistDataResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getSentimentDistData(request));
  }

  static async getUserRatingsData(
    request: DashboardDataRequest | UserReviewFilterBody
  ): Promise<ApiResult<UserRatingsResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getUserRatingsData(request));
  }

  static async getTotalTraffic(
    request: DashboardDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTotalTraffic(request));
  }

  static async getTotalLikes(
    request: OpinionDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTotalLikes(request));
  }

  static async getTotalDislikes(
    request: OpinionDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTotalDislikes(request));
  }

  static async getTotalConversations(
    request: DashboardDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTotalConversations(request));
  }

  static async getTotalQuestions(
    request: DashboardDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTotalQuestions(request));
  }

  static async getEngagedUsers(
    request: DashboardDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getEngagedUsers(request));
  }

  static async getTop5SourceDocs(
    request: DashboardDataRequest
  ): Promise<ApiResult<UserEngFieldResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTop5SourceDocs(request));
  }

  static async getTrafficDataForMaps(
    request: DashboardDataRequest
  ): Promise<ApiResult<TrafficDataForMapResponse> | undefined> {
    return await this.invokeApi(dashboardConfig.getTrafficDataForMaps(request));
  }

  static async export(request: DashboardDataRequest): Promise<ApiResult<any>> {
    return await this.invokeApi<any>(dashboardConfig.export(request));
  }
}
