import { filled_star, half_filled_star, empty_star } from "assets/media";
import "./Rating.scss";

const Rating = ({ value }) => {
  const filledStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;

  const starIcons: any[] = [];

  // Create filled stars
  for (let i = 0; i < filledStars; i++) {
    starIcons.push(
      <span key={i} className="star">
        <img src={filled_star} />
      </span>
    );
  }

  // Add half-filled star if needed
  if (hasHalfStar) {
    starIcons.push(
      <span key="half" className="star half">
        <img src={half_filled_star} />
      </span>
    );
  }

  // Fill the remaining stars with empty stars
  while (starIcons.length < 5) {
    starIcons.push(
      <span key={starIcons.length} className="star empty">
        <img src={empty_star} />
      </span>
    );
  }

  return <div className="rating">{starIcons}</div>;
};

export default Rating;
