import "./TreeMapSkeletonLoader.scss";

const TreeMapSkeletonLoader = () => {
  return (
    <div className="tree-skelton-loader">
      <div className="tree-skelton-loader__container">
        <div className="tree-skelton-loader__container--body">
          <div className="tree-square"></div>
          <div className="col">
            <div className="tree-square"></div>
            <div className="tree-square"></div>
          </div>
          <div className="col">
            <div className="tree-square"></div>
            <div className="tree-square"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeMapSkeletonLoader;
