import React, { useEffect, useRef, useState } from "react";
import "./PaginationSelect.scss";
import { expand_more } from "assets/media";
import { SelectOptions } from "common/models";

type PaginationSelectProp = {
  className?: string;
  data: SelectOptions[];
  defaultOption?: SelectOptions;
  onChange: (option: SelectOptions ) => void;
};
const PaginationSelect = ({
  className,
  data,
  defaultOption,
  onChange,
}: PaginationSelectProp) => {
  const selectRef: any = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectOptions>({id:'',name:''});

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (defaultOption) {
      setSelectedOptions(defaultOption);
    }
  }, [defaultOption]);

  useEffect(() => {
    if(selectedOptions) onChange(selectedOptions);
  }, [selectedOptions]);

  const handleClickOutside = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSelect = (option: SelectOptions) => {
      onChange(option);
      setIsOpen(false);
      setSelectedOptions(option);    
  };

  return (
    <div
      className={`pagination-select__form-field ${className ? className : ""}`}
      ref={selectRef}
    >
      <div className="pagination-select__container">
        <button
          className="pagination-select__trigger"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div
            className="pagination-select__input-text"
            title={selectedOptions.name}
          >
            {selectedOptions.name}
          </div>
          <img
            className={`pagination-select__arrow ${isOpen ? "pagination-select__arrow--open" : ""}`}
            src={expand_more}
            alt="arrow"
          />
        </button>
        {isOpen && (
          <div className="pagination-select__options-wrapper">
            <div className="pagination-select__options">
              {data.map((item, index) => (
                <div
                  className="pagination-select__option"
                  key={index}
                  onClick={() => handleSelect(item)}
                >
                  <label htmlFor={item.id}>{item.name}</label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationSelect;
