import { put, takeLatest } from 'redux-saga/effects';
import { UserManagementService } from './user-management.service';
import { userManagementActions } from './index';
import { ApiResult } from 'common/models';

// const getUserProfile = function* () {
//     const profile: ApiResult<IGraphUser> = yield UserService.getUserProfile();
//     yield put({
//         type: userActions.getUserProfileSuccess.type,
//         payload: profile.value
//     });
// };

// const getUserProfileWatch = function* () {
//     yield takeLatest(userActions.getUserProfile.type, getUserProfile);
// };

const getAllGraphUsers = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
    yield UserManagementService.getAllGraphUsers(action?.payload);
    if (hasErrors) {
        yield put({
            type: userManagementActions.getAllGraphUsersFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: userManagementActions.getAllGraphUsersSuccess.type,
            payload: value?.value
        });
    }
};

const getAllGraphUsersWatch = function* () {
    yield takeLatest(userManagementActions.getAllGraphUsers.type, getAllGraphUsers);
};

const addNewUsers = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield UserManagementService.addNewUser(action?.payload);

    if (hasErrors) {
        yield put({
            type: userManagementActions.addNewUsersFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: userManagementActions.addNewUsersSuccess.type,
            payload: value
        });
    }
};

const addNewUsersWatch = function* () {
    yield takeLatest(userManagementActions.addNewUsers.type, addNewUsers);
};

const approveRejectUser = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield UserManagementService.approveRejectUser(action?.payload);

    if (hasErrors) {
        yield put({
            type: userManagementActions.approveRejectUserFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: userManagementActions.approveRejectUserSuccess.type,
            payload: value
        });
    }
};

const approveRejectUserWatch = function* () {
    yield takeLatest(userManagementActions.approveRejectUser.type, approveRejectUser);
};

export { getAllGraphUsersWatch,addNewUsersWatch,approveRejectUserWatch };
