import {
    Modal,
    Button,
    ModalHeader,
    ModalBody,
    MultiSelect,
    FileUploader,
    FileUploaderItem,
    ModalFooter
} from 'common/components';
import Loader from 'common/components/base/Loader/Loader';
import { ApiStatus } from 'common/enums';
import { SelectOptions } from 'common/models';
import { RootState, useAppDispatch, useAppSelector } from 'common/redux/core/root.reducer';
import { AddDocumentForm } from 'modules/gpo-agent/models';
import { agentChatHistoryActions } from 'modules/gpo-agent/redux/agent-chat-history';
import React, { useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import './AddDocumentVersion.scss';

type AddDocumentVersionProps = {
    existingDocument: { id:string, name:string}
    onClose: () => void;
};
const AddDocumentVersion = ({existingDocument, onClose }: AddDocumentVersionProps) => {
    const dispatch = useAppDispatch();
    const FileMaxSize = 104857600; //100 MB
    const FileErrorMessages = {
        'file-invalid-type': 'File type must be PDF',
        'file-too-large': 'File is larger than 100 MB'
    };
    const {status, error} = useAppSelector(
        (state) => state.agentChatHistory.uploadDocument
      );
    const countryResponse = useAppSelector((state) => state.header.countryResponse);
    const [countryData, setCountryData] = useState<SelectOptions[]>([]);

    const [form, setForm] = useState<AddDocumentForm>({
        country: [],
        document: null
    });

    const [validationError, setValidationError] = useState<{
        document: string[];
        country: string;
        existingDoc: string;
    }>({
        country: '',
        document: [],
        existingDoc: ''
    });

    useEffect(() => {
        return () =>{
          dispatch(agentChatHistoryActions.resetUploadDocument());
         }
      },[])

    useEffect(() =>{
        if (countryResponse && countryResponse.data){
           setCountryData(transformCountries())
        }
       },[countryResponse.data])

       useEffect(() =>{
        if (status == ApiStatus.ERROR && error){
          const msg = error.message;
          setValidationError((prevState) => ({
            ...prevState,
            document:[msg]
          }));
        }
       },[status])

    const transformCountries = () => {
        if (countryResponse && countryResponse.data.countries.length > 0) {
            return countryResponse.data.countries.map((country) => ({
                id: country.guidCode,
                name: country.name
            }));
        } else {
            return [];
        }
    };

    const transformDocuments = () => {
        if (existingDocument.id) {
          return [{
            id: existingDocument.id,
            name: existingDocument.name
          }]
        } else {
          return [];
        }
      };

    const handleCountryChange = (option: SelectOptions | SelectOptions[]) => {
        const selected = Array.isArray(option) ? option : [option];

        setForm({
            ...form,
            country: selected
        });
        setValidationError((prevState) => ({
            ...prevState,
            country: selected.length ? '' : 'Country is required'
        }));
    };

    const handleFileDrop = (files) => {
        dispatch(agentChatHistoryActions.resetUploadDocument());
        setValidationError((prevState) => ({
            ...prevState,
            document: []
        }));

        if (files.length > 0) {
            setForm({
                ...form,
                document: files[0]
            });
        }
    };

    const handlefileRemove = () => {
        setValidationError((prevState) => ({
            ...prevState,
            document: ['File is required']
        }));

        setForm({
            ...form,
            document: null
        });
    };

    const isAddButtonDisabled = () => !form.country.length || !form.document || !!error;

    const handleDropRejected = (fileRejections: FileRejection[], event) => {

        if (fileRejections.length === 0 && fileRejections[0].errors.length === 0) return;

        setValidationError((prevState) => ({
            ...prevState,
            document: fileRejections[0].errors.map(
                (i) => FileErrorMessages[i.code] || 'Invalid file'
            )
        }));
    };

    const handleCountryClose = () => {
        setValidationError((prevState) => ({
            ...prevState,
            country: !form.country.length ? 'Country is required' : ''
        }));
    };

    const handleAddClick = () => {
        const request = new FormData();
        form.country.forEach((country) => {
            request.append("Countries", country.id);
          });
        request.append("DocumentType", "1");
        if (form.document) request.append("File", form.document);
        request.append("GuidCode", existingDocument.id);
    
        dispatch(agentChatHistoryActions.uploadDocumentBegin(request));
      };
    
    return (
        <Modal className="add-document-version">
            {status == ApiStatus.LOADING && <Loader fullScreen={false} />}
            <ModalHeader onClose={onClose}>Add New Version</ModalHeader>
            <ModalBody>
                <div>
                    <MultiSelect
                        multi={false}
                        searchable={false}
                        showSelectAll={false}
                        disabled={true}
                        optionsPosition="bottom"
                        defaultOption={existingDocument}
                        label={'Select Existing Document (required)'}
                        placeholder="Search..."
                        checkBoxName={'country'}
                        onChange={() => undefined}
                        data={transformDocuments()}
                    />

                    <MultiSelect
                        multi
                        searchable
                        showSelectAll
                        optionsPosition="bottom"
                        label={'Select Country (required)'}
                        placeholder="Search..."
                        checkBoxName={'country'}
                        error={validationError.country}
                        onChange={handleCountryChange}
                        data={countryData}
                        onBlur={handleCountryClose}
                    />

                    <FileUploader
                        maxFiles={1}
                        maxSize={FileMaxSize}
                        multiple={false}
                        errors={validationError.document}
                        onDrop={handleFileDrop}
                        accept={{ 'application/pdf': ['.pdf'] }}
                        onDropRejected={handleDropRejected}
                        onFileDialogCancel={handlefileRemove}
                    />
                    {form.document && (
                        <FileUploaderItem
                            fileName={form.document.name}
                            fileSize={form.document.size}
                            onRemove={handlefileRemove}
                        />
                    )}
                    <p>
                        *Note: On adding a new version, system will archive the old version of the
                        document automatically{' '}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="primary"
                    disabled={isAddButtonDisabled()}
                    onClick={handleAddClick}>
                    Add
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};
export default AddDocumentVersion;
