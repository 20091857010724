import { AgGridReact } from "ag-grid-react";
import TableLoader from "common/components/base/TableLoader/TableLoader";
import { GridSortModel } from "common/models/grid-sort-model";

import { GridService } from "common/services/table-service";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CellClickedEvent } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { PaginationSelect } from "common/components";
import { SelectOptions } from "common/models";
import ReactDOM from 'react-dom';

import "./GPOAgGrid.scss";

interface GPOAgGridProps {
  url: string;
  method?: "GET" | "POST";
  payloadType: "query" | "body";
  responsePropertyKey?: string;
  requestBody?: any;
  paginationSize: number;
  columnDefinitions: any;
  searchFilter?: string | undefined;
  refreshGrid?: boolean | number;
  additionalQuery?: string | undefined;
  initialSortModel?: GridSortModel;
  onRowClicked?: (rowData: any, rowIndex: any) => void;
  onSort?: (params: GridSortModel) => void;
  onTotalResults?:(totalResults:number)=>void;
  onCurrentPageNumber?:(currentPageNumber:number)=>void;
  onCurrentPageSize?:(currentPageSize:number)=>void;
  onGridApiSuccess?: () => void;
}

const GPOAgGrid: FC<GPOAgGridProps> = ({
  url,
  method = "GET",
  paginationSize,
  columnDefinitions,
  searchFilter = undefined,
  refreshGrid,
  additionalQuery = undefined,
  initialSortModel,
  requestBody,
  responsePropertyKey,
  payloadType,
  onRowClicked,
  onSort,
  onTotalResults,
  onCurrentPageNumber,
  onCurrentPageSize,
  onGridApiSuccess
}) => {
  const [searchText, setSearchText] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  const [gridActivated, setGridActivated] = useState(true);
  const [paginationPosition, setPaginationPosition] = useState<any>();
  const gridRef: any = useRef();

  useEffect(() => {
    if (searchFilter != undefined) {
      setSearchText(searchFilter);
      recreateGrid();
    }
  }, [searchFilter]);
  

  useEffect(() => {
    if (additionalQuery != undefined || requestBody != undefined) {
      recreateGrid();
    }
  }, [additionalQuery, requestBody]);

  useEffect(() => {
    if (refreshGrid) {
      recreateGrid();
    }
  }, [refreshGrid]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const recreateGrid = () => {
    setGridActivated(false);
    const timeoutHandler = setTimeout(() => {
      setGridActivated(true);
    }, 10);
    return () => {
      clearTimeout(timeoutHandler);
    };
  };
  const refreshCache = () => {
    //gridRef?.current?.api?.refreshServerSide({ route: route, purge: true });
    gridRef?.current?.api?.refreshInfiniteCache();
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      minWidth: 100,
      wrapHeaderText: true,
      autoHeaderHeight: true
    };
  }, []);
  const [gridApi, setGridApi] = useState<null | {
    setDatasource: any;
    showLoadingOverlay: any;
    hideOverlay: any;
    showNoRowsOverlay: any;
  }>(null);
  const [gridColumnApi, setGridColumnApi] = useState<null | {
    applyColumnState: any;
    getColumn: any;
  }>(null);

  const onCellClicked = (params: CellClickedEvent) => {
    if (onRowClicked) {
      onRowClicked(params?.data, params?.rowIndex);
    }
  };

  const onPageSizeChanged = useCallback((option: SelectOptions) => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.paginationSetPageSize(Number(option.id));
      if(onCurrentPageSize){
      onCurrentPageSize(Number(option.id));
      }
    }
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if(document.querySelector(".ag-paging-panel .ag-paging-row-summary-panel")){
      const paginationWrapperElement = document.createElement("div");
      paginationWrapperElement.setAttribute("id", "pagination-select-wrapper");
      document.querySelector(".ag-paging-panel .ag-paging-row-summary-panel")?.insertAdjacentElement("beforebegin",
      paginationWrapperElement
      );

     ReactDOM.render(
        <PaginationSelect
        defaultOption={{
          id: "10",
          name: "Show 10"
        }}
        onChange={onPageSizeChanged}
        data={[
          { id: "5", name: "Show 5" },
          { id: "10", name: "Show 10" },
          { id: "20", name: "Show 20" },
          { id: "50", name: "Show 50" }
        ]}
      />,document.querySelector("#pagination-select-wrapper")
      );
  }
 }
  const setPaginationSelectBoundingBox = () => {
    const paginationPanel = document.querySelector(".ag-paging-panel");
    if (paginationPanel) {
      if (paginationPanel?.getBoundingClientRect()) {
        const paginationSelect: any = document.querySelector(
          ".gpo-grid__page-size"
        );
        if (paginationSelect) {
          setPaginationPosition({
            top: paginationPanel?.getBoundingClientRect()?.top + 5 + "px",
            right: paginationPanel?.getBoundingClientRect()?.right + "px"
          });
        }
      }
    }
  };

  useEffect(() => {
    if (initialSortModel && gridColumnApi) {
      const columnState = {
        state: [initialSortModel]
      };
      gridColumnApi.applyColumnState(columnState);

      //gridColumnApi.getColumn(initialSortModel.colId).setSort(initialSortModel.sort)
    }
  }, [initialSortModel, gridColumnApi]);
  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          // Use startRow and endRow for sending pagination to Backend
          // params.startRow : Start Page
          // params.endRow : End Page
          setShowLoader(true);
          let sortOrder;
          let sortColumn;
          if (initialSortModel) {
            sortOrder = initialSortModel.sort == "asc" ? 1 : 2;
            sortColumn = initialSortModel.colId;
          }
          if (params.sortModel.length) {
            sortOrder = params.sortModel[0].sort == "asc" ? 1 : 2;
            sortColumn = params.sortModel[0].colId;
          }

          let searchFilterParam: any = "";
          let additionalQueryParam: any = undefined;
          if (searchText != "") {
            searchFilterParam = searchText;
          }
          if (additionalQuery) {
            additionalQueryParam = additionalQuery;
          }
          const payload = {
            pageNumber: Math.round(params.endRow / paginationSize),
            pageSize: paginationSize,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            searchText: searchFilterParam,
            ...requestBody
          };

          GridService.getServerSidePaginationData(
            url,
            method,
            payloadType,
            payload,
            additionalQueryParam
          )
            .then((data: any) => {
              const response = responsePropertyKey
                ? data?.value[responsePropertyKey]
                : data?.value;

              if (response?.items?.length > 0) {              
                params.successCallback(response?.items, response?.totalResults);
                if(onTotalResults){
                onTotalResults(response?.totalResults);
                }
                if(onCurrentPageNumber){
                  onCurrentPageNumber(response?.currentPage);
                }
                if(onCurrentPageSize){
                  onCurrentPageSize(response?.pageSize);
                }
              } else {
                if (!response?.items?.length) {
                  params.successCallback([], 0);
                  gridApi!.showNoRowsOverlay();
                  if(onTotalResults){
                  onTotalResults(0);
                  }
                  if(onCurrentPageNumber){
                    onCurrentPageNumber(0);
                  }
                  if(onCurrentPageSize){
                    onCurrentPageSize(paginationSize);
                  }
                }
              }
              setShowLoader(false);
              onGridApiSuccess?.();
            })
            .catch((err: any) => {
              gridApi!.showNoRowsOverlay();
              params.successCallback([], 0);
              console.error(err);
              setShowLoader(false);
            });
        }
      };

      gridApi!.setDatasource(dataSource);
    }
  }, [gridApi]);



  const handleSortChange = (event: any) => {
    if (onSort) {
      const sortValues = event?.columnApi
        ?.getColumnState()
        .filter((column: GridSortModel) => column.sort);

      if (sortValues.length > 0) {
        onSort({
          colId: sortValues[0].colId,
          sort: sortValues[0].sort
        });
      } else {
        onSort({
          colId: "",
          sort: ""
        });
      }
    }
  };

  return (
    <>
      {showLoader && <TableLoader fullScreen/>}
      <div style={containerStyle}>
        <div className="ag-theme-alpine ag-theme-alpine-dark">
          {gridActivated && (
            <>
              <AgGridReact
                ref={gridRef}
                pagination={true}
                rowModelType={"infinite"}
                paginationPageSize={paginationSize}
                cacheBlockSize={paginationSize}
                onCellClicked={onCellClicked}
                onGridReady={onGridReady}
                rowHeight={60}
                domLayout='autoHeight'
                columnDefs={columnDefinitions}
                defaultColDef={defaultColDef}
                overlayNoRowsTemplate={
                  '<span style={height:"2rem"}>No data found.</span>'
                }
                getRowStyle={(params) => {
                  // to hide the empty row if data is not populated 
                  // https://github.com/ag-grid/ag-grid/issues/4461
                  if (params.data === undefined) {
                    return { opacity: 0 };
                    }
                }}

                onSortChanged={handleSortChange}
              />
              <div className="gpo-grid__page-size"
                    style={paginationPosition?{top:paginationPosition?.top}:{}}
              >
                {/* <PaginationSelect
                  defaultOption={{
                    id: "10",
                    name: "Show 10"
                  }}
                  onChange={onPageSizeChanged}
                  data={[
                    { id: "5", name: "Show 5" },
                    { id: "10", name: "Show 10" },
                    { id: "20", name: "Show 20" },
                    { id: "50", name: "Show 50" }
                  ]}
                /> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GPOAgGrid;
