import { CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { ExportReviewRequest } from "modules/gpo-agent/models";

const UserReviewConfig = {
    export: (request: ExportReviewRequest): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.RATINGS}/${METHOD.EXPORT}`, 'POST', '', request),
};

export default UserReviewConfig;
