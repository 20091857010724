import { ApiResult } from "common/models/api-service/api-result.model";
import { HttpClient } from "common/services/http-client.service";
import appHeaderConfig from "./header.config";
import { ArchiveDocumentRequest } from "modules/gpo-agent/models/manage-document-filter/archive-document.model";
import { DocumentDownloadRequest } from "modules/gpo-agent/models/document/download-documentRequest.model";
import { ExportManagedDocumentDetails } from "modules/gpo-agent/models/manage-document-filter/export-managed-document.model";

export class AppHeaderService extends HttpClient {
    public static async getAllCountries(): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(appHeaderConfig.getAllCountries());
    }
    public static async getAllDocumentStatues(): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(appHeaderConfig.getAllDocumentStatuses());
    }
    public static async getAllDocumentAdmins(): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(appHeaderConfig.getAllAdmins());
    }
    public static async archiveDocument (request:ArchiveDocumentRequest): Promise<ApiResult<any>> {
        return await this.invokeApi<any>(appHeaderConfig.archiveDocument(request));
    }
    public static async downloadDocument (request:DocumentDownloadRequest): Promise<ApiResult<any>> {
        return await this.invokeApi<any>(appHeaderConfig.downloadDocument(request));
    }
    public static async exportDocument (request:ExportManagedDocumentDetails): Promise<ApiResult<any>> {
        return await this.invokeApi<any>(appHeaderConfig.exportDocument(request));
    }
}
