import { logo } from "assets/media/images";
import "./Logout.scss";

const Logout = () => {
  const hadleLogin = () => {
    window.location.replace(window.location.origin);
  };

  return (
    <div className="logout-wrapper">
      <div className="logout-wrapper__container">
        <div className="card">
          <header>
            <img src={logo} />
            <span>
              ChatBot by <strong>GPO</strong>
            </span>
          </header>
          <p>You have successfully Logout!!!</p>
          <button type="button" onClick={hadleLogin}>LOGIN</button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
