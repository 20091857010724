import { Fragment, useEffect, useRef, useState } from "react";
import "./ManageDocumentsFilter.scss";
import { clear, down, pdf, remove } from "assets/media";
import { SelectOptions } from "common/models";
import moment from "moment";
import { DateRangePicker, MultiSelect } from "common/components";
import { UserReviewFilters } from "modules/gpo-agent/models";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { ReviewFilterTypes } from "modules/gpo-agent/enums/user-review-filter.enum";
import { agentChatHistoryActions } from "modules/gpo-agent/redux/agent-chat-history";
import { DocumentTypes } from "modules/gpo-agent/enums/document-types.enum";
import { IDocumentDetails, IReferenceDocumentsResponse } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import DropdownSearch from "common/components/custom/DropdownSearch/DropdownSearch";
import { DropdownSearchData } from "common/models/dropdown-search-data.model";
import { ManagedDocumentsFilter, QuestionDetailFilter, QuestionDetailsFilters } from "modules/gpo-agent/models/filter-component/filter-values.model";
import { DashboardDataRequest } from "modules/gpo-agent/models/dashboard";
import { QuestionDetailFilterTypes } from "modules/gpo-agent/enums/question-details-filter.enum";
import { DateRange, DateValue } from "common/models/date-range.model";
import { dashboardActions } from "modules/gpo-agent/redux/dashboard";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { ManagedDocumentsFilterTypes } from "modules/gpo-agent/enums/managed-documents-filter-types.enum";
import { ManagedDocumentsType } from "modules/gpo-agent/enums/managed-documents-type.enum";


type ManageDocumentsFilterProps = {
  appliedFilters?: Array<ManagedDocumentsFilter>;
  onCloseModal: () => void;
  onApply: (filter: Array<ManagedDocumentsFilter>) => void;
  onReset: () => void;
};
const ManageDocumentsFilter = ({
  appliedFilters,
  onCloseModal,
  onApply,
  onReset
}: ManageDocumentsFilterProps) => {
  const STAR_RATING = ["Like","Dislike"];
  const filterRef: any = useRef(null);
  const countryResponse = useAppSelector(
    (state) => state.header.countryResponse
  );
  const documentStatuesResponse = useAppSelector(
    (state) => state.header.documentStatusesResponse?.data?.documentStatuses
  );
  const documentAdminsResponse = useAppSelector(
    (state) => state.header.documentAdminsResponse?.data?.admins
  );
  const initialFilter: Array<ManagedDocumentsFilter> = [];
  const [filter, setFilter] = useState<Array<ManagedDocumentsFilter>>(initialFilter);

  const chatHistoryState = useAppSelector((state: RootState) => state.agentChatHistory);
  const [pages, setPages] = useState("");
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState("");
  const dispatch = useAppDispatch();
  const [dropdownData,setDropdownData] = useState<Array<DropdownSearchData>>([]);
  const [selectedDocument,setSelectedDocument] = useState<DropdownSearchData>();
  const [dropdownValue, setDropdownValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState<IDocumentDetails[]>();
  const [likesData,setLikesData] = useState<number>(0);
  const [disLikesData,setDislikesData] = useState<number>(0);
  const agentsData = useAppSelector((state: RootState) => state.agents.userEngagementData);
  const [defaultCountryOption,setDefaultCountryOption] = useState<SelectOptions[]>([]);
  const [defaultStatus,setDefaultStatus] = useState<SelectOptions[]>([]);
  const [defaultAdmin,setDefaultAdmin] = useState<SelectOptions[]>([]);

  const handleOpen = () => {
    setOpen(!open);
  };

  // useEffect(()=>{
  //   if(!appliedFilters?.length || appliedFilters?.length && !appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.COUNTRY)?.length){
  //     const selectCountryOptions:Array<SelectOptions>=[];
  //       selectCountryOptions?.push(
  //        {
  //        id:"select-all",
  //        name: "All"
  //        }
  //       )  
  //    setDefaultCountryOption(selectCountryOptions);
  //   }
  // },[])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (appliedFilters) {
      if(appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.COUNTRY)?.length){
        const selectCountryOptions:Array<SelectOptions>=[];
        appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.COUNTRY)[0]?.value?.map(val=>{
          if(val=="All"){
            setDefaultCountryOption(transformCountries());
          }
          else{
          const option:SelectOptions = {
            id: countryResponse?.data?.countries?.filter((country)=>country?.name==val)?.length ?countryResponse.data.countries?.filter((country)=>country?.name==val)[0]?.guidCode:"",
            name: val
          };
          
          selectCountryOptions?.push(option);
          setDefaultCountryOption(selectCountryOptions);
         }
        })
        
      }
      else{
        setDefaultCountryOption([]);
      }
      if(appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.STATUS)?.length){
        const selectStatusOptions:Array<SelectOptions>=[];
        appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.STATUS)[0]?.value?.map(val=>{
          const option:SelectOptions = {
            id: documentStatuesResponse?.filter((status)=>status?.name==val)?.length ?documentStatuesResponse?.filter((status)=>status?.name==val)[0]?.guidCode:"",
            name: val
          };
          selectStatusOptions?.push(option);
        })
        setDefaultStatus(selectStatusOptions);
      }
      else{
        setDefaultStatus([]);
      }
      if(appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPLOADED_BY)?.length){
        const selectAdminOptions:Array<SelectOptions>=[];
        appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPLOADED_BY)[0]?.value?.map(val=>{
          const option:SelectOptions = {
            id: documentAdminsResponse?.filter((admin)=>admin?.fullName==val)?.length ?documentAdminsResponse?.filter((admin)=>admin?.fullName==val)[0]?.guidCode:"",
            name: val
          };
          selectAdminOptions?.push(option);
        })
        setDefaultAdmin(selectAdminOptions);
        
      }
      else{
        setDefaultAdmin([]);
      }
      if(appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)?.length){
        appliedFilters?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)[0]?.value?.map(val=>{
          return new Date(val);
        })
        
      }
      setFilter(appliedFilters);
    }
  }, [appliedFilters]);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      onCloseModal();
    }
  };
  
  const getISODate = (date: DateValue) => {
    if (date) {
      // const utcDate = moment(date).utcOffset(0);
      //utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      // return utcDate.toISOString();
      //return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      return date?.toISOString();
    }
  };
  
  const handleResetFilter = () => {
    setFilter(initialFilter);
    onReset();
  };


  const transformCountries = () => {
    if (countryResponse && countryResponse.data.countries.length > 0) {
      return countryResponse.data.countries.map((country) => ({
        id: country.guidCode,
        name: country.name
      }));
    } else {
      return [];
    }
  };
  const transformStatuses = () => {
    if (documentStatuesResponse && documentStatuesResponse?.length > 0) {
      return documentStatuesResponse.map((status) => ({
        id: status.guidCode,
        name: status.name
      }));
    } else {
      return [];
    }
  };
  const transformAdmins = () => {
    if (documentAdminsResponse && documentAdminsResponse?.length > 0) {
      return documentAdminsResponse.map((admin) => ({
        id: admin.guidCode,
        name: admin.fullName
      }));
    } else {
      return [];
    }
  };


  const handleMultiSelectChange = (option: SelectOptions | SelectOptions[],key:ManagedDocumentsFilterTypes) => {
    const selected = Array.isArray(option) ? option : [option];
    if(filter?.filter((val)=>val?.property==key)?.length){
      const newFilter:any = filter?.map((item,i)=>{
        if(item?.property==key){
            const existingValue = item.value;
            const newItem = {...item,
                             value : key==ManagedDocumentsFilterTypes?.COUNTRY && selected?.length == countryResponse?.data?.countries?.length ? ["All"] : selected?.map((val)=>val?.name)
                            };
            return newItem;
        }
        else{
            return item;
        }
    })?.filter(data=>data?.value?.length);
    setFilter(newFilter);
    }
    else{
      setFilter(
      [...filter,
      {
        property:key,
        type:key==ManagedDocumentsFilterTypes?.UPDATED_ON ? ManagedDocumentsType?.DATETIME : ManagedDocumentsType?.TEXT,
        value:key==ManagedDocumentsFilterTypes?.COUNTRY ? selected?.length == countryResponse?.data?.countries?.length ? ["All"] : selected?.map((val)=>val?.name) : [selected[0]?.name]   
      }
     ]?.filter(data=>data?.value?.length)
     )
    }

    
  };
  const handleDateChange = (date:Date|null) => {
    if(filter?.filter((val)=>val?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)?.length){
      const newFilter:any = filter?.map((item,i)=>{
        if(item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON){
            const newItem = {...item,
                             value : date?.toISOString()
                            };
            return newItem;
        }
        else{
            return item;
        }
    })
    setFilter(newFilter);
    }
    else{
      const dateString = date?.toISOString();
      if(dateString){
      setFilter(
      [...filter,
      {
        property: ManagedDocumentsFilterTypes?.UPDATED_ON,
        type: ManagedDocumentsType?.DATETIME,
        value:[dateString]
      }
     ])
    }
    }
  };
  

  const handleChange = (value,key) => {
    setDropdownValue(value);
    setSelectedDocument(key)
    setOpen(false);
  };

  const handleDateRangeChange = (date:DateRange) => {
    if (date && date[0] && date[1]) {
     /* const date1String = getISODate(date[0]);
      const date2String = getISODate(date[1]);*/
      const date1String = date[0]?.toISOString();
      const date2String = date[1]?.toISOString();
      if(date1String && date2String){
      setFilter(
      [...filter?.filter(item=>item?.property!=ManagedDocumentsFilterTypes?.UPDATED_ON),
      {
        property: ManagedDocumentsFilterTypes?.UPDATED_ON,
        type: ManagedDocumentsType?.DATETIME,
        value:[date1String,date2String]
      }
     ])
     }    
    }
    else{
      setFilter(
        [...filter?.filter(item=>item?.property!=ManagedDocumentsFilterTypes?.UPDATED_ON)
       ]
       ) 
    }
  };

  return (
    <div className="manage-documents-filter" ref={filterRef}>
      <div className="manage-documents-filter__header">
        <p>Filters</p>
        <img src={clear} onClick={onCloseModal} />
      </div>
      <div className="manage-documents-filter__body">
      <div className="body-item">
        <MultiSelect
          multi={true}
          searchable={true}
          optionsPosition="bottom"
          defaultOption={defaultCountryOption}
          label={"Country"}
          placeholder="Search..."
          checkBoxName={"country"}
          showSelectAll={true}
          onChange={(data)=>{handleMultiSelectChange(data,ManagedDocumentsFilterTypes.COUNTRY)}}
          data={transformCountries()}
        />
        </div>

        <div className="body-item">
        {/* <DateComponent
          label="Uploaded on"
          value={filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)?.length==2 ? new Date(filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)[0]?.value[0]) : null}
          format="dd/MM/y"
          maxDate={new Date()}
          onChange={handleDateChange}
        /> */}
          <DateRangePicker
          label="Uploaded on"
          value={filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)?.length ? [filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)[0]?.value[0]?new Date(filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)[0]?.value[0]):null,filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)[0]?.value[1]?new Date(filter?.filter(item=>item?.property==ManagedDocumentsFilterTypes?.UPDATED_ON)[0]?.value[1]):null] : [null,null]}
          format="dd/MM/y"
          maxDate={new Date()}
          onChange={handleDateRangeChange}
        />
        </div>
        <div className="body-item">
          <MultiSelect
          multi={true}
          searchable={true}
          optionsPosition="bottom"
          defaultOption={defaultStatus}
          label={"Status"}
          placeholder="Search..."
          checkBoxName={"source-document"}
          onChange={(data)=>{handleMultiSelectChange(data,ManagedDocumentsFilterTypes.STATUS)}}
          data={transformStatuses()}
        />
        </div>
        <div className="body-item">
        <MultiSelect
          multi={true}
          searchable={true}
          optionsPosition="bottom"
          defaultOption={defaultAdmin}
          label={"Uploaded by"}
          placeholder="Search..."
          checkBoxName={"country"}
          onChange={(data)=>{handleMultiSelectChange(data,ManagedDocumentsFilterTypes.UPLOADED_BY)}}
          data={transformAdmins()}
        />
        </div>
      </div>
      <footer>
        <button className="btn btn-primary" onClick={() => onApply(filter)} disabled={filter?.length==0}>
          Apply
        </button>
        <button className="btn btn-secondary " onClick={handleResetFilter}>
          Clear all
        </button>
      </footer>
    </div>
  );
};
export default ManageDocumentsFilter;
