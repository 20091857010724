import { CONTROLLER, METHOD, WEB_PUB_SUB_CONFIG } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { OpinionDataRequest } from "modules/gpo-agent/models/dashboard";
import { ExportTableQuestionDetails } from "modules/gpo-agent/models/export-table-question-details/export-table-question-details.model";
import { ManagedDocumentsRequest } from "modules/gpo-agent/models/filter-component/filter-values.model";

const agentsClientApiConfig = {

    session: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.SESSION}`, 'POST', '', {}),
    chatRequests: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT_REQUESTS}`, 'GET', '', {}),
    acceptConversation: (conversationThreadId: string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${conversationThreadId}/${METHOD.ACCEPT}`, 'POST', '', {}),
    getACSContext: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CONTEXT}`, 'GET', '', {}),
    leaveConversation: (conversationThreadId: string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.AGENTS}/${METHOD.CHAT}/${conversationThreadId}/${METHOD.LEAVE}`, 'POST', '', {}),
    webPubSubNegotiate: (): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.WEB_PUB_SUB}/${WEB_PUB_SUB_CONFIG.HUB_NAME}/${METHOD.NEGOTIATE}`, 'GET', '', {}),
    getPDFDocument: (documentGuid:string): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.DOCUMENTS}/${documentGuid}`, 'GET'),
    getTotalLikes: (request: OpinionDataRequest): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.OPINION}`, "POST", "", {
          ...request,
        }),
    getTotalDislikes: (request: OpinionDataRequest): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.OPINION}`, "POST", "", {
          ...request,
    }),
    getTotalNoActions: (request: OpinionDataRequest): ApiConfig<any> =>
        new ApiConfig(`${CONTROLLER.OPINION}`, "POST", "", {
          ...request,
    }),
    getTotalArchived : (request: ManagedDocumentsRequest): ApiConfig<any> =>
    new ApiConfig(`${METHOD.MANAGED_DOCUMENTS}`, "POST", "", {
      ...request,
    }),
    exportQuestionDetailsTable : (request : ExportTableQuestionDetails) : ApiConfig<any> =>
    new ApiConfig(`${CONTROLLER.QUESTION_DETAILS}/${METHOD.EXPORT}`, "POST", "", {
      ...request,
   }) 
};
export default agentsClientApiConfig;