import React, { useEffect, useRef, useState } from "react";
import { search, sort_down, sort_up } from "assets/media";
import { close_btn } from "assets/images";
import { SelectOptions } from "common/models";
import { useOnClickOutside } from "common/hooks";
import "./MultiSelect.scss";

type MultiSelectProp = {
  className?: string;
  data: SelectOptions[];
  placeholder?: string;
  label?: string;
  checkBoxName?: string;
  multi: boolean;
  defaultOption?: SelectOptions | SelectOptions[];
  searchable?: boolean;
  showSelectAll?: boolean;
  optionsPosition?: "top" | "bottom";
  error?: string;
  disabled?: boolean;
  onBlur?: () => void;
  onChange: (options: SelectOptions | SelectOptions[]) => void;
  onDropdownClose?: (options: SelectOptions | SelectOptions[]) => void;
};
const MultiSelect = ({
  className,
  data,
  placeholder,
  label,
  checkBoxName,
  multi,
  defaultOption,
  searchable,
  showSelectAll,
  optionsPosition,
  error,
  disabled,
  onChange,
  onBlur,
  onDropdownClose
}: MultiSelectProp) => {
  const selectRef: any = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectOptions[]>([]);
  const [formattedData, setFormattedData] = useState(data);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [highlightAllChecked, setHighlightAllChecked] =
    useState<boolean>(false);

  useEffect(() => {
    setFormattedData(data);
  }, [data]);

  useEffect(() => {
    if (defaultOption) {
      if (Array.isArray(defaultOption)) {
        setSelectedOptions(defaultOption);

        // if default options length is same as data length then highlighting select All
        if (defaultOption?.length === data.length) {
          setHighlightAllChecked(true);
        }
        else {
          setHighlightAllChecked(false);
        }
      } else setSelectedOptions([defaultOption]);
    } else {
      setSelectedOptions([]);
    }
  }, [defaultOption]);

  useEffect(() => {
    if (!isOpen && onDropdownClose) {
      /* specific to metric dashboard: 
         When all checkboxes are unchecked and a user clicks outside of the selection area, 
         the state should be reflected as "Select All."
      */
      if (!selectedOptions.length) {
        onDropdownClose?.(formattedData);
        setSelectedOptions(formattedData);
        setHighlightAllChecked(true);
        return;
      }
      onDropdownClose?.(selectedOptions);
    }
  }, [isOpen]);

  useOnClickOutside(selectRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    setFormattedData(
      data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const handleCheckBoxChange = (event) => {
    const selected = data.find((item) => item.id === event.target.value);
    let options: SelectOptions[] = [];

    if (selected) {
      if (event.target.checked) {
        options = [...selectedOptions, selected];
        setSelectedOptions((prevData) => [...prevData, selected]);
        onChange(options);
      } else {
        options = selectedOptions.filter(
          (item) => item.id !== event.target.value
        );
        setSelectedOptions(options);
        onChange(options);
      }
    }

    if (options.length > 0) {
      setHighlightAllChecked(true);
    } else {
      setHighlightAllChecked(false);
    }
  };

  const handleSelectAll = (checkAll: boolean) => {
    if (checkAll) {
      onChange(formattedData);
      setHighlightAllChecked(true);
      setSelectedOptions(formattedData);
    } else {
      onChange([]);
      setHighlightAllChecked(false);
      setSelectedOptions([]);
    }
  };

  const handleSelect = (option: SelectOptions) => {
    if (!multi) {
      onChange(option);
      setIsOpen(false);
      setSelectedOptions([option]);
    }
  };

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  return (
    <div
      className={`multi-select__form-field ${className ? className : ""} ${disabled ? "multi-select--disabled": ""}`}
      ref={selectRef}
      onBlur={onBlur}
    >
      {label && (
        <label
          className={`multi-select__form-field__label ${
            selectedOptions.length > 0
              ? "multi-select__form-field__label--active"
              : ""
          }`}
        >
          {label}
        </label>
      )}
      <div className="multi-select__container">
        <button
          className="multi-select__trigger"
          disabled={disabled}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div
            className="multi-select__input-text"
            title={selectedOptions.map((i) => i.name).join(", ")}
          >
            {showSelectAll && selectedOptions.length === data.length &&
            selectedOptions.length !== 0
              ? "All"
              : selectedOptions.map((i) => i.name).join(", ")}
          </div>
          <img
            className="multi-select__arrow"
            src={isOpen ? sort_up : sort_down}
            alt="arrow"
          />
        </button>
        {isOpen && (
          <div
            className={`multi-select__options-wrapper ${
              optionsPosition == "top"
                ? "multi-select__options-wrapper--top"
                : ""
            }`}
          >
            {searchable && (
              <div className="multi-select__search">
                <input
                  type="text"
                  placeholder={placeholder ?? ""}
                  autoComplete="off"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {searchTerm ? (
                  <img
                    className="multi-select__search--close-btn"
                    src={close_btn}
                    alt="close"
                    onClick={() => handleSearch("")}
                  />
                ) : (
                  <img src={search} alt="search" />
                )}
              </div>
            )}
            {showSelectAll && multi && formattedData.length ? (
              <div className="multi-select__select-all">
                <div className="multi-select__select-all--wrapper">
                  <input
                    type="checkbox"
                    id={"select-all"}
                    value={"select-all"}
                    className={`${
                      selectedOptions.length === formattedData.length
                        ? "all-selected"
                        : selectedOptions.length > 0
                        ? "few-selected"
                        : ""
                    }`}
                    checked={highlightAllChecked}
                    onChange={() => handleSelectAll(!highlightAllChecked)}
                  />
                  <label htmlFor={"select-all"}>
                    <span></span>
                    {"All"}
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="multi-select__options">
              {formattedData.length ? (
                formattedData.map((item, index) => (
                  <div
                    className="multi-select__option"
                    key={index}
                    onClick={() => handleSelect(item)}
                  >
                    {multi && (
                      <input
                        type="checkbox"
                        id={item.id}
                        name={checkBoxName}
                        value={item.id}
                        checked={selectedOptions.some((i) => i.id === item.id)}
                        onChange={handleCheckBoxChange}
                      />
                    )}
                    <label htmlFor={item.id} title={item.name}>
                      {multi && <span></span>}
                      <p>{item.name}</p>
                    </label>
                  </div>
                ))
              ) : (
                <p className="no-data">No results found</p>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <p className="multi-select__error">{error}</p>}
    </div>
  );
};

export default MultiSelect;
