import { ActionCellRendererItems } from "common/models/action-cell-renderer.model";
import React from "react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import './ActionCellRenderer.scss';
import { GridService } from "common/services/table-service";

interface ActionCellRendererProps {
    rowData:any;
    actionItems:Array<ActionCellRendererItems>;
    onActionClicked: (rowData:any,actionName:string) => void;
  }
  
  const ActionCellRenderer: FC<ActionCellRendererProps> = ({
    rowData,
    actionItems,
    onActionClicked,
  }) => {
    return rowData ?(
        <div className="action-cell-renderer">
            {actionItems?.map((action:ActionCellRendererItems, i) => 
              <React.Fragment key={action?.actionName || i}>
                {!action?.isActionDisabled && !action?.displayConditions?.length && (
                <div className={'enabledAction '+action?.actionName}>
                    <img src={action?.actionSrc}  onClick={() => onActionClicked(rowData,action?.actionName)}/>
                </div>
                )}
                {!action?.isActionDisabled && action?.displayConditions?.length && GridService.evaluateExpression(action?.displayConditions,rowData) && (
                <div className={'enabledAction '+action?.actionName}>
                    <img src={action?.actionSrc}  onClick={() => onActionClicked(rowData,action?.actionName)}/>
                </div>
                )}
                {(action?.isActionDisabled || action?.displayConditions?.length && !GridService.evaluateExpression(action?.displayConditions,rowData)) && (
                 <div className={'disabledAction '+action?.actionName}>
                   <img src={action?.actionSrc} />
                 </div>  
                )}
              </React.Fragment>
              
            )}
        </div>
    ):<></>
  }

  export default ActionCellRenderer