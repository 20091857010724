import { ReactNode } from "react";
import { Pie, PieChart as Chart, Legend, Label, Tooltip } from "recharts";
import PieLabel from "../PieLabel/PieLabel";
import "./PieChart.scss";

type LayoutType = "horizontal" | "vertical";
type legendInfo = {
  label: string;
  color: string;
};

type PieChartProps = {
  data?: any[];
  innerRadius?: string | number;
  outerRadius?: string | number;
  dataKey: string | number;
  onClick?: (data, index, e?) => void;
  width?: number;
  height?: number;
  children?: ReactNode;
  legendInfo?: legendInfo[];
  legendLayout?: LayoutType;
  legendAlign?: "left" | "center" | "right";
  cx?: number;
  cy?: number;
  startAngle?: number;
  endAngle?: number;
  fill?: string;
  blendStroke?: boolean;
  labelTitle?: string;
  labelValue?: number;
};

const PieChart = ({
  data,
  innerRadius = 35,
  outerRadius = 50,
  dataKey,
  onClick,
  width,
  height,
  legendInfo,
  legendLayout = "vertical",
  legendAlign = "center",
  blendStroke = true,
  labelTitle,
  labelValue,
  children,
}: PieChartProps) => {
  const renderLegend = () => {
    return (
      <div className="legend-container">
        {legendInfo?.map((data, index) => (
          <p key={index} className="legend-line">
            <span
              className="legend-dot"
              style={{ backgroundColor: data.color }}
            />
            <span>{data.label}</span>
          </p>
        ))}
      </div>
    );
  };

  return (
    <Chart width={width} height={height}>
      <Pie
        blendStroke={blendStroke}
        data={data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        dataKey={dataKey}
        onClick={onClick}
      >
        {labelTitle && (
          <Label
            content={<PieLabel label={labelTitle} value={labelValue} />}
            fill="#808080"
            position="middle"
          />
        )}
        {children}
      </Pie>
      <Legend
        content={renderLegend}
        layout={legendLayout}
        align={legendAlign}
      />
      <Tooltip
        content={({ label, payload }) => {
          return (
            <div className="custom-tooltip">
              <div className="tooltip-arrow"></div>
              <div className="tooltip-content">
                <p>{`${payload?.[0]?.name}: ${Number(
                  payload?.[0]?.value
                )?.toFixed(2)}%`}</p>
              </div>
            </div>
          );
        }}
      />
    </Chart>
  );
};

export default PieChart;
