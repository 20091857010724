import { all } from 'redux-saga/effects';
import { acceptConversationWatch, getACSContextWatch, getSilentChatRequestWatch, getChatRequestsWatch, getSessionWatch, leaveConversationWatch, rejectConversationWatch, webPubSubNegotiateWatch, getPDFDocumentWatch, getTotalLikesWatch, getTotalDislikesWatch, exportedQuestionDetailsWatch, getTotalNoActionsWatch, getTotalArchivedCountWatch } from 'modules/gpo-agent/redux/agents/agents.saga';
import { getCurrentUserAvatarWatch, getUserProfileWatch, getUserAvatarWatch, requestAccessWatch, logoutUserWatch } from '../store/user/user.saga';
import { completedAnnotationWatch, getChatHistoryListWatch,getChatHistoryMessagesWatch, getReferenceDocumentsWatch, annotateMessageWatch, getAllDocumentsWatch, uploadDocumentWatch } from 'modules/gpo-agent/redux/agent-chat-history/agent-chat-history.saga';
import { addNewUsersWatch, approveRejectUserWatch, getAllGraphUsersWatch } from '../store/user-management/user-management.saga';
import { archiveDocumentWtach, downloadDocumentWatch, exportDocumentWatch, getAllAdminsWatch, getAllCountriesWatch, getAllDocumentStatuesWatch } from 'modules/gpo-agent/redux/header/header.saga';
import { dashboardWatch } from 'modules/gpo-agent/redux/dashboard/dashboard.saga';

export default function* rootSaga() {
    yield all([
        getUserProfileWatch(),
        getCurrentUserAvatarWatch(),
        getSessionWatch(),
        getChatHistoryListWatch(),
        getChatHistoryMessagesWatch(),
        getUserAvatarWatch(),
        getSessionWatch(),
        getChatRequestsWatch(),
        acceptConversationWatch(),
        getACSContextWatch(),
        getReferenceDocumentsWatch(),
        completedAnnotationWatch(),
        annotateMessageWatch(),
        leaveConversationWatch(),
        getSilentChatRequestWatch(),
        rejectConversationWatch(),
        webPubSubNegotiateWatch(),
        getAllGraphUsersWatch(),
        addNewUsersWatch(),
        approveRejectUserWatch(),
        requestAccessWatch(),
        logoutUserWatch(),
        getAllCountriesWatch(),
        getAllDocumentStatuesWatch(),
        getAllAdminsWatch(),
        dashboardWatch(),
        getPDFDocumentWatch(),
        getAllDocumentsWatch(),
        getTotalLikesWatch(),
        getTotalDislikesWatch(),
        getTotalNoActionsWatch(),
        exportedQuestionDetailsWatch(),
        getTotalArchivedCountWatch(),
        archiveDocumentWtach(),
        uploadDocumentWatch(),
        downloadDocumentWatch(),
        exportDocumentWatch()
    ]);
}
