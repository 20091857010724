import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ProfileImage from "../ProfileImage/ProfileImage";
import './DocumentViewerCellRenderer.scss';
import { DocumentViewerCellMapper } from "common/models/document-viewer-cell-renderer.model";
import { document_viewer_icon } from "assets/images";
import { IDocumentDetails, IDocumentPageDetails } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";


interface DocumentViewerCellRendererProps {
    documentsArray:Array<IDocumentDetails>;
    openPDF:(details:IDocumentDetails)=>void;
}
  
  const DocumentViewerCellRenderer: FC<DocumentViewerCellRendererProps> = ({
    documentsArray,
    openPDF

  }) => {
    

    return documentsArray?.length ?(
        <div className="document-viewer-cell-renderer">
             {documentsArray?.length > 1 ? (
               <div className="document-viewer-cell-renderer__data">
               <span>{documentsArray?.length}</span>
               <span>documents</span>
            </div>
             ):
             (
               <div className="document-viewer-cell-renderer__data">
                  <span className="single" title={documentsArray[0]?.documentName}>{documentsArray[0]?.documentName}</span>
                  {!documentsArray[0]?.pages?.map((page:IDocumentPageDetails)=>page?.pageNumber)?.includes(0) && (
                    <img src={document_viewer_icon} onClick={()=>{openPDF(documentsArray[0])}}/>
                  )}
               </div>
             )
            }

        </div>
    )
    :
    <></>
  }

  export default DocumentViewerCellRenderer;