import { ApiConfig, ApiResult, GridPayload } from "common/models";
import { HttpClient } from "./http-client.service";
import { DisplayCondition } from "common/models/action-cell-renderer.model";

export class GridService extends HttpClient {
  public static async getTableData(
    url: any
  ): Promise<ApiResult<any> | undefined> {
    const apiConfig = new ApiConfig(`${url}`, "GET");

    return await this.invokeApi<any>(apiConfig);
  }
  public static async getServerSidePaginationData(
    urlInfo: string,
    method: "GET" | "POST",
    payloadType: "query" | "body",
    payload: GridPayload,
    additionalQueryString?: string
  ): Promise<ApiResult<any> | undefined> {
    let queryString = "";

    if (payloadType == "query") {
      queryString = `PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`;
      if (payload.sortColumn && payload.sortOrder) {
        queryString =
          queryString +
          `&SortOrder=${payload.sortOrder}&SortColumn=${payload.sortColumn}`;
      }
      if (payload.searchText) {
        queryString = queryString + `&SearchText=${payload.searchText}`;
      }
    }

    if (additionalQueryString) {
      queryString = queryString + `${additionalQueryString}`;
    }
    let apiConfig: ApiConfig<any> = {
      url: urlInfo,
      method: method,
      queryString: queryString ? encodeURIComponent(queryString) : "",
      data: payloadType == "body" ? payload : {}
    };
    return await this.invokeApi<any>(apiConfig);
  }

  public static evaluateExpression(
    displayConditions: DisplayCondition[],
    params: any
  ): boolean {
    let evaluateExpressionReturn: boolean = true;
    let evaluateExpression: boolean = true;
    for (let x = 0; x < displayConditions.length; x++) {
      let displayCondition = displayConditions[x];
      let conditions = displayCondition.conditions;
      let groupJoinCondition = displayCondition?.groupJoinCondition;
      let conditionExpression: any;
      for (let i = 0; i < conditions?.length; i++) {
        let condition = conditions[i];
        let fieldNames = condition.fieldName.split(".");
        fieldNames.forEach((field: string, index) => {
          if (index == 0) conditionExpression = params[field];
          else conditionExpression = conditionExpression[field];
        });

        if (condition?.parseDataFunction == "parseFloat") {
          conditionExpression = parseFloat(conditionExpression);
        }
        if (condition?.parseDataFunction == "parseInt") {
          conditionExpression = parseInt(conditionExpression);
        }
        let conditionValue = condition?.value;
        let expresionResult: boolean = true;

        if (condition?.matchType == "==") {
          expresionResult = conditionExpression == conditionValue;
        } else if (condition?.matchType == "!=") {
          expresionResult = conditionExpression != conditionValue;
        } else if (condition?.matchType == "!") {
          expresionResult = !conditionExpression;
        } else if (condition?.matchType == "") {
          expresionResult = conditionExpression;
        }

        if (condition?.joinCondition == "&&") {
          evaluateExpression = evaluateExpression && expresionResult;
        }
        if (condition?.joinCondition == "||") {
          evaluateExpression = evaluateExpression || expresionResult;
        }
        if (condition?.joinCondition == "") {
          evaluateExpression = expresionResult;
        }
      }
      if (groupJoinCondition == "&&") {
        evaluateExpressionReturn =
          evaluateExpressionReturn && evaluateExpression;
      } else if (groupJoinCondition == "||") {
        evaluateExpressionReturn =
          evaluateExpressionReturn || evaluateExpression;
      } else if (groupJoinCondition == "") {
        evaluateExpressionReturn = evaluateExpression;
      }
    }
    return evaluateExpressionReturn;
  }
}
