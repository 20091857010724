const getThisWeekRange = () => {
  const today = new Date();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());
  return [startOfWeek, today];
};

const getLastWeekRange = () => {
  const today = new Date();
  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() - today.getDay() - 1); // Set to the end of last week (Saturday)

  const startOfWeek = new Date(endOfWeek);
  startOfWeek.setDate(endOfWeek.getDate() - 6); // Set to the start of last week (Sunday)

  return [startOfWeek, endOfWeek];
};

const getLast15DaysRange = () => {
  const today = new Date();
  const fifteenDaysAgo = new Date(today);
  fifteenDaysAgo.setDate(today.getDate() - 15);
  return [fifteenDaysAgo, today];
};

const getLast30DaysRange = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  return [thirtyDaysAgo, today];
};

const getThisMonthRange = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return [startOfMonth, today];
};

const getLastMonthRange = () => {
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);

  // Calculate the first day of last month
  const firstDayOfLastMonth = new Date(
    lastMonth.getFullYear(),
    lastMonth.getMonth(),
    1
  );

  // Calculate the last day of last month
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  return [firstDayOfLastMonth, lastDayOfLastMonth];
};

const getToday = () => {
  const today = new Date();
  const oneDayAgo = new Date(today);
  oneDayAgo.setDate(today.getDate() - 1);
  return [oneDayAgo, today];
};

const formatToYYYYMMDD = (date) => {
  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export default {
  getThisWeekRange,
  getLastWeekRange,
  getLast15DaysRange,
  getLast30DaysRange,
  getThisMonthRange,
  getLastMonthRange,
  getToday,
  formatToYYYYMMDD,
};
