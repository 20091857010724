import { TABLE_SORT_ORDER } from "common/enums/table-sort-order.enum";
import { DateRange, SelectOptions } from "common/models";
import { ManagedDocumentsType } from "modules/gpo-agent/enums/managed-documents-type.enum";
import { QuestionDetailFilterTypes } from "modules/gpo-agent/enums/question-details-filter.enum";
import { ReviewFilterTypes } from "modules/gpo-agent/enums/user-review-filter.enum";

export interface IFilterNameValues{
    filterName:string;
    filterCode:string;
    filterValues:Array<IFilterValues>
}
export interface IFilterValues{
    code:string;
    value:string;
}

export type UserReviewFilters = {
    [ReviewFilterTypes.Rating]: StarRatingValue;
    [ReviewFilterTypes.Duration]: DateRange;
    [ReviewFilterTypes.Country]: SelectOptions[];
    [ReviewFilterTypes.Comment]: SelectOptions | null;
}

export type QuestionDetailsFilters = {
    [QuestionDetailFilterTypes.Like]: boolean|null;
    [QuestionDetailFilterTypes.Dislike]: boolean|null;
    [QuestionDetailFilterTypes.NoAction]: boolean|null;
    [QuestionDetailFilterTypes.Duration]: DateRange;
    [QuestionDetailFilterTypes.Country]: SelectOptions[];
    [QuestionDetailFilterTypes.Documents]: SelectOptions[];
}

export type StarRatingValue = {
    [key: string]: boolean;
}

export type UserReviewFilterBody = {
    filter: UserReviewGenericFilter;
    ratingFilters: RatingFilter;
    searchText: string;
}
export type QuestiondetailsFilterBody = {
    filter: UserReviewGenericFilter;
    questionDetailsFilters: QuestionDetailFilter,
    searchText:string|null;
}

export type QuestionDetailFilter = {
    documentGuids:  string[];
    messageFeedbackFilter: {
        includeLikes: boolean|null;
        includeDislikes: boolean|null,
        includeNoAction: boolean|null
    }
}

export type ManagedDocumentsRequest = {
    filters:Array<ManagedDocumentsFilter>,
}
export type ManagedDocumentsFilter = {
    property: string;
    value: Array<string>;
    type: ManagedDocumentsType;
}
export type RatingFilter = {
    ratingValues: number[];
    isCommentNotProvided: boolean | null;
}

export type UserReviewGenericFilter = {
    countryGuids: string[];
    startDate: string | null;
    endDate: string | null;
  };

export type ExportReviewRequest = UserReviewFilterBody & {
    searchText: string;
    sortOrder: number,
    sortColumn: string;
    timeZone: string;
}

export const SortOrder = {
    desc: 2,
    asc: 1
}