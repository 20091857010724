import { ApiResult } from "common/models/api-service/api-result.model";
import { HttpClient } from "common/services/http-client.service";
import UserReviewConfig from "./user-review.config";
import { ExportReviewRequest } from "modules/gpo-agent/models";

export class UserReviewService extends HttpClient {
    public static async export(request: ExportReviewRequest): Promise<ApiResult<any>> {
        return await this.invokeApi<any>(UserReviewConfig.export(request));
    }
}
