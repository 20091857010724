import { ApiStatus } from "common/enums";
import { ApiResponse } from "common/models";
import {
  AvgTimeToResolveDataResponse,
  SentimentDistDataResponse,
  UnresolvedRateResponse,
  ResolvedDataResponse,
  TrafficDataForMapResponse,
  UserEngagementData,
  UserRatingsResponse,
} from "modules/gpo-agent/models/dashboard";

interface IAppHeaderState {
  resolvedData: ApiResponse<ResolvedDataResponse>;
  unresolvedRateData: ApiResponse<UnresolvedRateResponse>;
  avgTimeToResolveData: ApiResponse<AvgTimeToResolveDataResponse>;
  sentimentDistData: ApiResponse<SentimentDistDataResponse>;
  userRatingsData: ApiResponse<UserRatingsResponse>;
  userEngagementData: UserEngagementData;
  trafficDataForMap: ApiResponse<TrafficDataForMapResponse>;
  lastUpdatedTime: string;
}

const initialDashboardState: IAppHeaderState = {
  resolvedData: {
    data: {
      resolvedRate: "",
      unresolvedRate: "",
      resolvedQuestionsByDay: [],
      totalQuestionsByDay: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  unresolvedRateData: {
    data: {
      handOffRate: "",
      botResolvedRate: "",
      handsOffByDay: [],
      botResolvedByDay: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  avgTimeToResolveData: {
    data: {
      averageTimeToResolve: 0,
      averageTimeToResolveByDay: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  sentimentDistData: {
    data: {
      likes: "",
      unlikes: "",
      noAction: "",
      sentimentByDay: [],
      status: "",
      code: -1,
      message: "",
    },
    status: ApiStatus.NONE,
  },
  userRatingsData: {
    data: {
      count: 0,
      feedbacks: [],
      average: 0,
      status: "",
      code: -1,
      message: "",
      conversationCount: 0,
    },
    status: ApiStatus.NONE,
  },
  userEngagementData: {
    totalTraffic: {
      data: {
        total: 0,
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
    totalLikes: {
      data: {
        total: 0,
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
    totalDislikes: {
      data: {
        total: 0,
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
    totalConversations: {
      data: {
        total: 0,
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
    totalQuestions: {
      data: {
        total: 0,
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
    engagedUsers: {
      data: {
        total: 0,
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
    top5SourceDocs: {
      data: {
        top5SourceDocuments: [],
        status: "",
        code: -1,
        message: "",
      },
      status: ApiStatus.NONE,
    },
  },
  trafficDataForMap: {
    data: {
      trafficDistributionMaps: [],
    },
    status: ApiStatus.NONE,
  },
  lastUpdatedTime: "",
};

export { initialDashboardState };
